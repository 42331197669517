import { API, Hub } from "aws-amplify";
import { IndexableType, Table } from "dexie";
import { useEffect, useState } from "react";
import { mainDb } from "../helpers/db.model";
import { GlobalConstantValuesInterface } from "../helpers/dbTypes";
import { apiName } from "../helpers/utils";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setReloadConfig } from "../redux/webSocket";
import * as Sentry from "@sentry/nextjs";

/**
 * Fetches all constant values and sets them in indexedDB. Should only be used in the _app file so that the Auth listener is not initialized multiple times
 */
export default function useIndexedDbSetter() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { reloadConfig } = useSelector((state: RootState) => state.webSocket);

	// Ensure the database is open
	const ensureDatabaseOpen = async () => {
		if (!mainDb.isOpen()) {
			try {
				await mainDb.open();
				console.log("Database opened successfully");
			} catch (error) {
				console.error("Failed to open the database:", error);
				Sentry.captureException(error);
			}
		}
	};

	// Wrapper function to bulk set data in table
	const setData = async <T>(
		table: Table<T, IndexableType>,
		data: Array<T>,
	) => {
		try {
			// await ensureDatabaseOpen();
			await table.bulkAdd(data);
		} catch (error) {
			// throw new Error("Unable to set data in table: " + table.name, {
			// 	cause: error,
			// });
			console.error(`Unable to set data in table: ${table.name}`, error);
			Sentry.captureException(error);
		}
	};

	// Wrapper function to clear data from table
	const clearData = async <T>(table: Table<T, IndexableType>) => {
		try {
			await ensureDatabaseOpen();
			await table.clear();
		} catch (error) {
			// throw new Error("Unable to clear data from table: " + table.name, {
			// 	cause: error,
			// });
			console.error(
				`Unable to clear data from table: ${table.name}`,
				error,
			);
			Sentry.captureException(error);
		}
	};

	/**
	 * Is called when user signs in. Fetches all the constant values and sets them in indexedDB
	 */
	const handleOnSignIn = async () => {
		setLoading(true);

		// Calling api that returns all the constant values
		try {
			await ensureDatabaseOpen();
			const response: {
				data: GlobalConstantValuesInterface;
				message: string;
				success: boolean;
			} = await API.get(apiName, "api/clinic-list-of-values", {});
			if (response.success) {
				// Deleting the previous indexed DB and opening a new one
				// await mainDb.delete().then(async () => await mainDb.open()); // Commenting this because we don't want to close the db and open it again we can just clear the data

				// Clearing all the tables in indexDB
				await handleOnSignOut();
				// Adding all data to indexedDB
				if (response.data) {
					await setData(
						mainDb.archivedReasons,
						response.data.archived_reasons,
					);
					await setData(mainDb.doctorList, response.data.doctor_list);
					await setData(
						mainDb.globalConfig,
						response.data.global_config,
					);
					await setData(
						mainDb.careCoordinator,
						response.data.care_coordinator,
					);
					await setData(
						mainDb.priorityList,
						response.data.priority_list,
					);
					await setData(
						mainDb.clinicLanguages,
						response.data.clinic_languages,
					);
					await setData(
						mainDb.contactResolution,
						response.data.contact_resolution,
					);
					await setData(
						mainDb.virtualCareProvider,
						response.data.virtual_care_provider,
					);
					await setData(
						mainDb.appointmentCancelledReasons,
						response.data.appointment_cancelled_reasons,
					);
					await setData(mainDb.roles, response.data.roles);
					await setData(
						mainDb.referringPhysicianOptoutDropdown,
						response.data.referring_physician_optout_dropdown,
					);
					await setData(
						mainDb.specialtyDropdown,
						response.data.speciality_dropdown,
					);
					await setData(mainDb.locations, response.data.locations);
					await setData(
						mainDb.initialContactChannel,
						response.data.initial_contact_channel,
					);
					await setData(
						mainDb.benefitProvider,
						response.data.benefit_provider,
					);
					await setData(
						mainDb.patientPreferences,
						response.data.patient_prefrences,
					);
					await setData(
						mainDb.reasonForReferrals,
						response.data.reason_for_refferals,
					);
					await setData(
						mainDb.patientLanguages,
						response.data.patient_languages,
					);
					await setData(
						mainDb.referringPhysicians,
						response.data.referring_physicians,
					);
					await setData(mainDb.country, response.data.country);
					await setData(mainDb.pronouns, response.data.pronouns);
					await setData(
						mainDb.appointmentTypes,
						response.data.appointment_types,
					);
					if (response.data.fax_archived_reasons) {
						await setData(
							mainDb.faxReferralArchiveReasons,
							response.data.fax_archived_reasons
								? response.data.fax_archived_reasons
								: [],
						);
					}
					if (response.data.visiting_purpose) {
						await setData(
							mainDb.visitingPurpose,
							response.data.visiting_purpose
								? response.data.visiting_purpose
								: [],
						);
					}
					await setData(
						mainDb.countryProvinces,
						response.data.country_provinces,
					);
				}
			}
		} catch (error) {
			console.error("Error during sign-in:", error);
			Sentry.captureException(error);
			throw error;
		} finally {
			setLoading(false);
		}
	};

	/**
	 * Is called when user signs out. Removes all the values from indexedDB
	 */
	const handleOnSignOut = async () => {
		await clearData(mainDb.archivedReasons);
		await clearData(mainDb.doctorList);
		await clearData(mainDb.globalConfig);
		await clearData(mainDb.careCoordinator);
		await clearData(mainDb.priorityList);
		await clearData(mainDb.clinicLanguages);
		await clearData(mainDb.contactResolution);
		await clearData(mainDb.virtualCareProvider);
		await clearData(mainDb.appointmentCancelledReasons);
		await clearData(mainDb.roles);
		await clearData(mainDb.referringPhysicianOptoutDropdown);
		await clearData(mainDb.specialtyDropdown);
		await clearData(mainDb.locations);
		await clearData(mainDb.initialContactChannel);
		await clearData(mainDb.benefitProvider);
		await clearData(mainDb.patientPreferences);
		await clearData(mainDb.reasonForReferrals);
		await clearData(mainDb.patientLanguages);
		await clearData(mainDb.referringPhysicians);
		await clearData(mainDb.country);
		await clearData(mainDb.pronouns);
		await clearData(mainDb.appointmentTypes);
		await clearData(mainDb.faxReferralArchiveReasons);
		await clearData(mainDb.visitingPurpose);
		await clearData(mainDb.countryProvinces);
	};

	/**
	 * Is called when user changes their language or the reload socket event is called. First this Removes all the values from indexedDB then calls handleOnSignIn to fetch the new values
	 */
	const handleRefreshing = async () => {
		setLoading(true);
		await handleOnSignOut();
		await handleOnSignIn();
	};

	useEffect(() => {
		const cancelListener = Hub.listen("auth", async ({ payload }) => {
			switch (payload.event) {
				case "signIn":
					await handleOnSignIn();
					break;
				case "languageChange":
					await handleRefreshing();
					break;
				case "signOut":
					await handleOnSignOut();
					break;
				default:
					break;
			}
		});

		return () => {
			cancelListener();
		};
	}, []);

	useEffect(() => {
		if (reloadConfig) {
			handleRefreshing();
			dispatch(setReloadConfig(false));
		}
	}, [reloadConfig]);

	useEffect(() => {
		const checkIndexDbOpen = async () => {
			if (!mainDb.isOpen()) {
				await mainDb.open();
			}
		};
		checkIndexDbOpen();
	}, [mainDb.isOpen()]);

	return { loading };
}
