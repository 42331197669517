import React from "react";
import { MuiTheme as theme } from "../styles/theme";
import { alpha, Backdrop, CircularProgress } from "@mui/material";

interface LoaderProps {
	open: boolean;
	color?: string;
}

function CustomLoader({ open, color }: LoaderProps) {
	return (
		<Backdrop
			sx={{
				borderRadius: 2,
				backgroundColor: alpha(theme.palette.white.main, 0.6),
				zIndex: 9999,
			}}
			open={open}
		>
			<CircularProgress
				sx={{
					color: color || theme.palette.primary.main,
				}}
			/>
		</Backdrop>
	);
}

export default React.memo(CustomLoader);
