const frTranslations = {
	"Sign In": "Se connecter",
	"Sign out": " Se déconnecter",
	Next: "Suivant",
	"Add document": "Ajouter un document",
	"Add Document": "Ajouter un document",
	"All right reserved": "Tous droits réservés",
	"All Right Reserved": "Tous droits réservés",
	"Privacy Policy": "Politique de confidentialité",
	"PRIVACY POLICY": "POLITIQUE DE CONFIDENTIALITÉ",
	Inbox: "Boîte de réception",
	Archived: "Archivé",
	Archive: "Archives",
	Dashboard: "Tableau de bord",
	Referrals: "Demandes de RDV",
	Referral: "Renvoi",
	"In progress": "En cours",
	"In Progress": "En cours",
	"Same-sex relationship": "Relations entre personnes de même sexe",
	Patients: "Les patients",
	"Public Funded Waitlist": "Liste d’attente financée par les fonds publics",
	"Referring Physician": "Médecin référent",
	"Referring physician": "Médecin référent",
	"Referring Physicians": "Médecins traitants",
	Team: "L’équipe",
	"We are here to help you": "Nous sommes là pour vous aider",
	"New Referral": "Nouvelle référence",
	"My Account": "Mon compte",
	Previous: "Précédent",
	"Male/Female relationship": "Relation homme/femme",
	"Virtual care provider": "Fournisseur de soins virtuels",
	"Rate your experience?": "Évaluez votre expérience ?",
	"Rate your experience": "Évaluez votre expérience",
	"Please rate your overall experience":
		"S’il vous plaît évaluer votre expérience globale",
	"Need Help": "Besoin d’aide",
	"Need help": "Besoin d’aide",
	Hi: "Bonjour",
	Leads: "Responsables",
	Lead: "Responsable",
	Name: "Nom",
	name: "nom",
	"Referral Date": "Date de référence",
	"Entry Date": "Date d’entrée",
	Source: "Target",
	"Type of Referral": "Type de référence",
	"Referral name": "Nom de la référence",
	Phone: "Téléphone",
	phone: "téléphone",
	Email: "Courriel",
	email: "Courriel ...",
	nan: "Aucune",
	All: "Tous les",
	"Send invitation": "Envoyer une invitation",
	"Send Invitation": "Envoyer une invitation",
	"Form submitted": "Formulaire soumis",
	Appointments: "Rendez-vous",
	Appointment: "Nomination",
	Priority: "Priorité",
	Doctor: "Médecin",
	Waitlist: "Liste d’attente",
	Active: "Actif",
	"Doctor repository": "Répertoire des médecins",
	Campaign: "Campagne",
	Campaigns: "Campagnes",
	Filter: "Filtre",
	"Assign Campaign": "Attribuer la campagne",
	"Assign campaign": "Attribuer la campagne",
	"Mark as lead": "Marquez comme responsable",
	"Add Physician": "Ajouter un médecin",
	"Contact Info": "Coordonnées",
	Billing: "Facturation",
	"New Member": "Nouveau membre",
	"Team Members": "Membres de l’équipe",
	"Team members": "Membres de l’équipe",
	"Physician Name": "Nom du médecin",
	City: "Ville",
	"Last Segment status": "État du dernier segment",
	"Assigned campaign": "Campagne assignée",
	"Assigned Campaign": "Campagne assignée",
	Role: "Rôle",
	Status: "État d’avancement",
	"First Name": "Prénom",
	"First name": "Prénom",
	"Last Name": "Nom de famille",
	"Last name": "Nom de famille",
	Preferred: "Préféré",
	"Preferred Method of Contact": "Méthode de contact préférée",
	"Date Of Birth": "Date de naissance",
	"Date of Birth": "Date de naissance",
	"Fertility Benefit Provider": "Fournisseur d’avantages de fertilité",
	"I would like to help with": "Campagne assignée ...",
	"Reason For Referral": "Raison de l’aiguillage",
	"Reason for Referral": "Raison de l’aiguillage",
	"New Patient": "Raison de l’aiguillage ...",
	"Preferred Language": "Langue préférée",
	"Preferred language": "Langue préférée",
	"Preferred Location": "Emplacement préféré",
	"Referred To": "Mentionné",
	"Physician Referral": "Recommandation d’un médecin",
	"Add file": "Ajouter un fichier",
	"or drop a file here": "ou déposez un fichier ici",
	"Or Drop a file here": "Ou déposez un fichier ici",
	Notes: "Remarques :",
	"Lead Notes": "Notes de plomb",
	"Consent History": "Historique du consentement",
	"Add a note": "Ajouter une note",
	"Add A Note": "Ajouter une note",
	Save: "Enregistrer",
	Cancel: "Annuler",
	Close: "Fermer",
	"Very High": "Très élevé",
	High: "Élevée",
	Medium: "Moyenne",
	Low: "Faible",
	"Very Low": "Très faible",
	Edit: "Modifier",
	"Your Profile": "Votre profil",
	Logout: "Déconnexion",
	"The address used to identify this account cannot be changed":
		"L’adresse utilisée pour identifier ce compte ne peut pas être modifiée",
	"Change password": "Changer le mot de passe",
	"Change Password": "Changer le mot de passe",
	Back: "Retour",
	Password: "Mot de passe",
	"Confirm Password": "Confirmer le mot de passe",
	"Show Password": "Afficher le mot de passe",
	"Show password": "Afficher le mot de passe",
	"Receive insightful tips to support your fertility health.":
		"Recevez des conseils perspicaces pour soutenir votre santé en matière de fertilité.",
	"Receive insightful tips to support your fertility health":
		"L’adresse utilisée pour identifier ce compte ne peut pas être modifiée ...",
	"By creating an account": "En créant un compte",
	"I agree to": "Je suis d’accord pour",
	"Terms & Conditions": "Termes & Conditions",
	"Privacy Policy.": "Politique de confidentialité.",
	"Use 8 or more characters with a mix of letters, numbers & symbols.":
		"Utilisez 8 caractères ou plus avec un mélange de lettres, de chiffres et de symboles.",
	"Use 8 or more characters with a mix of letters, numbers & symbols":
		"Utilisez 8 caractères ou plus avec un mélange de lettres, de chiffres et de symboles",
	"Forgot your password?": "Vous avez oublié votre mot de passe ?",
	"Sex assigned at birth": "Sexe assigné à la naissance",
	"We're here to help you every step of the way":
		"Nous sommes là pour vous aider à chaque étape du processus",
	Female: "Femmes",
	female: "femmes",
	Male: "Hommes",
	male: "mâle",
	"Relationship status": "Statut relationnel",
	"Relationship Status": "Statut relationnel",
	"Relationship status?": "Statut relationnel?",
	Single: "Célibataire",
	"Male / female relationship": "Relation homme / femme",
	"Would you like to include your partner in your fertility journey at this time":
		"Souhaitez-vous inclure votre partenaire dans votre parcours de fertilité en ce moment",
	"Maybe later": "Peut-être plus tard",
	"What are your pronouns": "Quels sont vos pronoms",
	"He/Him": "Il/Elle",
	"She/Her": "Elle/Elle",
	"They/Them": "Ils/Eux (EUX)",
	"Health card Number (optional)":
		"Numéro de carte d’assurance-maladie (facultatif)",
	"Health Card Number (Optional)":
		"Numéro de carte d’assurance-maladie (facultatif)",
	"Health Card Number": "Numéro de carte Santé",
	"Health Card Number*": "Numéro de la carte d’assurance-maladie*",
	"Health Card Number is required": "Le numéro de carte Santé est requis",
	"Health Card Number must be 9 digits":
		"Le numéro de carte Santé doit être à 9 chiffres",
	"Please enter health card number first":
		"Veuillez d’abord entrer le numéro de la carte Santé",
	"Health Card Expiry is required":
		"L’expiration de la carte Santé est requise",
	"Date-Month-Year": "Date-Mois-Année",
	"Month, Day, Year": "Mois, Jour, Année",
	Address: "Adresse",
	"Address Line 2": "Adresse (ligne 2)",
	"Address Line 1": "Adresse (ligne 1)",
	"Address line 2": "Adresse (ligne 2)",
	"Address line 1": "Adresse (ligne 1)",
	"Appointment Seen": "Rendez-vous vu",
	"Appointment seen": "Rendez-vous vu",
	Province: "La province",
	"Search Address": "Adresse de recherche",
	"Reset Form": "Réinitialiser le formulaire",
	Country: "Pays",
	"Postal/Zip Code": "Code postal/postal",
	"There are 2 tasks you'll need to complete before your first appointment.":
		"Il y a 2 tâches que vous devrez effectuer avant votre premier rendez-vous.",
	"We look forward to seeing you and helping you take the next steps in your fertility journey.":
		"Nous sommes impatients de vous voir et de vous aider à franchir les prochaines étapes de votre parcours de fertilité.",
	"Task 1: Personal Information": "Tâche 1 : Renseignements personnels",
	"Task 2: Health History": "Tâche 2 : Antécédents médicaux",
	"Fertility Center Location": "Emplacement du centre de fertilité",
	"When you join a fertility center, you will be assigned to a specific location.":
		"Lorsque vous rejoignez un centre de fertilité, vous serez affecté à un emplacement spécifique.",
	"My Location": "Mon emplacement",
	"Change Location": "Changer d’emplacement",
	"Select Location": "Sélectionner l’emplacement",
	"Send Request": "Envoyer une demande",
	"Connect with a Fertility Nurse":
		"Connectez-vous avec une infirmière en fertilité",
	"Connect with a Nurse": "Connectez-vous avec une infirmière",
	"If you have any questions or need assistance, please don't hesitate to reach out.":
		"Si vous avez des questions ou avez besoin d’aide, n’hésitez pas à nous contacter.",
	"Here to Help": "Ici pour vous aider",
	"Nurse Name": "Nom de l’infirmière",
	"Available Hours": "Heures disponibles",
	"Send Message": "Envoyer un message",
	"View Profile": "Voir le profil",
	Calendar: "Calendrier",
	"Schedule an Appointment": "Prendre rendez-vous",
	"Choose a Date & Time": "Choisissez une date et une heure",
	"Select Date": "Sélectionner la date",
	"Select Time": "Sélectionnez l’heure",
	AM: "L’AM",
	PM: "Pm",
	"Appointment Type": "Type de rendez-vous",
	"Reason for Appointment": "Raison de la nomination",
	Duration: "Durée",
	"30 Minutes": "30 minutes",
	"60 Minutes": "60 minutes",
	"Appointment Date & Time": "Date et heure du rendez-vous",
	"Confirm Appointment": "Confirmer le rendez-vous",
	"Appointment Confirmation": "Confirmation de rendez-vous",
	"Your appointment has been confirmed for":
		"Votre rendez-vous a été confirmé pour",
	with: "avec",
	on: "le",
	"You will receive a confirmation email shortly.":
		"Vous recevrez un e-mail de confirmation sous peu.",
	"Manage Appointments": "Gérer les rendez-vous",
	"View All": "Tout voir",
	"Upcoming Appointments": "Rendez-vous à venir",
	"Appointment History": "Historique des rendez-vous",
	"You have no upcoming appointments.":
		"Vous n’avez pas de rendez-vous à venir.",
	"Your appointment history will be displayed here.":
		"L’historique de vos rendez-vous sera affiché ici.",
	"Appointment Details": "Détails du rendez-vous",
	"Appointment ID": "Id de rendez-vous",
	Date: "Date :",
	Time: "Temps",
	Location: "Situation géographique",
	"Care Coordinator": "Coordonnateur des soins",
	Reason: "Raison",
	"Set Priority": "Établir la priorité",
	Completed: "Terminé",
	Cancelled: "Annulé",
	Rescheduled: "Reprogrammé",
	"View All Notes": "Voir toutes les notes",
	"Add a New Note": "Ajouter une nouvelle note",
	"Your notes will be displayed here.": "Vos notes seront affichées ici.",
	"Enter your note here...": "Entrez votre note ici...",
	"Patient Information": "Renseignements sur le patient",
	"Emergency Contact Information":
		"Coordonnées des personnes-ressources en cas d’urgence",
	"Emergency Contact Name": "Nom de la personne-ressource en cas d’urgence",
	"Emergency Contact Relationship": "Relation de contact en cas d’urgence",
	"Emergency Contact Phone": "Téléphone de contact d’urgence",
	"Insurance Information": "Renseignements sur l’assurance",
	"Primary Insurance Provider": "Fournisseur d’assurance principal",
	"Policy Holder Name": "Nom du titulaire de police",
	"Policy Number": "Numéro de police",
	"Group Number": "Numéro de groupe",
	"Secondary Insurance Provider": "Fournisseur d’assurance secondaire",
	Prediagnostic: "Prédiagnostique",
	"Employment Information": "Renseignements sur l’emploi",
	"Employment Status": "Situation d’emploi",
	"Employer Name": "Nom de l’employeur",
	Occupation: "L’occupation",
	"Work Phone": "Téléphone au travail",
	"School Information": "Renseignements sur l’école",
	"School Name": "Nom de l’école",
	"Grade Level": "Niveau scolaire",
	"School Phone": "Téléphone de l’école",
	"Referred By": "Référé par",
	"About Us": "À propos de nous",
	"How did you hear about us": "Comment avez-vous entendu parler de nous",
	"Please select": "Veuillez sélectionner",
	"Health Information": "Information sur la santé",
	"Medical History": "Antécédents médicaux",
	"Care Team": "Équipe soignante",
	"Have you ever been diagnosed with a genetic disorder":
		"Avez-vous déjà reçu un diagnostic de trouble génétique",
	Yes: "Oui",
	No: "Non",
	NO: "NON",
	"Please specify": "Veuillez préciser",
	"Have you ever had a genetic test": "Avez-vous déjà subi un test génétique",
	"Last Contacted": "Dernier contact",
	"Do you have a family history of fertility issues":
		"Avez-vous des antécédents familiaux de problèmes de fertilité",
	"Are you currently taking any medications or supplements":
		"Prenez-vous actuellement des médicaments ou des suppléments",
	"Report Day 1": "Jour de rapport 1",
	"Are you currently experiencing any of the following":
		"Rencontrez-vous actuellement l’un des éléments suivants",
	"None of the above": "Rien de tout cela",
	"Referral Reason": "Motif de la demande de consultation",
	"Have you ever been diagnosed with a sexually transmitted infection (STI)":
		"Avez-vous déjà reçu un diagnostic d’infection transmissible sexuellement (ITS)",
	" Documents": " Documents d’information",
	Lifestyle: "Mode de vie",
	"Test requisitions": "Demandes d’essai",
	"Tobacco Use": "Usage du tabac",
	"Alcohol Consumption": "Consommation d’alcool",
	"Substance Use": "Consommation de substances",
	"Appointment Booked": "Rendez-vous réservé",
	"Exercise Habits": "Habitudes d’exercice",
	"New appointment": "Nouveau rendez-vous",
	"Dietary Preferences": "Préférences alimentaires",
	"Additional Information": "Renseignements supplémentaires",
	"Is there anything else you would like to share about your health or fertility journey":
		"Y a-t-il autre chose que vous aimeriez partager sur votre parcours de santé ou de fertilité",
	"Your answers have been saved.": "Vos réponses ont été sauvegardées.",
	"Back to Dashboard": "Retour au tableau de bord",
	"Task 3: Fertility Assessment": "Tâche 3 : Évaluation de la fécondité",
	"Understand Your Fertility": "Comprendre votre fertilité",
	"Contact Attempts": "Tentatives de contact",
	"Contact attempts": "Tentatives de contact",
	"Contact atempts": "Contactez atempts",
	Unassigned: "Non affecté",
	Regular: "Régulier",
	"No Partner": "Aucun partenaire",
	"No Partner Invited": "Aucun partenaire invité",
	"No partner Invited": "Aucun partenaire invité",
	"User successfully deleted": "Utilisateur supprimé avec succès",
	"Patient Name": "Nom du patient",
	"Patient name": "Nom du patient",
	"Add A New Campaigns": "Ajouter Une Nouvelle Campagne",
	"Start Date Is Required": "La Date De Début Est Requise",
	"Name Is Required": "Le nom est requis",
	"Name is required": "Le nom est requis",
	"Start Date is invalid": "La date de début n’est pas valide",
	"Pending Invitation": "Invitation en attente",
	Disable: "Désactiver",
	"Pending Invite": "Invitation en attente",
	"Edit Referrals": "Modifier les références",
	"Edit Referral": "Modifier la référence",
	Invited: "Invité",
	"Referral To": "Référé à",
	"Care Coordinator is required": "Le coordonnateur des soins est requis",
	Optional: "Facultatif",
	optional: "facultatif",
	"Priority must be selected": "La priorité doit être choisie",
	Loading: "Chargement",
	"First name is required": "Le prénom est requis",
	"First Name is required": "Le prénom est requis",
	"This field is required.": "Ce champ est obligatoire.",
	"This field is required": "Ce champ est obligatoire",
	"Last name is required.": "Le nom de famille est requis.",
	"Last Name is required.": "Le nom de famille est requis.",
	"Last Name is required": "Le nom de famille est requis",
	"Last name is required": "Le nom de famille est requis",
	"Email is invalid": "Le courrier électronique n’est pas valide",
	"Email is already taken": "L’e-mail est déjà pris",
	"Phone number is required": "Le numéro de téléphone est requis",
	"Invalid phone number": "Numéro de téléphone non valide",
	"Phone number is already taken": "Le numéro de téléphone est déjà pris",
	"Date of birth is invalid": "La date de naissance n’est pas valide",
	"Date Of Birth cannot be less than 10 years":
		"La date de naissance ne peut pas être inférieure à 10 ans",
	"Preferred location is required": "L’emplacement préféré est requis",
	"Preferred Location is required": "L’emplacement préféré est requis",
	"Please Select a physician": "Veuillez sélectionner un médecin",
	"Please Select a Physician": "Veuillez sélectionner un médecin",
	"Please Enter Some Text": "S’il vous plaît entrer un peu de texte",
	"Primary Patient": "Patient primaire",
	"Add Inactive Partner": "Ajouter un partenaire inactif",
	Pronouns: "Pronoms",
	"Health Card Expiry is invalid":
		"L’expiration de la carte Santé n’est pas valide",
	"Health Card Expiry": "Expiration de la carte Santé",
	"Health Card Expiry*": "Expiration de la carte Santé*",
	"Same as primary": "Identique à primaire",
	"Same as Primary": "Identique à primaire",
	"Street Address": "Adresse municipale",
	Region: "Région",
	"Move To Active Partner": "Passer à partenaire actif",
	"Olivia Chloe": "Olivia Chloé",
	Primary: "Primaire",
	"Diagnosed with endometriosis": "Diagnostic d’endométriose",
	Contact: "Contact",
	"Phone Call": "Appel téléphonique",
	"General Practitioners": "Médecins généralistes",
	REI: "REI",
	"Select the test type": "Sélectionnez le type de test",
	"Add Test requisitions": "Ajouter des demandes de test",
	Unarchive: "Désarchiver",
	"Archive patient": "Archiver le patient",
	Profile: "Profil",
	Doctors: "Médecins",
	"Referring Doctor": "Médecin référent",
	"Referring doctor": "Médecin référent",
	"Added To IVF Funded": "Ajouté à la FIV financée",
	"Remove From Public": "Retirer du public",
	"Funded Waitlist": "Liste d’attente financée",
	"Add to public funded": "Ajouter au financement public",
	"Health form": "Formulaire de santé",
	Submitted: "Soumis",
	"Consent Form": "Formulaire de consentement",
	"No Appointment Scheduled": "Aucun rendez-vous n’est prévu",
	"No appointment scheduled": "Aucun rendez-vous n’est prévu",
	"You are about to unarchive the records of":
		"Vous êtes sur le point de désarchiver les dossiers de",
	and: "et",
	"Patient records will be moved To th Previous stage":
		"Les dossiers des patients seront transférés à l’étape précédente",
	Ok: "Ok",
	"Please select a priority": "Veuillez sélectionner une priorité",
	"Virtual Care provider is required":
		"Un fournisseur de soins virtuels est requis",
	"Virtual Care Provider is required":
		"Un fournisseur de soins virtuels est requis",
	"Received on is required": "Reçu le est obligatoire",
	"Received on is invalid": "Reçu le n’est pas valide",
	"Please cancel your appointments first then unmerge the partner":
		"Veuillez d’abord annuler vos rendez-vous, puis défusionner le partenaire",
	"Resend Email": "Renvoyer l’e-mail",
	"Are you sure!": "Es-tu certain!",
	"Do you really want to delete?": "Voulez-vous vraiment supprimer ?",
	"Pre-diagnostic form": "Formulaire de pré-diagnostic",
	"Pre-diagnostic": "Prédiagnost diagnostique",
	"Todays appointment": "Rendez-vous d’aujourd’hui",
	"Todays Appointment": "Rendez-vous d’aujourd’hui",
	"Join Meeting": "Rejoignez la réunion",
	"Please unmerge the partner before changing the relationship status to single":
		"Veuillez dissocier le partenaire avant de changer le statut de la relation en célibataire",
	"Edit partner information": "Modifier les informations sur le partenaire",
	"Edit lead information": "Modifier les informations des leads",
	"Email is required": "L’e-mail est requis",
	"Referral date is required": "La date de référence est requise",
	"Referral Date is required": "La date de référence est requise",
	"Referral date is invalid": "La date de renvoi n’est pas valide",
	"Referral date cannot be after today's date":
		"La date de renvoi ne peut pas être postérieure à la date d’aujourd’hui",
	"Sex At Birth": "Sexe à la naissance",
	Maybe: "Peut-être",
	Unmerge: "Annuler la fusion",
	"Reason for Referral is required": "La raison de l’aiguillage est requise",
	"Reason for referral is required": "La raison de l’aiguillage est requise",
	"Reason For referral is required": "Raison De l’aiguillage est requis",
	"New patient is required": "Un nouveau patient est nécessaire",
	"Save & Send Invitation": "Enregistrer et envoyer une invitation",
	"Save & Send invitation": "Enregistrer et envoyer l’invitation",
	"Sex At Birth is required": "Le sexe à la naissance est requis",
	"Two Step verification": "Vérification en deux étapes",
	"Two Step Verification": "Vérification en deux étapes",
	"Initial Contact Channel": "Canal de contact initial",
	"Your response has been submitted": "Votre réponse a été soumise",
	Share: "Partager",
	"Invitation has been sent to prospects":
		"Une invitation a été envoyée aux prospects",
	"No leads selected": "Aucun responsable n’a été sélectionné",
	"Edit profile information": "Modifier les informations de profil",
	"Patient Todo Tasks": "Tâches en suspens du patient ",
	"Partner Todo Tasks": "Tâches en suspens du partenaire",
	"Not Completed": "Non terminé",
	"Resend invitation": "Renvoyer l’invitation",
	"Resend Invitation": "Renvoyer l’invitation",
	"Invite Partner": "Inviter le partenaire",
	assign: "assigner",
	Assign: "Attribuer",
	Physician: "Médecin",
	"Received on": "Date de réception",
	"Source is required.": "La source est requise.",
	"EMR Patient ID": "Id du patient DME",
	Apt: "Apt",
	"Concent Form": "Forme concente",
	"Write a note": "Écrivez une note",
	"Partner Invited": "Invité des partenaires",
	"Merge Existing": "Fusionner existant",
	"Partner Not Invited": "Partenaire non invité",
	"Edit Patient information": "Modifier les informations sur le patient",
	"Edit Partner": "Modifier le partenaire",
	Unisex: "Unisexe",
	"Invite lead/patient": "Inviter le responsable/patient",
	"Date of Appointment": "Date de nomination",
	From: "À partir de",
	FROM: "À PARTIR DE",
	"Video chat link": "Lien de chat vidéo",
	"If the email is in our system, you'll receive a password reset link":
		"Si l’e-mail est dans notre système, vous recevrez un lien de réinitialisation de mot de passe",
	"Reset password": "Réinitialiser le mot de",
	"Reset Password": "Réinitialiser le mot de",
	"Enter an email associated with your account and we will send an email with instructions to reset your password":
		"Entrez un e-mail associé à votre compte et nous vous enverrons un e-mail avec des instructions pour réinitialiser votre mot de passe",
	"Enter Email": "Entrez e-mail",
	"We have sent you an email with instructions to reset your password":
		"Nous vous avons envoyé un e-mail avec des instructions pour réinitialiser votre mot de passe",
	"Did not receive your email? Check your spam folder":
		"Vous n’avez pas reçu votre courriel ? Vérifiez votre dossier de spam",
	"Did not receive your email?": "Vous n’avez pas reçu votre courriel ?",
	"Did not receive your email": "Vous n’avez pas reçu votre e-mail",
	"If you didn't receive an email": "Si vous n’avez pas reçu d’e-mail",
	"Resend code": "Renvoyer le code",
	"8 characters minimum": "8 caractères minimum",
	"Password must contain at least one number":
		"Le mot de passe doit contenir au moins un numéro",
	"Password must contain at least one special character":
		"Le mot de passe doit contenir au moins un caractère spécial",
	"Password is required": "Le mot de passe est requis",
	"Confirm Password is required": "Confirmer que le mot de passe est requis",
	"Both passwords must match": "Les deux mots de passe doivent correspondre",
	"Password requirements do not meet":
		"Les exigences en matière de mot de passe ne répondent pas",
	"Are you sure you want to mark the health form as completed":
		"Êtes-vous sûr de vouloir marquer le formulaire de santé comme rempli",
	"This will render the patient unable to make any changes to their health form":
		"Cela rendra le patient incapable d’apporter des modifications à son formulaire de santé",
	"Please select an answer": "Veuillez sélectionner une réponse",
	"Please provide details": "Veuillez fournir des détails",
	"Please enter a value greater than zero":
		"Entrez une valeur supérieure à zéro",
	"Something went wrong": "Quelque chose s’est mal passé",
	"Patient health history": "Antécédents médicaux des patients",
	"Mark As Completed": "Marquer comme terminé",
	"Download PDF": "Télécharger le PDF",
	"New Password": "Nouveau mot de passe",
	"New password": "Nouveau mot de passe",
	"Use the form below to reset the password for your account":
		"Utilisez le formulaire ci-dessous pour réinitialiser le mot de passe de votre compte",
	"Use the form below to change the password for your CLINIC_NAME account":
		"Utilisez le formulaire ci-dessous pour modifier le mot de passe de votre compte CLINIC_NAME",
	"Logged in with email": "Connecté avec e-mail",
	Add: "Ajouter",
	"Add partner": "Ajouter un partenaire",
	"Add Partner": "Ajouter un partenaire",
	"Password Expired": "Mot de passe expiré",
	"Your password has expired": "Votre mot de passe a expiré",
	"To reset your password, please click on the":
		"Pour réinitialiser votre mot de passe, veuillez cliquer sur le bouton",
	"Reset Button": "Bouton de réinitialisation",
	"You will not be able to revert": "Vous ne pourrez pas revenir en arrière",
	"Are you sure you want to": "Êtes-vous sûr que vous voulez",
	"remove this user": "supprimer cet utilisateur",
	"Yes, delete this user": "Oui, supprimez cet utilisateur",
	"Enter Your Registered ID": "Entrez votre pièce d’identité enregistrée",
	"Billing Number": "Numéro de facturation",
	"Enter Your Billing Number": "Entrez votre numéro de facturation",
	"Reg ID": "Reg ID",
	"Area(s) of SPEC": "Zone(s) de la SPEC",
	"Total Referrals": "Nombre total de renvois",
	"First Date of Referral": "Première date de renvoi",
	"Last Date of Referral": "Dernière date de renvoi",
	"Opt out": "Se désinscrire",
	"Opt Out": "Désinscription",
	"Edit Physician": "Modifier le médecin",
	"Registered ID": "Identifiant enregistré",
	"Invalid Number": "Numéro non valide",
	"Previous campaigns": "Campagnes précédentes",
	"Exclude Opt Out": "Exclure la désactivation",
	"Physician Within Radius By Clinic Location":
		"Médecin dans le rayon par emplacement de la clinique",
	Clinic: "Clinique",
	Patient: "Patient",
	"Please Provide A Clinic": "Veuillez Fournir Une Clinique",
	Distance: "Distance",
	Km: "Km",
	"Referral received during a defined period of time":
		"Renvoi reçu au cours d’une période de temps définie",
	"Please Provide Start Date": "Veuillez indiquer la date de début",
	"Start Date Cannot Be After End Date":
		"La Date De Début Ne Peut Pas Être Postérieure À La Date De Fin",
	"Start Date": "Date de début",
	"Please Provide End Date": "Veuillez indiquer la date de fin",
	"End Date Cannot Be Before Start Date":
		"La Date De Fin Ne Peut Pas Être Avant La Date De Début",
	"End Date": "Date de fin",
	"Please Provide Minimum Referrals":
		"S’il vous plaît fournir des références minimales",
	"Minimum referrals": "Nombre minimum de renvois",
	"Filter by Segment status": "Filtrer par état de segment",
	"Please Provide Year": "Veuillez indiquer l’année",
	"Please Provide Month": "S’il vous plaît fournir le mois",
	"Please Provide Status": "Veuillez indiquer l’état d’avancement",
	"Filter by Contact info": "Filtrer par coordonnées",
	Contains: "Contient",
	Apply: "Présenter une demande",
	"Clear All": "Tout effacer",
	"New Campaign": "Nouvelle campagne",
	"End date cannot be before or same to start date":
		"La date de fin ne peut pas être avant ou identique à la date de début",
	"Target audience is required": "Le public cible est requis",
	"Target Audience": "Public cible",
	"Campaign Channel": "Canal de campagne",
	"Campaign channel is required": "Le canal de campagne est requis",
	"Campaign info is required": "Les informations de campagne sont requises",
	"Edit Campaign": "Modifier la campagne",
	"Start date is required": "La date de début est requise",
	"End date is required": "La date de fin est requise",
	"End Date is invalid": "La date de fin n’est pas valide",
	"Select campaign": "Sélectionner une campagne",
	"remove this campaign": "supprimer cette campagne",
	"No Physicians can be marked as lead":
		"Aucun médecin ne peut être marqué comme plomb",
	"Are you sure you want to Mark these Items as Lead":
		"Êtes-vous sûr que vous voulez marquer ces éléments comme plomb",
	"Selected physicians will be marked as lead":
		"Les médecins sélectionnés seront marqués comme chefs de file",
	"Selected physicians already have a referral":
		"Les médecins sélectionnés ont déjà une référence",
	"Yes, Mark As Lead": "Oui, MarqueZ Comme Plomb",
	"Please select a campaign": "Veuillez sélectionner une campagne",
	"Edit Referring Physician": "Modifier le médecin référent",
	Remove: "Supprimer",
	"Token not found": "Jeton introuvable",
	"Passwords do not match": "Les mots de passe ne correspondent pas",
	"Password field empty": "Champ mot de passe vide",
	"Create your account": "Créer votre compte",
	"Confirm new password": "Confirmer le nouveau mot de passe",
	"Confirm New Password": "Confirmer le nouveau mot de passe",
	"Create account": "Créer un compte",
	"The address used to identify this":
		"L’adresse utilisée pour identifier cela",
	"account cannot be changed": "le compte ne peut pas être modifié",
	Disabled: "Handicapé",
	"Team member": "Membre de l’équipe",
	"disable this user": "désactiver cet utilisateur",
	"The following email address will receive a link to create an account":
		"L’adresse e-mail suivante recevra un lien pour créer un compte",
	"Current password is required": "Le mot de passe actuel est requis",
	"Password length should be at least 4 characters":
		"La longueur du mot de passe doit être d’au moins 4 caractères",
	"Password is successfully updated":
		"Le mot de passe est mis à jour avec succès",
	"Current Password is incorrect": "Le mot de passe actuel est incorrect",
	"Password Change limit reached. Please try after some time":
		"Limite de modification de mot de passe atteinte. S’il vous plaît essayer après un certain temps",
	"Profile picture updated successfully":
		"Photo de profil mise à jour avec succès",
	"Please select a file to upload":
		"Veuillez sélectionner un fichier à télécharger",
	"Profile updated successfully": "Profil mis à jour avec succès",
	"Page Not Found": "Page introuvable",
	"Return Back": "Retour en arrière",
	Test: "Essai",
	"Please select a contact method":
		"Veuillez sélectionner une méthode de contact",
	"Forgot your password": "Vous avez oublié votre mot de passe",
	"Incorrect OTP. You have": "OTP incorrect. Tu l’as fait",
	"tries remaining": "essais restants",
	"Your session has expired. Please login again":
		"Votre session a expiré. Veuillez vous reconnecter",
	"A verification code has been sent to":
		"Un code de vérification a été envoyé à",
	"Check your Email and input the": "Vérifiez votre e-mail et entrez le",
	"OTP is required": "Otp est requis",
	"OTP must be 6 digits": "OTP doit être de 6 chiffres",
	Verify: "Vérifier",
	"If you didn't receive a code": "Si vous n’avez pas reçu de code",
	"Yes, archive patient": "Oui, archiver le patient",
	"Reason Is Required": "La Raison Est Requise",
	"Method of contact": "Méthode de contact",
	"Selection required": "Sélection requise",
	"Contact Resolution": "Résolution de contact",
	"New Referring Physician": "Nouveau médecin traitant",
	"Phone number has to be 10 digits minimum":
		"Le numéro de téléphone doit être de 10 chiffres minimum",
	"Phone number can be 10 digits maximum":
		"Le numéro de téléphone peut être de 10 chiffres maximum",
	"Phone number can only include digits":
		"Le numéro de téléphone ne peut inclure que des chiffres",
	"Phone Number": "Numéro de téléphone",
	Fax: "Télécopieur",
	"Add New Physician": "Ajouter un nouveau médecin",
	"New Physician": "Nouveau médecin",
	"Are you sure you want to ": "Êtes-vous sûr que vous voulez ",
	remove: "supprimer",
	"Yes, delete archived": "Oui, supprimer archivé",
	"delete this comment": "supprimer ce commentaire",
	"Yes, delete this comment": "Oui, supprimez ce commentaire",
	"Are you sure": "Êtes-vous sûr",
	Move: "Déplacer",
	"from Lead appointments to Patients":
		"des rendez-vous de contact principal aux patients",
	"from Leads to Patients": "de prospects à patients",
	"Please enter the patient's EMR ID to confirm ":
		"Veuillez saisir l'identifiant DME du patient pour confirmer ",
	"this action. This cannot be undone":
		"cette action. Ça ne peut pas être annulé",
	"Please enter the patient‘s EMR ID to confirm this action. This cannot be undone":
		"Veuillez entrer l'ID EMR du patient pour confirmer cette action. Cette action est irréversible.",
	"Primary Patient EMR ID is required":
		"L'ID EMR du patient principal est requis",
	"Primary Patient EMR ID": "ID EMR du patient principal",
	"Partner EMR ID is required": "L'ID EMR du partenaire est requis",
	"Partner EMR ID": "ID EMR du partenaire",
	Confirm: "Confirmer",
	Partner: "Partenaire",
	Other: "Autres",
	"Please confirm that do you really want to move this partner as an Active Partner":
		"L’adresse e-mail suivante recevra un lien pour créer un compte ...",
	"Date is required": "La date est requise",
	"Date is invalid": "La date n’est pas valide",
	"Yes, Unarchive": "Oui, DésarchiveR",
	"Are you sure you want to Unassign":
		"Êtes-vous sûr que vous voulez annuler l’affectation",
	"Are you sure you want to Unmerge":
		"Êtes-vous sûr que vous voulez défusionner",
	"If you want to merge again, you can merge them in the Lead list":
		"Si vous souhaitez fusionner à nouveau, vous pouvez les fusionner dans la liste Lead",
	"Are you sure you want to cancel": "Êtes-vous sûr de vouloir annuler",
	"the appointment": "la nomination",
	"Canceling Reason is required": "L’annulation de la raison est requise",
	"Other reason is required": "Une autre raison est requise",
	"Please invite either primary patient or partner":
		"Veuillez inviter le patient principal ou le partenaire",
	"Please Select A Clinic Location":
		"Veuillez Sélectionner L’Emplacement D’Une Clinique",
	"Clinic Locations": "Emplacements des cliniques",
	"Please select appointment type first":
		"Veuillez d’abord sélectionner le type de rendez-vous",
	"Profile incomplete. Missing to-do tasks":
		"Profil incomplet. Tâches restant à accomplir",
	"Appointment type": "Type de rendez-vous",
	"Appointment type is required": "Le type de rendez-vous est requis",
	"Visit type": "Type de visite",
	"Visit type is required": "Le type de visite est requis",
	"Please Select A Doctor": "Veuillez Sélectionner Un Médecin",
	"Date of Appointment is required": "La date de nomination est requise",
	"Date of Appointment is invalid": "La date de nomination n’est pas valide",
	"Appointment date must be greater than or equal to":
		"La date de nomination doit être supérieure ou égale à",
	"From Time is required": "À partir du temps est requis",
	"To Time is required": "Le temps requis est requis",
	"To time must be greater than from time":
		"Le temps doit être supérieur à celui du temps",
	"Copy Link": "Copier le lien",
	"Link Copied successfully": "Lien copié avec succès",
	"Save & send email": "Sauvegarder et envoyer le courriel",
	"Cancel Appointment": "Annuler le rendez-vous",
	English: "Anglais",
	French: "Français",
	"From time must be greater than": "À partir du temps doit être supérieur à",
	"Password must meet the": "Le mot de passe doit respecter le",
	"following requirements": "suivre les exigences",
	"Send Email": "Envoyer un e-mail",
	"Confirm Invitations": "Confirmer les invitations",
	"You‘re sending": "Vous envoyez",
	"invitations via both sms and email":
		"des invitations à la fois par message texte et par courriel",
	"just by sms, correct": "juste par SMS, correct",
	"Check your Message and input the": "Vérifiez votre message et entrez le",
	"verification code below to Continue":
		"code de vérification ci-dessous pour continuer",
	"A verification code has been sent to your email":
		"Un code de vérification a été envoyé à votre e-mail",
	"Enter Code": "Entrez le code",
	"Password did not conform with policy: Password must have uppercase characters":
		"Le mot de passe n’était pas conforme à la stratégie : le mot de passe doit comporter des caractères majuscules",
	"Update your password for your Generation Fertility account below":
		"Mettez à jour votre mot de passe pour votre compte Generation Fertility ci-dessous",
	"Address Information": "Renseignements sur l’adresse",
	"City is required": "La ville est requise",
	"Province is required": "La province est requise",
	"Country is required": "Le pays est requis",
	"Postal/Zip Code is required": "Le code postal est requis",
	"Contact Information": "Coordonnées de la personne-ressource",
	"Phone number is not a valid US/Canadian number":
		"Le numéro de téléphone n’est pas un numéro américain ou canadien valide",
	"This phone number is not verified":
		"Ce numéro de téléphone n’est pas vérifié",
	Language: "Langue",
	Notifications: "Notifications",
	"CLINIC_NAME may still send you important notifications about your account and content outside of your preferred notification settings":
		"CLINIC_NAME pouvez toujours vous envoyer des notifications importantes sur votre compte et votre contenu en dehors de vos paramètres de notification préférés",
	"Send me notifications for": "Envoyez-moi des notifications pour",
	"Task assignments": "Affectations de tâches",
	Reminders: "Rappels",
	"No tasks have been completed yet": "Aucune tâche n’a encore été accomplie",
	"Insightful Tips": "Conseils perspicaces",
	"Fertility health tips": "Conseils de santé sur la fertilité",
	"Where you will be notified": "Où vous serez avisé",
	SMS: "SMS",
	"Preferred Name": "Nom préféré",
	"Preferred name": "Nom préféré",
	"Security & Data": "Sécurité et données",
	"Account closure": "Fermeture de compte",
	Preferences: "Préférences",
	"Edit Address Information": "Modifier les informations d’adresse",
	"Edit Contact Information": "Modifier les coordonnées",
	"Edit Profile": "Modifier le profil",
	"Please enter valid date": "Veuillez saisir la date de validité",
	"Personal Info": "Renseignements personnels",
	"Postal Code": "Code postal",
	"Use the form below to change the password for your Generation fertility account":
		"Utilisez le formulaire ci-dessous pour modifier le mot de passe de votre compte de fertilité Generation",
	"Current password": "Mot de passe actuel",
	"You're amazing": "Vous êtes incroyable",
	"All tasks complete": "Toutes les tâches sont terminées",
	"Report the first day of your period":
		"Signalez le premier jour de vos règles",
	"Your estimated date must be confirmed by":
		"Votre date estimée doit être confirmée par",
	"Today's Appointment": "Rendez-vous d’aujourd’hui",
	"Your upcoming appointments": "Vos prochains rendez-vous",
	"will be listed here": "sera répertorié ici",
	Article: "Article premier",
	"Logging out": "Déconnexation",
	"April 1, 2023": "Le 1er avril 2023",
	"Last Updated": "Dernière mise à jour",
	"Last updated": "Dernière mise à jour",
	"is committed to protecting the privacy and confidentiality of personal information in our custody and control. This Privacy Policy describes how personal information is collected, used, and disclosed in connection with the TFP Care Navigation platform (the":
		"s’engage à protéger la vie privée et la confidentialité des renseignements personnels dont nous avons la garde et le contrôle. Cette politique de confidentialité décrit comment les renseignements personnels sont recueillis, utilisés et divulgués dans le cadre de la plateforme de navigation de soins TFP (le",
	"is committed to protecting the privacy and confidentiality of personal information in our custody and control. This Privacy Policy describes how personal information is collected, used, and disclosed in connection with the CLINIC_NAME":
		"s’engage à protéger la vie privée et la confidentialité des renseignements personnels dont nous avons la garde et le contrôle. Cette politique de confidentialité décrit comment les renseignements personnels sont recueillis, utilisés et divulgués dans le cadre de la CLINIC_NAME",
	us: "nous",
	or: "ou",
	our: "notre",
	Platform: "Plate-forme",
	"and website located at": "et site Web situé à l’adresse suivante :",
	"Table of Contents": "Table des matières",
	"Collection and Use of Personal Information":
		"Collecte et utilisation des renseignements personnels",
	"Information Collected Automatically":
		"Informations collectées automatiquement",
	"Disclosure of Personal Information":
		"Divulgation de renseignements personnels",
	"Third Party Links": "Liens de tiers",
	Safeguards: "Mesures de protection",
	"Retention of Personal Information":
		"Conservation des renseignements personnels",
	"Your Choices": "Vos choix",
	"Your Rights": "Vos droits",
	"Changes to This Privacy Policy":
		"Modifications apportées à cette politique de confidentialité",
	"Our Platform provides tools that allow a fertility clinic’s care coordinators":
		"Notre plate-forme fournit des outils qui permettent aux coordinateurs de soins d’une clinique de fertilité",
	"and physicians or other healthcare professionals":
		"et les médecins ou autres professionnels de la santé",
	"Healthcare Professionals": "Professionnels de la santé",
	"collectively our": "collectivement, notre",
	"to share fertility resources, to access practice management tools, and to provide fertility services to their patients":
		"partager des ressources sur la fertilité, accéder à des outils de gestion de la pratique et fournir des services de fertilité à leurs patients",
	"including management of the patient journey virtually. Our Platform also allows Patients to view information related to their fertility treatment assigned to them by Care Coordinators or Health Professionals and to communicate with their Care Coordinators or Health Professionals. CLINIC_NAME is not a medical or healthcare professional and does not provide fertility or other medical services to Patients. CLINIC_NAME provides the Platform to its Clients as a tool for them to service their Patients":
		"y compris la gestion du parcours du patient virtuellement. Notre plateforme permet également aux patients de consulter les informations relatives à leur traitement de fertilité qui leur est attribué par les coordonnateurs de soins ou les professionnels de la santé et de communiquer avec leurs coordonnateurs de soins ou professionnels de la santé. CLINIC_NAME n’est pas un professionnel de la santé ou de la santé et ne fournit pas de services de fertilité ou d’autres services médicaux aux patients. CLINIC_NAME fournit la plate-forme à ses clients comme un outil pour eux de servir leurs patients",
	"We process Patient Data on behalf of our Clients as their service provider. Our Clients are accountable for the Patient Data that we process on their behalf. If you are a Patient of one of our Clients and you have questions about how your Patient Data is handled, please contact them directly":
		"Nous traitons les données des patients au nom de nos clients en tant que fournisseur de services. Nos clients sont responsables des données des patients que nous traitons en leur nom. Si vous êtes un patient de l’un de nos clients et que vous avez des questions sur la façon dont vos données de patient sont traitées, veuillez les contacter directement",
	"In providing the Platform, we collect personal information relating to our Clients and their employees and independent contractors":
		"En fournissant la plate-forme, nous recueillons des informations personnelles relatives à nos clients et à leurs employés et entrepreneurs indépendants",
	"Client Data": "Données sur le client",
	"We also collect personal information relating to our":
		"Nous recueillons également des renseignements personnels relatifs à notre",
	"Client’s Patients": "Patients du client",
	"Patient Data": "Données sur les patients",
	"We collect Patient Data on behalf of our Clients and in accordance with their instructions. The Patient Data we collect will depend on how our Client uses the Platform, but may include the following information about Patients":
		"Nous recueillons des données sur les patients au nom de nos clients et conformément à leurs instructions. Les données des patients que nous recueillons dépendront de la façon dont notre client utilise la plate-forme, mais peuvent inclure les informations suivantes sur les patients",
	"Name and contact information (including mailing address, email address, and telephone number":
		"Nom et coordonnées (y compris l’adresse postale, l’adresse e-mail et le numéro de téléphone",
	"Date of birth, gender, sex at birth, relationship status, and other demographic information":
		"Date de naissance, genre, sexe à la naissance, statut relationnel et autres informations démographiques",
	"Account information such as username and password":
		"Informations de compte telles que le nom d’utilisateur et le mot de passe",
	"Billing information including provincial/territorial health insurance plan (health card) number, private medical insurance details, and credit card information":
		"Renseignements sur la facturation, y compris le numéro de régime d’assurance-maladie provincial/territorial (carte d’assurance-maladie), les détails de l’assurance médicale privée et les renseignements sur la carte de crédit",
	"Health information including medical history, family history, test results, reports, symptoms, diagnosis, treatments performed, prescriptions, appointment information, and consents provided":
		"Renseignements sur la santé, y compris les antécédents médicaux, les antécédents familiaux, les résultats des tests, les rapports, les symptômes, le diagnostic, les traitements effectués, les ordonnances, les renseignements sur les rendez-vous et les consentements fournis",
	"Information about the Patient’s use of the Platform and interactions with Care Coordinators and Healthcare Professionals":
		"Informations sur l’utilisation de la plateforme par le patient et les interactions avec les coordonnateurs de soins et les professionnels de la santé",
	"Opinions and feedback shared with Clients":
		"Opinions et commentaires partagés avec les clients",
	"Additional information Patients may choose to provide such as queries and requests":
		"Informations supplémentaires Les patients peuvent choisir de fournir, telles que des requêtes et des demandes",
	"We also collect certain Patient Data automatically (see “Information We Collect Automatically” section below)":
		"Nous recueillons également certaines données des patients automatiquement (voir la section « Informations que nous recueillons automatiquement » ci-dessous)",
	"We use Patient Data to provide the Platform to our Clients and otherwise in accordance with their instructions. We may also use Patient Data to generate anonymized data, which is not capable of identifying a Patient, whether directly or indirectly, and may use anonymized data to improve our products and services, for market research purposes, and for other lawful purposes":
		"Nous utilisons les données des patients pour fournir la plate-forme à nos clients et conformément à leurs instructions. Nous pouvons également utiliser les données des patients pour générer des données anonymisées, qui ne sont pas en mesure d’identifier un patient, que ce soit directement ou indirectement, et pouvons utiliser des données anonymisées pour améliorer nos produits et services, à des fins d’études de marché et à d’autres fins légales",
	"The Client Data we collect includes the following information about the employees and independent contractors of our Clients":
		"Les données client que nous recueillons comprennent les informations suivantes sur les employés et les sous-traitants indépendants de nos clients",
	"Name and contact information": "Nom et coordonnées",
	"Username and password": "Nom d’utilisateur et mot de passe",
	"Information about the use of the Platform":
		"Informations sur l’utilisation de la Plateforme",
	"Opinions, feedback, and other information that an employee or independent contractor may choose to provide":
		"Opinions, commentaires et autres renseignements qu’un employé ou un entrepreneur indépendant peut choisir de fournir",
	"We also collect certain Client Data automatically (see “Information We Collect Automatically” section below)":
		"Nous collectons également certaines données client automatiquement (voir la section « Informations que nous recueillons automatiquement » ci-dessous)",
	"We use Client Data to": "Nous utilisons les données des clients pour",
	"Provide the Platform, including to create and manage Client accounts, provide customer service and support, and process payments":
		"Fournir la plate-forme, y compris pour créer et gérer des comptes clients, fournir un service client et un support, et traiter les paiements",
	"Communicate with Clients and their employees and representatives respect of the Platform or other products, services and events that may be of interest":
		"Communiquer avec les Clients et leurs employés et représentants au respect de la Plateforme ou d’autres produits, services et événements pouvant vous intéresser",
	"Better understand how our Clients use the Platform in order to improve the Platform and develop new products or services":
		"Mieux comprendre comment nos Clients utilisent la Plateforme afin d’améliorer la Plateforme et de développer de nouveaux produits ou services",
	"Manage our business including to maintain business records for reasonable periods, enforce our agreements, and collect amounts owing to us":
		"Gérer nos activités, y compris pour tenir des registres commerciaux pendant des périodes raisonnables, faire respecter nos accords et percevoir les montants qui nous sont dus",
	"Meet legal and regulatory obligations":
		"Respecter les obligations légales et réglementaires",
	Continue: "Continuer",
	"Please read over your form before submitting":
		"Veuillez lire votre formulaire avant de le soumettre",
	"We may use Client Data for other purposes with consent or as otherwise permitted or required by law":
		"Nous pouvons utiliser les données du client à d’autres fins avec consentement ou autrement permis ou requis par la loi",
	"Information We Collect Automatically":
		"Informations que nous recueillons automatiquement",
	"We use data collection tools to automatically collect information when individuals visit our Website or use our Platform":
		"Nous utilisons des outils de collecte de données pour collecter automatiquement des informations lorsque des personnes visitent notre site Web ou utilisent notre plate-forme",
	"We use technologies like cookies and pixel tags to provide, monitor, analyze, and improve the Website and the Platform. For example, a cookie is used to remember your username when you return to the Website or the Platform and to improve our understanding of how you interact with the Website or Platform. At this time, we do not offer the ability to block cookies as key features of the Website and the Platform will not function properly if the ability to accept cookies is disabled":
		"Nous utilisons des technologies telles que les cookies et les pixels invisibles pour fournir, surveiller, analyser et améliorer le site Web et la plate-forme. Par exemple, un cookie est utilisé pour mémoriser votre nom d’utilisateur lorsque vous revenez sur le site Web ou la plate-forme et pour améliorer notre compréhension de la façon dont vous interagissez avec le site Web ou la plate-forme. Pour le moment, nous n’offrons pas la possibilité de bloquer les cookies en tant que fonctionnalités clés du site Web et la plate-forme ne fonctionnera pas correctement si la possibilité d’accepter les cookies est désactivée",
	"Log Files": "Fichiers journaux",
	"When you use the Website or the Platform, our servers automatically record certain information in server logs. These server logs may include information such as your web request, Internet Protocol":
		"Lorsque vous utilisez le site Web ou la plate-forme, nos serveurs enregistrent automatiquement certaines informations dans les journaux du serveur. Ces journaux de serveur peuvent inclure des informations telles que votre demande Web, le protocole Internet",
	"address, browser type, referring / exit pages and URLs, number of clicks and how you interact with content and links on the Website or the Platform, domain names, landing pages, pages viewed, mobile carrier, and other such information. Log files help us to monitor, analyze, improve and maintain the Website and the Platform and to diagnose and fix any service-related issues":
		"l’adresse, le type de navigateur, les pages de référence / sortie et les URL, le nombre de clics et la façon dont vous interagissez avec le contenu et les liens sur le site Web ou la plate-forme, les noms de domaine, les pages de destination, les pages consultées, l’opérateur mobile et d’autres informations de ce type. Les fichiers journaux nous aident à surveiller, analyser, améliorer et maintenir le site Web et la plate-forme et à diagnostiquer et résoudre tout problème lié au service",
	"Device Identifiers": "Identificateurs de périphérique",
	"When you access the Website or the Platform using a mobile device, we collect specific device information contained in your mobile device’s “device identifier.” This device identifier includes information such as the type of device you are using, its operating system, and mobile network information, which may include your mobile phone number. We may associate this device identifier with your account and will use data associated with your device identifier to customize our Website or Platform to your device and to analyze any device related issues":
		"Lorsque vous accédez au site Web ou à la plate-forme à l’aide d’un appareil mobile, nous recueillons des informations spécifiques sur l’appareil contenues dans « l’identifiant de l’appareil » de votre appareil mobile. Cet identifiant d’appareil comprend des informations telles que le type d’appareil que vous utilisez, son système d’exploitation et des informations sur le réseau mobile, qui peuvent inclure votre numéro de téléphone mobile. Nous pouvons associer cet identifiant d’appareil à votre compte et utiliserons les données associées à l’identifiant de votre appareil pour personnaliser notre site Web ou notre plate-forme sur votre appareil et pour analyser tout problème lié à l’appareil",
	"Location Information": "Renseignements sur l’emplacement",
	"We may collect and process information about the location of the device from which you are accessing the Website or the Platform to provide certain location-based services. You can disable location-based services in settings associated with the Website or the Platform; however please be aware that some features of the Website or the Platform may not function properly if location services are turned off":
		"Nous pouvons collecter et traiter des informations sur l’emplacement de l’appareil à partir duquel vous accédez au site Web ou à la plate-forme pour fournir certains services basés sur la localisation. Vous pouvez désactiver les services basés sur la localisation dans les paramètres associés au site Web ou à la plate-forme ; cependant, veuillez noter que certaines fonctionnalités du site Web ou de la plate-forme peuvent ne pas fonctionner correctement si les services de localisation sont désactivés",
	Analytics: "Analyse",
	"We may use third party service providers like Google Analytics to collect information about your use of the Website or Platform, such as the features used and time spent on the Website or the Platform, to help us better understand our users and improve our Website and Platform. The information we gather is used in an aggregate, non-identifiable form. For information about information is processed in connection with Google Analytics please read the Google Analytics Policy here":
		"Nous pouvons utiliser des fournisseurs de services tiers comme Google Analytics pour collecter des informations sur votre utilisation du site Web ou de la plate-forme, telles que les fonctionnalités utilisées et le temps passé sur le site Web ou la plate-forme, afin de nous aider à mieux comprendre nos utilisateurs et à améliorer notre site Web et notre plate-forme. Les informations que nous recueillons sont utilisées sous une forme agrégée et non identifiable. Pour plus d’informations sur les informations traitées dans le cadre de Google Analytics, veuillez lire la politique de Google Analytics ici",
	Clients: "Les clients",
	"Our Clients have access to Patient Data relating to their Patients. We are not responsible for our Client’s handling of Patient Data. A Client’s privacy policy may allow a Patient to consent to providing CLINIC_NAME with Patient Data. If you are a Patient of one of our Clients and have questions about how your Patient Data is handled, please contact the applicable Client directly":
		"Nos clients ont accès aux données des patients relatives à leurs patients. Nous ne sommes pas responsables du traitement des données des patients par notre client. La politique de confidentialité d’un client peut permettre à un patient de consentir à fournir à CLINIC_NAME des données du patient. Si vous êtes un patient de l’un de nos clients et que vous avez des questions sur la façon dont vos données de patient sont traitées, veuillez contacter directement le client concerné",
	"Service providers": "Fournisseurs de services",
	"We may transfer (or otherwise make available) personal information to service providers (including affiliated companies acting in this capacity) who perform services on our behalf. For example, we may use service providers to store and process Patient Data and Client Data, provide back-up systems, process payments, or provide data analytics and marketing services. Personal information may be maintained and processed by our service providers within and outside of Canada":
		"Nous pouvons transférer (ou autrement rendre disponibles) des informations personnelles à des fournisseurs de services (y compris des sociétés affiliées agissant en cette qualité) qui fournissent des services en notre nom. Par exemple, nous pouvons faire appel à des fournisseurs de services pour stocker et traiter les données des patients et des clients, fournir des systèmes de sauvegarde, traiter les paiements ou fournir des services d’analyse de données et de marketing. Les renseignements personnels peuvent être conservés et traités par nos fournisseurs de services à l’intérieur et à l’extérieur du Canada",
	"If CLINIC_NAME is sold, restructured, or involved in a similar business transaction, we may transfer your personal information to the parties involved in that transaction. We will ensure that your personal information is transferred in accordance with legal and regulatory obligations":
		"Si CLINIC_NAME est vendu, restructuré ou impliqué dans une transaction commerciale similaire, nous pouvons transférer vos informations personnelles aux parties impliquées dans cette transaction. Nous veillerons à ce que vos informations personnelles soient transférées conformément aux obligations légales et réglementaires",
	"Disclosures authorized or required by law":
		"Divulgations autorisées ou exigées par la loi",
	"We may disclose your personal information where we are legally required or authorized to do so":
		"Nous pouvons divulguer vos informations personnelles lorsque nous sommes légalement tenus ou autorisés à le faire",
	"We may disclose your personal information in other circumstances with your consent":
		"Nous pouvons divulguer vos renseignements personnels dans d’autres circonstances avec votre consentement",
	"Disclosures with consent": "Divulgations avec consentement",
	"Disclosure of Anonymous Data": "Divulgation de données anonymes",
	"Not Started": "Non commencé",
	"As noted above, we may use Patient Data to generate anonymized data, which is not capable of identifying a Patient, whether directly or indirectly. We may disclose and sell anonymous data to third parties for research and commercial purposes":
		"Comme indiqué ci-dessus, nous pouvons utiliser les données des patients pour générer des données anonymisées, ce qui n’est pas en mesure d’identifier un patient, que ce soit directement ou indirectement. Nous pouvons divulguer et vendre des données anonymes à des tiers à des fins de recherche et commerciales",
	"Our Website or Platform may contain links to other websites or online or digital services that we do not own or operate. We provide these links as a convenience to the user. We do not have any control over these websites or services and they are not subject to this Privacy Policy. We encourage you to read the privacy policy of every website or online or digital service you visit":
		"Notre site Web ou notre plate-forme peut contenir des liens vers d’autres sites Web ou services en ligne ou numériques que nous ne possédons pas ou n’exploitons pas. Nous fournissons ces liens pour la commodité de l’utilisateur. Nous n’avons aucun contrôle sur ces sites Web ou services et ils ne sont pas soumis à cette politique de confidentialité. Nous vous encourageons à lire la politique de confidentialité de chaque site Web ou service en ligne ou numérique que vous visitez",
	"We have implemented reasonable physical, technological, and administrative security measures designed to protect the personal information in our custody or control against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. We limit access to personal information to our employees and service providers who require access in connection with their role or function. Each of our employees is responsible for maintaining the confidentiality of all personal information to which they have access. We keep our employees informed about our policies and procedures for protecting personal information. Despite these measures, since no system or security measure is completely secure, it is possible that personal information may be lost, stolen, or accessed without authorization":
		"Nous avons mis en œuvre des mesures de sécurité physiques, technologiques et administratives raisonnables conçues pour protéger les renseignements personnels dont nous avons la garde ou le contrôle contre la perte ou le vol, ainsi que contre l’accès, la divulgation, la copie, l’utilisation ou la modification non autorisés. Nous limitons l’accès aux renseignements personnels à nos employés et à nos fournisseurs de services qui en ont besoin dans le cadre de leur rôle ou de leur fonction. Chacun de nos employés est responsable du maintien de la confidentialité de tous les renseignements personnels auxquels ils ont accès. Nous tenons nos employés informés de nos politiques et procédures de protection des renseignements personnels. Malgré ces mesures, étant donné qu’aucun système ou mesure de sécurité n’est entièrement sécurisé, il est possible que des renseignements personnels soient perdus, volés ou consultés sans autorisation",
	"We retain personal information for as long as necessary for the fulfilment of the purposes for which it was collected, in accordance with prudent business practices on general document retention, or as otherwise required by law":
		"Nous conservons les renseignements personnels aussi longtemps que nécessaire à la réalisation des fins pour lesquelles ils ont été recueillis, conformément aux pratiques commerciales prudentes en matière de conservation générale des documents, ou tel que requis par la loi",
	"You may unsubscribe from promotional email communications that we send at any time by using the unsubscribe feature included in those messages. Please note that if you unsubscribe from promotional email communications you may still receive transactional or business-related communications from us":
		"Vous pouvez vous désabonner des communications promotionnelles par e-mail que nous envoyons à tout moment en utilisant la fonction de désabonnement incluse dans ces messages. Veuillez noter que si vous vous désabonnez des communications promotionnelles par e-mail, vous pouvez toujours recevoir des communications transactionnelles ou professionnelles de notre part",
	"Employees and independent contractors of our Clients may have the right to request access to or correction of their personal information in our custody or control, by writing to us as described below. These rights are subject to applicable legal restrictions and we may take appropriate steps to verify identity before responding to the request. To exercise any of these rights, please contact us in writing using the contact information in the “Contact” section below.":
		"Les employés et les entrepreneurs indépendants de nos Clients peuvent avoir le droit de demander l’accès ou la correction de leurs renseignements personnels sous notre garde ou notre contrôle, en nous écrivant comme décrit ci-dessous. Ces droits sont soumis aux restrictions légales applicables et nous pouvons prendre les mesures appropriées pour vérifier l’identité avant de répondre à la demande. Pour exercer l’un de ces droits, veuillez nous contacter par écrit en utilisant les coordonnées de la section « Contact » ci-dessous.",
	"If you are a Patient of one of our Clients and would like to exercise your rights under applicable privacy laws, please contact the applicable Client directly":
		"Si vous êtes un patient de l’un de nos clients et que vous souhaitez exercer vos droits en vertu des lois applicables en matière de confidentialité, veuillez contacter directement le client concerné",
	"Changes to this Privacy Policy":
		"Modifications apportées à cette politique de confidentialité",
	"If we change our Privacy Policy, we will post those changes on this page to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it. Changes to this Privacy Policy are effective when they are posted on this page":
		"Si nous modifions notre politique de confidentialité, nous publierons ces modifications sur cette page pour vous tenir au courant des informations que nous recueillons, de la façon dont nous les utilisons et des circonstances dans lesquelles nous pouvons les divulguer. Les modifications apportées à cette politique de confidentialité entrent en vigueur lorsqu’elles sont publiées sur cette page",
	"If you have questions about this Privacy Policy or how your personal information is handled by CLINIC_NAME, please contact the CLINIC_NAME Privacy Officer at":
		"Si vous avez des questions au sujet de la présente politique de confidentialité ou de la façon dont vos renseignements personnels sont traités par CLINIC_NAME, veuillez communiquer avec le responsable de la protection de la vie privée de l’CLINIC_NAME à l’adresse suivante :",
	"privacy@thefertilitypartners.com": "privacy@thefertilitypartners.com",
	"Token is Invalid": "Le jeton n’est pas valide",
	"Communication Paused": "La communication est interrompue",
	"You will not receive emails from us until next month":
		"Vous ne recevrez pas d’e-mails de notre part avant le mois prochain",
	"Go to website": "Aller sur le site Web",
	"If you have any questions, comments, or notices regarding these Terms, please contact us at":
		"Si vous avez des questions, des commentaires ou des avis concernant ces Conditions, veuillez nous contacter à l’adresse",
	"HOW TO CONTACT US": "COMMENT NOUS CONTACTER",
	"The following Sections survive the termination of these Terms of Use: Intellectual Property; Arbitration; Disclaimer and Limitation of Liability; Indemnification; Miscellaneous":
		"Les sections suivantes survivent à la résiliation des présentes conditions d’utilisation : Propriété intellectuelle ; Arbitrage ; Clause de non-responsabilité et limitation de responsabilité ; Indemnisation ; Divers",
	"You acknowledge and agree that you have been provided with a copy of these Terms and ancillary documents in French prior to, or simultaneously, with these Terms and have elected to execute and be bound by these Terms and ancillary documents in English. Vous reconnaissez et convenez que vous avez reçu une copie de ces modalités d'utilisation et des documents y afférant en français avant ou en même temps que ces modalités d'utilisation et avez choisi d'accepter et d'être lié par ces modalités d'utilisation et les documents y afférant en anglais":
		"Vous reconnaissez et acceptez que vous avez reçu une copie des présentes Conditions et des documents auxiliaires en français avant, ou simultanément, avec ces Conditions et que vous avez choisi d’exécuter et d’être lié par ces Conditions et documents auxiliaires en anglais. ",
	"These Terms are between you and Generation Fertility only and no other parties, including any third party service providers. You agree that there are no third-party beneficiaries under these Terms":
		"Les présentes Conditions sont entre vous et Generation Fertility uniquement et aucune autre partie, y compris les fournisseurs de services tiers. Vous acceptez qu’il n’y ait pas de tiers bénéficiaires en vertu des présentes Conditions",
	"No waiver by us of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition. Our failure to insist upon or enforce strict performance of any provision of these Terms shall not be construed as a waiver of any right":
		"Aucune renonciation de notre part à l’une des conditions énoncées dans les présentes Conditions ne sera considérée comme une renonciation supplémentaire ou continue à cette condition ou une renonciation à toute autre condition. Notre incapacité à insister sur ou à appliquer l’exécution stricte de toute disposition des présentes Conditions ne doit pas être interprétée comme une renonciation à un droit",
	"If any provision of these Terms is held to be unenforceable, the remaining Terms shall remain in full force and effect, and the unenforceable provision shall be replaced by an enforceable provision that comes closest to the intention underlying the unenforceable provision":
		"Si une disposition des présentes Conditions est jugée inapplicable, les Conditions restantes resteront pleinement en vigueur et de plein effet, et la disposition inapplicable sera remplacée par une disposition exécutoire qui se rapproche le plus de l’intention sous-jacente à la disposition inapplicable",
	"We reserve the right to seek all remedies available at law and in equity for violations of these Terms, including the right to remove your account and any contents generated by you through the Services, block your access to any or all aspects of the Services, and block IP addresses":
		"Nous nous réservons le droit de rechercher tous les recours disponibles en droit et en équité pour les violations des présentes Conditions, y compris le droit de supprimer votre compte et tout contenu généré par vous via les Services, de bloquer votre accès à tout ou partie des Aspects des Services et de bloquer les adresses IP",
	"You represent and warrant that you shall comply with all laws and regulations that apply to your access and use of the Services, including, but not limited to, any applicable national laws that prohibit the export or transmission of technical data or software to certain territories or jurisdictions":
		"Vous déclarez et garantissez que vous devez vous conformer à toutes les lois et réglementations qui s’appliquent à votre accès et à votre utilisation des Services, y compris, mais sans s’y limiter, toute législation nationale applicable qui interdit l’exportation ou la transmission de données techniques ou de logiciels à certains territoires ou juridictions",
	"To the fullest extent permitted by applicable law, these Terms shall be governed by the laws of the Province of Ontario, Canada":
		"Dans toute la mesure permise par la loi applicable, les présentes Conditions sont régies par les lois de la province de l’Ontario, Au Canada",
	MISCELLANEOUS: "DIVERS",
	"To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold harmless us, our licensor, and our affiliates, and our respective officers, directors, employees, agents, and representatives from and against all losses, expenses, damages, and costs, including reasonable legal fees and court costs, resulting from any violation by you of these Terms or from our termination of your access to or use of the Services. Such termination will not affect either party's rights or obligations, which accrued before the termination":
		"Dans toute la mesure permise par la loi applicable, vous acceptez d’indemniser, de défendre et de dégager de toute responsabilité nous, notre concédant de licence et nos sociétés affiliées, ainsi que nos dirigeants, administrateurs, employés, agents et représentants respectifs de et contre toutes les pertes, dépenses, dommages et coûts, y compris les frais juridiques raisonnables et les frais de justice, résultant de toute violation par vous des présentes Conditions ou de notre résiliation de votre accès ou de votre utilisation des Services. Une telle résiliation n’affectera pas les droits ou obligations de l’une ou l’autre des parties, qui se sont accumulés avant la résiliation",
	INDEMNIFICATION: "INDEMNISATION",
	"TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE REMEDY FOR DISSATISFACTION WITH (1) THE SERVICES, (2) THE PLATFORM WEBSITE, (3) INFORMATION CONTAINED WITHIN THE SERVICES OR THE PLATFORM WEBSITE, (4) ANY LINKED SITE, OR (5) ANY PRODUCT, IS TO STOP USING THE SERVICES, THE PLATFORM WEBSITE, AND/OR THOSE PRODUCTS. TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, GENERATION FERTILITY, ITS LICENSORS, AND THE APPLICABLE THIRD PARTY'S MAXIMUM LIABILITY TO YOU WITH RESPECT TO YOUR USE OF THE SERVICES, THE PLATFORM WEBSITE, AND ANY PRODUCTS PURCHASED BY YOU THROUGH THE SERVICES IS THE EXTENT OF ACTUAL DAMAGES INCURRED BY YOU, NOT TO EXCEED $1000. THE FOREGOING LIMITATIONS APPLY EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE":
		"DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, VOTRE SEUL RECOURS EN CAS D’INSATISFACTION AVEC (1) LES SERVICES, (2) LE SITE WEB DE LA PLATE-FORME, (3) LES INFORMATIONS CONTENUES DANS LES SERVICES OU LE SITE WEB DE LA PLATE-FORME, (4) TOUT SITE LIÉ, OU (5) TOUT PRODUIT, EST DE CESSER D’UTILISER LES SERVICES, LE SITE WEB DE LA PLATE-FORME ET / OU CES PRODUITS. DANS LA MESURE OÙ TOUS LES ASPECTS DES LIMITATIONS DE RESPONSABILITÉ CI-DESSUS NE SONT PAS EXÉCUTOIRES, GENERATION FERTILITY, SES CONCÉDANTS DE LICENCE ET LA RESPONSABILITÉ MAXIMALE DU TIERS APPLICABLE ENVERS VOUS EN CE QUI CONCERNE VOTRE UTILISATION DES SERVICES, DU SITE WEB DE LA PLATE-FORME ET DE TOUT PRODUIT ACHETÉ PAR VOUS PAR LE BIAIS DES SERVICES EST L’ÉTENDUE DES DOMMAGES RÉELS ENCOURUS PAR VOUS,  NE PAS EXCÉDER 1000 $. LES LIMITATIONS QUI PRÉCÈDENT S’APPLIQUENT MÊME SI LES RECOURS EN VERTU DES PRÉSENTES CONDITIONS ÉCHOUENT À LEUR OBJECTIF ESSENTIEL",
	"YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT WILL GENERATION FERTILITY, ITS LICENSORS OR APPLICABLE THIRD PARTIES OR THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL LOSSES OR DAMAGES OF WHATSOEVER KIND ARISING OUT OF ACCESS TO OR USE OF THE WEBSITE, SERVICES, PRODUCTS, OR ANY INFORMATION, CONTENT OR MATERIALS INCLUDED ON WEBSITE":
		"VOUS COMPRENEZ ET ACCEPTEZ EXPRESSÉMENT QU’EN AUCUN CAS GENERATION FERTILITY, SES CONCÉDANTS DE LICENCE OU TIERS APPLICABLES OU LEURS DIRIGEANTS, ADMINISTRATEURS, EMPLOYÉS, AGENTS OU AUTRES REPRÉSENTANTS NE SERONT TENUS RESPONSABLES DE TOUTE PERTE OU DOMMAGE DIRECT, INDIRECT, ACCESSOIRE, SPÉCIAL, PUNITIF, EXEMPLAIRE OU CONSÉCUTIF DE QUELQUE NATURE QUE CE SOIT DÉCOULANT DE L’ACCÈS OU DE L’UTILISATION DU SITE WEB, DES SERVICES,  PRODUITS, OU TOUTE INFORMATION, CONTENU OU MATÉRIEL INCLUS SUR LE SITE WEB",
	"TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DO NOT WARRANT THAT ACCESS TO OR USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SERVICES WILL BE CORRECTED. THE SERVICES ARE PROVIDED BY GENERATION FERTILITY AND APPLICABLE THIRD PARTIES ON AN AS IS AND AS AVAILABLE BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EACH OF GENERATION FERTILITY, ITS LICENSORS, AND APPLICABLE THIRD PARTIES MAKES NO REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, EXPRESS, LEGAL OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, COVENANTS, DURABILITY, NON-INFRINGEMENT, SATISFACTORY QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO THE SERVICE OR ANY INFORMATION, CONTENT, PRODUCT, OR OTHER MATERIAL PROVIDED ON OR THROUGH THE SERVICES, EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW":
		"DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, NOUS NE GARANTISSONS PAS QUE L’ACCÈS OU L’UTILISATION DES SERVICES SERA ININTERROMPU OU SANS ERREUR OU QUE LES DÉFAUTS DES SERVICES SERONT CORRIGÉS. LES SERVICES SONT FOURNIS PAR GENERATION FERTILITY ET LES TIERS CONCERNÉS SUR UNE BASE TELLE QUELLE ET SELON QUE DISPONIBLE. DANS LA MESURE MAXIMALE PERMISE PAR LA LOI APPLICABLE, CHACUN DE GENERATION FERTILITY, SES CONCÉDANTS DE LICENCE ET LES TIERS APPLICABLES NE FONT AUCUNE DÉCLARATION, GARANTIE OU CONDITION DE QUELQUE NATURE QUE CE SOIT, EXPRESSE, LÉGALE OU IMPLICITE, STATUTAIRE OU AUTRE, Y COMPRIS, SANS LIMITATION, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D’ENGAGEMENTS, DE DURABILITÉ, DE NON-CONTREFAÇON, DE QUALITÉ SATISFAISANTE OU D’ADÉQUATION À UN USAGE PARTICULIER EN CE QUI CONCERNE LE SERVICE OU TOUTE INFORMATION,  CONTENU, PRODUIT OU AUTRE MATÉRIEL FOURNI SUR OU VIA LES SERVICES, SAUF DANS LA MESURE INTERDITE PAR LA LOI APPLICABLE",
	"SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. TO THE EXTENT THAT WE MAY NOT DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY OR LIMIT ITS LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY AND THE EXTENT OF OUR LIABILITY WILL BE THE MINIMUM PERMITTED UNDER APPLICABLE LAW":
		"CERTAINES JURIDICTIONS N’AUTORISENT PAS L’EXCLUSION DE CERTAINES GARANTIES OU LA LIMITATION OU L’EXCLUSION DE RESPONSABILITÉ POUR LES DOMMAGES ACCESSOIRES OU CONSÉCUTIFS. DANS LA MESURE OÙ NOUS NE POUVONS DÉCLINER AUCUNE GARANTIE IMPLICITE OU LÉGALE OU LIMITER SES RESPONSABILITÉS, LA PORTÉE ET LA DURÉE D’UNE TELLE GARANTIE ET L’ÉTENDUE DE NOTRE RESPONSABILITÉ SERONT LE MINIMUM AUTORISÉ PAR LA LOI APPLICABLE",
	"DISCLAIMER AND LIMITATION OF LIABILITY":
		"EXCLUSION DE RESPONSABILITÉ ET LIMITATION DE RESPONSABILITÉ",
	"Certain parts of the Services, including the Platform Website, may contain links to third-party websites or may give you access to applications, software, or products provided by third parties. Please understand that those third-party websites and products may have different terms of use and privacy policies, and that Generation Fertility does not control and is not responsible for the content of such websites or the privacy practices or products of such third parties. The information collected by such third-party websites is not covered by the Privacy Policy":
		"Certaines parties des Services, y compris le Site Web de la Plateforme, peuvent contenir des liens vers des sites Web tiers ou peuvent vous donner accès à des applications, des logiciels ou des produits fournis par des tiers. Veuillez comprendre que ces sites Web et produits tiers peuvent avoir des conditions d’utilisation et des politiques de confidentialité différentes, et que Generation Fertility ne contrôle pas et n’est pas responsable du contenu de ces sites Web ou des pratiques de confidentialité ou des produits de ces tiers. Les informations collectées par ces sites Web tiers ne sont pas couvertes par la politique de confidentialité",
	"LINKS TO THIRD PARTY SITES": "LIENS VERS DES SITES TIERS",
	"To the fullest extent permissible by law, you and Generation Fertility agree that any dispute or claim arising from or relating to these Terms, the Services and the Platform Website shall be finally settled by final and binding arbitration, using the English language, administered by the Canadian Arbitration Association (the “CAA”) under its Arbitration Rules (the “CAA Rules”) then in effect (those rules are deemed to be incorporated by reference into this section, and as of the date of these Terms you can find the CAA here), unless otherwise required by law. Arbitration, including threshold questions of arbitrability of the dispute, will be handled by a sole arbitrator in accordance with those rules. Judgment on the arbitration award may be entered in any court that has jurisdiction. To the fullest extent permissible by law, any arbitration under these Terms will take place on an individual basis — class arbitrations and class actions are not permitted. You understand that by agreeing to these Terms, you and Generation Fertility are each waiving the right to trial by jury or to participate in a class action or class arbitration. Notwithstanding the foregoing, each party shall have the right to bring an action in a court of proper jurisdiction for injunctive or other equitable or conservatory relief, pending a final decision by the arbitrator":
		"Dans toute la mesure permise par la loi, vous et Generation Fertility convenez que tout litige ou réclamation découlant de ou lié aux présentes Conditions, aux Services et au Site Web de la Plateforme sera définitivement réglé par arbitrage final et exécutoire, en utilisant la langue anglaise, administré par l’Association canadienne d’arbitrage (la « CAA ») en vertu de son Règlement d’arbitrage (les « Règles de la CAA ») alors en vigueur (ces règles sont réputées être incorporées par renvoi dans cette section,  et à la date des présentes Conditions, vous pouvez trouver la CAA ici), sauf si la loi l’exige. L’arbitrage, y compris les questions de seuil d’arbitrabilité du différend, sera traité par un arbitre unique conformément à ces règles. Le jugement sur la sentence arbitrale peut être inscrit devant tout tribunal compétent. Dans toute la mesure permise par la loi, tout arbitrage en vertu des présentes Conditions aura lieu sur une base individuelle - les arbitrages collectifs et les recours collectifs ne sont pas autorisés. Vous comprenez qu’en acceptant ces Conditions, vous et Generation Fertility renoncez chacun au droit à un procès devant jury ou à un recours collectif ou à un arbitrage collectif. Nonobstant ce qui précède, chaque partie a le droit d’intenter une action devant un tribunal compétent pour obtenir une injonction ou une autre réparation équitable ou conservatoire, en attendant une décision finale de l’arbitre",
	ARBITRATION: "ARBITRAGE",
	"Electronic communication via text (SMS) messaging or email is critical to the functionality of the Platform and the performance of the Services. If you opt-out of both email and text (SMS) communications, you will not be able to continue to use the Platform and the Services will terminate":
		"La communication électronique par messagerie texte (SMS) ou par e-mail est essentielle à la fonctionnalité de la Plateforme et à la performance des Services. Si vous vous désabinsez des communications par e-mail et par SMS (SMS), vous ne pourrez pas continuer à utiliser la Plate-forme et les Services seront résiliés",
	"You may choose to opt-out of receiving email messages by updating your preferences in the Services or following the opt-out instructions in the message. You may opt-out of receiving email communication directly relating to provision of the Services or other types of messages, including marketing announcements. If you opt-out, Generation Fertility will not send another message to you unless/until you expressly opt in to receiving additional messages. You acknowledge that opting out of receiving email messages may impact your experience with the Service(s) that rely on communications via e-mail":
		"Vous pouvez choisir de refuser de recevoir des messages électroniques en mettant à jour vos préférences dans les Services ou en suivant les instructions de désinscription du message. Vous pouvez refuser de recevoir des communications par e-mail directement liées à la fourniture des Services ou d’autres types de messages, y compris les annonces marketing. Si vous vous désinscrisez, Generation Fertility ne vous enverra pas d’autre message à moins que / jusqu’à ce que vous choisissiez expressément de recevoir des messages supplémentaires. Vous reconnaissez que le refus de recevoir des messages électroniques peut avoir un impact sur votre expérience avec le(s) Service(s) qui reposent sur des communications par e-mail",
	"Message and data rates may apply. Your wireless carrier’s standard messaging rates apply to SMS correspondence. Downloadable content may incur additional charges from your wireless carrier. Please contact your wireless carrier for information about charges in your plan. Carriers are not responsible for delayed or undelivered messages":
		"Des frais de messagerie et de données peuvent s’appliquer. Les tarifs de messagerie standard de votre entreprise de services sans fil s’appliquent à la correspondance par SMS. Le contenu téléchargeable peut entraîner des frais supplémentaires de la part de votre fournisseur de services sans fil. Veuillez communiquer avec votre fournisseur de services sans fil pour obtenir des renseignements sur les frais prévus dans votre forfait. Les transporteurs ne sont pas responsables des messages retardés ou non livrés",
	"You may choose to opt-out of receiving text messages by updating your preferences in the Services or following the opt-out instructions in the message. You may opt-out of receiving text (SMS) messages directly relating to provision of the Services or other types of messages, including marketing announcements. If you opt-out, Generation Fertility will not send another message to you unless/until you expressly opt in to receiving additional messages. You acknowledge that opting out of receiving text (SMS) messages may impact your experience with the Service(s) that rely on communications via text (SMS) messaging":
		"Vous pouvez choisir de refuser de recevoir des messages texte en mettant à jour vos préférences dans les Services ou en suivant les instructions de désinscription dans le message. Vous pouvez refuser de recevoir des messages texte (SMS) directement liés à la fourniture des Services ou d’autres types de messages, y compris les annonces marketing. Si vous vous désinscrisez, Generation Fertility ne vous enverra pas d’autre message à moins que / jusqu’à ce que vous choisissiez expressément de recevoir des messages supplémentaires. Vous reconnaissez que le refus de recevoir des messages texte (SMS) peut avoir une incidence sur votre expérience avec le(s) Service(s) qui reposent sur des communications via la messagerie texte (SMS)",
	"By providing your cell phone number and email address to Generation Fertility, you are agreeing to be contacted by or on behalf of Generation Fertility at the email address and the telephone number provided, including emails to your email address and text (SMS) messages to your cell phone and other wireless devices, and the use of an automatic telephone dialing system, artificial voice and pre-recorded messages, for the purpose of providing the Services and sending you marketing communications. You may also be contacted with marketing announcements about the products and services offered by or made available through our website":
		"En fournissant votre numéro de téléphone cellulaire et votre adresse e-mail à Generation Fertility, vous acceptez d’être contacté par Ou au nom de Generation Fertility à l’adresse e-mail et au numéro de téléphone fournis, y compris les e-mails à votre adresse e-mail et les messages texte (SMS) à votre téléphone cellulaire et d’autres appareils sans fil, et l’utilisation d’un système de composition téléphonique automatique,  des messages vocaux artificiels et préenregistrés, dans le but de fournir les Services et de vous envoyer des communications marketing. Vous pouvez également être contacté avec des annonces marketing sur les produits et services offerts par ou mis à disposition via notre site Web",
	"EMAIL AND SMS MESSAGING": "MESSAGERIE ÉLECTRONIQUE ET SMS",
	"By using the Services, you hereby assign to Generation Fertility all right, title, and interest in and to any third-party benefits due from any and all insurance policies and/or responsible third-party payers for Services rendered to you. You agree to have any such amounts paid directly to Generation Fertility":
		"En utilisant les Services, vous cédez par la présente à Generation Fertility tous les droits, titres et intérêts dans et à tous les avantages de tiers dus à toutes les polices d’assurance et / ou aux tiers payeurs responsables pour les Services qui vous sont rendus. Vous acceptez que ces montants sont payés directement à Generation Fertility",
	"Assignment of Insurance/Third Party Payer Benefits":
		"Cession d’assurances/avantages d’un tiers payeur",
	"All prices posted on or through the Services and/or the Platform Website are subject to change without notice. Posted prices do not include taxes unless otherwise specified. All such taxes and charges will be added to your total. We strive to display accurate price information, however we may, on occasion, make inadvertent typographical errors, inaccuracies or omissions related to pricing and availability. To the fullest extent permitted by applicable law, we reserve the right to correct any errors, inaccuracies, or omissions at any time and to cancel any Services arising from such occurrences":
		"Tous les prix affichés sur ou via les Services et/ou le Site Web de la Plateforme sont sujets à changement sans préavis. Les prix affichés n’incluent pas les taxes, sauf indication contraire. Toutes ces taxes et charges seront ajoutées à votre total. Nous nous efforçons d’afficher des informations précises sur les prix, mais nous pouvons, à l’occasion, faire par inadvertance des erreurs typographiques, des inexactitudes ou des omissions liées aux prix et à la disponibilité. Dans toute la mesure permise par la loi applicable, nous nous réservons le droit de corriger toute erreur, inexactitude ou omission à tout moment et d’annuler tout Service découlant de tels événements",
	"TERMS OF SALE": "CONDITIONS DE VENTE",
	"For greater certainty, certain Services provided to you may be elective, not be medically necessary and/or not insured benefits or services under any federal or provincial healthcare programs in the jurisdiction in which they are offered. This means that these particular Services might be optional or chosen by the patient, rather than being essential for medical reasons. Additionally, they may not be covered under standard government health insurance programs. As such, if you opt for these Services, you may be fully responsible for their associated costs, as they might not be covered or reimbursed by public healthcare or other insurance plans":
		"Il est entendu que certains services qui vous sont fournis peuvent être facultatifs, ne pas être médicalement nécessaires et /ou ne pas être assurés ou des services dans le cadre de tout programme de soins de santé fédéral ou provincial dans la juridiction dans laquelle ils sont offerts. Cela signifie que ces Services particuliers peuvent être facultatifs ou choisis par le patient, plutôt que d’être essentiels pour des raisons médicales. De plus, ils peuvent ne pas être couverts par les programmes d’assurance maladie gouvernementaux standard. En tant que tel, si vous optez pour ces services, vous pouvez être entièrement responsable de leurs coûts associés, car ils pourraient ne pas être couverts ou remboursés par les régimes publics de soins de santé ou d’autres régimes d’assurance",
	"Some of the Services provided on the Platform Website are not covered by public health insurance. For these services, you agree to be financially responsible and obligated to pay any balance owing that is not otherwise paid by public health insurance, any third-party benefits provider, any and all insurance policies and/or responsible third-party payers. You acknowledge and agree that such payment will be made to Generation Fertility, its affiliates, and/or service providers, as per the information received prior to being provided with any Service. You further acknowledge and agree that in the event that you fail to make such payments in accordance with the payment terms in respect thereof, Generation Fertility may terminate your relationship with it, in accordance with applicable laws. Furthermore, in the event of a default of your financial obligation, should your account be turned over to an external collection agency for non-payment, you agree to pay any associated collection costs":
		"Certains des Services fournis sur le Site Web de la Plateforme ne sont pas couverts par l’assurance maladie publique. Pour ces services, vous acceptez d’être financièrement responsable et obligé de payer tout solde dû qui n’est pas autrement payé par l’assurance maladie publique, tout fournisseur d’avantages sociaux tiers, toutes les polices d’assurance et / ou les tiers payeurs responsables. Vous reconnaissez et acceptez qu’un tel paiement sera versé à Generation Fertility, à ses sociétés affiliées et/ou à ses fournisseurs de services, conformément aux informations reçues avant d’être fourni avec tout Service. Vous reconnaissez et acceptez en outre que dans le cas où vous n’effectuez pas de tels paiements conformément aux conditions de paiement à cet égard, Generation Fertility peut mettre fin à votre relation avec elle, conformément aux lois applicables. De plus, en cas de manquement à votre obligation financière, si votre compte est remis à une agence de recouvrement externe pour non-paiement, vous acceptez de payer tous les frais de recouvrement associés",
	"FINANCIAL RESPONSIBILITY FOR SERVICES":
		"RESPONSABILITÉ FINANCIÈRE À L’ÉGARD DES SERVICES",
	"You are also responsible for: (i) all transactions or activities carried out using your Account and Password; and (ii) ensuring that when you access the Account that the system you use to access the Account is secure, is not left unattended unless you have fully exited the Account, and that your Password is not retrievable from the system by others":
		"Vous êtes également responsable de : (i) toutes les transactions ou activités effectuées à l’aide de votre compte et de votre mot de passe ; et (ii) s’assurer que lorsque vous accédez au compte, le système que vous utilisez pour accéder au compte est sécurisé, n’est pas laissé sans surveillance à moins que vous n’ayez complètement quitté le compte, et que votre mot de passe n’est pas récupérable du système par d’autres",
	"To the fullest extent permitted by applicable law, we reserve the right to take any action that we deem necessary to ensure the security of the Services and your Account, including without limitation deactivating your Password, terminating your Account, or requesting additional information to authenticate your Account. You are solely responsible for keeping your Password and any security questions and responses associated with your Account confidential. Anyone with knowledge of or access to your Password or the security questions and responses associated with your Account can use that information to gain access to your Account. You are solely liable for any claims, damages, losses, costs, or other liabilities resulting from or caused by any failure to keep your Password and the security questions and responses confidential, whether such failure occurs with or without your knowledge or consent. You agree that you will immediately notify us of any suspected or actual unauthorized access to or use of your Password or any other breach of your Account security":
		"Dans toute la mesure permise par la loi applicable, nous nous réservons le droit de prendre toute mesure que nous jugeons nécessaire pour assurer la sécurité des Services et de votre Compte, y compris, sans s’y limiter, la désactivation de votre mot de passe, la résiliation de votre Compte ou la demande d’informations supplémentaires pour authentifier votre Compte. Vous êtes seul responsable de la confidentialité de votre mot de passe et de toutes les questions de sécurité et réponses associées à votre compte. Toute personne ayant connaissance ou accès à votre mot de passe ou aux questions et réponses de sécurité associées à votre compte peut utiliser ces informations pour accéder à votre compte. Vous êtes seul responsable de toute réclamation, dommage, perte, coût ou autre responsabilité résultant de ou causé par tout manquement à garder votre mot de passe et les questions et réponses de sécurité confidentielles, que cette défaillance se produise à votre insu ou sans votre consentement. Vous acceptez de nous informer immédiatement de tout accès ou utilisation non autorisé suspecté ou réel de votre mot de passe ou de toute autre violation de la sécurité de votre compte",
	"It is a condition of your use of the Services that all the required information you provide is correct, current, and complete. You agree to promptly inform us of any changes to your Account registration information":
		"L’une des conditions de votre utilisation des Services est que toutes les informations requises que vous fournissez soient correctes, à jour et complètes. Vous acceptez de nous informer rapidement de tout changement apporté aux informations d’enregistrement de votre compte",
	"To set up an Account, you must provide us with certain personal information and establish a username and password that you will use to access the Services":
		"Pour créer un Compte, vous devez nous fournir certaines informations personnelles et établir un nom d’utilisateur et un mot de passe que vous utiliserez pour accéder aux Services",
	"In order to use certain aspects of the Services, you must set up an account with us":
		"Afin d’utiliser certains aspects des Services, vous devez créer un compte chez nous",
	"ACCESSING THE SERVICES AND ACCOUNT SECURITY":
		"ACCÈS AUX SERVICES ET SÉCURITÉ DU COMPTE",
	"If you provide us with any feedback or suggestions regarding the Services or the Platform Website (any such feedback referred to herein as “Feedback”), you hereby assign to Generation Fertility all rights in the Feedback and agree that Generation Fertility shall have the right to use such Feedback and related information in any manner it deems appropriate. Generation Fertility will treat any Feedback you provide to Platform as non-confidential and non-proprietary. You agree that you will not submit to Generation Fertility any Feedback that contains your confidential health information or confidential health information of any third party, nor will you submit any information that you do not have the rights or permission to submit":
		"Si vous nous fournissez des commentaires ou des suggestions concernant les Services ou le Site Web de la Plate-forme (ces commentaires appelés ici « Commentaires »), vous cédez par la présente à Generation Fertility tous les droits sur les Commentaires et acceptez que Generation Fertility ait le droit d’utiliser ces Commentaires et les informations connexes de la manière qu’elle juge appropriée. Generation Fertility traitera tous les Commentaires que vous fournissez à la Plateforme comme non confidentiels et non exclusifs. Vous acceptez de ne pas soumettre à Generation Fertility de commentaires contenant vos informations de santé confidentielles ou des informations de santé confidentielles d’un tiers, ni de soumettre des informations que vous n’avez pas les droits ou la permission de soumettre",
	FEEDBACK: "COMMENTAIRES",
	"WE MAY TERMINATE OR DISABLE YOUR ACCESS TO ANY OR ALL OF THE SERVICES FOR ANY REASON, WITH OR WITHOUT CAUSE, INCLUDING IF WE BELIEVE THAT YOU HAVE VIOLATED OR ACTED INCONSISTENTLY WITH THESE TERMS":
		"NOUS POUVONS RÉSILIER OU DÉSACTIVER VOTRE ACCÈS À TOUT OU PARTIE DES SERVICES POUR QUELQUE RAISON QUE CE SOIT, AVEC OU SANS MOTIF, Y COMPRIS SI NOUS PENSONS QUE VOUS AVEZ VIOLÉ OU AGI DE MANIÈRE INCOMPATIBLE AVEC CES CONDITIONS",
	"otherwise attempt to interfere with the proper working of the Services":
		"tenter autrement d’interférer avec le bon fonctionnement des Services",
	"attack the Platform Website or any other Generation Fertility website via a denial-of-service attack or a distributed denial-of-service attack; or":
		"attaquer le site Web de la plate-forme ou tout autre site Web de Generation Fertility via une attaque par déni de service ou une attaque par déni de service distribué ; ou",
	"attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services are stored, or any server, computer, or database connected to the Services":
		"tenter d’obtenir un accès non autorisé, d’interférer avec, d’endommager ou de perturber toute partie des Services, le serveur sur lequel les Services sont stockés ou tout serveur, ordinateur ou base de données connecté aux Services",
	"use any automated means to access the Services, or collect any information from Services (including, without limitation, robots, spiders, scripts, or other automatic devices or programs);":
		"utiliser tout moyen automatisé pour accéder aux Services, ou collecter des informations à partir des Services (y compris, sans s’y limiter, les robots, les araignées, les scripts ou d’autres dispositifs ou programmes automatiques) ;",
	"harass, abuse, or otherwise mistreat any of our personnel":
		"harceler, abuser ou autrement maltraiter l’un de nos membres du personnel",
	"transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation":
		"transmettre ou obtenir l’envoi de tout matériel publicitaire ou promotionnel, y compris tout « courrier indésirable », « chaîne de lettres », « spam » ou toute autre sollicitation similaire",
	"upload, post, transmit, share, or otherwise make available any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of the Services":
		"télécharger, publier, transmettre, partager ou rendre disponible tout matériel contenant des virus logiciels ou tout autre code informatique, fichier ou programme conçu pour interrompre, détruire ou limiter la fonctionnalité des Services",
	"upload, post, transmit, share, store, or otherwise make publicly available through the Services any material that contains private information of any third party without their consent, including, without limitation, addresses, phone numbers, email addresses, social insurance numbers, and credit card numbers":
		"télécharger, publier, transmettre, partager, stocker ou autrement rendre public via les Services tout matériel contenant des informations privées d’un tiers sans son consentement, y compris, sans s’y limiter, les adresses, les numéros de téléphone, les adresses e-mail, les numéros d’assurance sociale et les numéros de carte de crédit",
	"impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age, or your affiliation with or authority to act on behalf of any person or entity":
		"usurper l’identité d’une personne ou d’une entité, ou déclarer faussement ou autrement déformer vous-même, votre âge, ou votre affiliation avec ou l’autorité d’agir au nom de toute personne ou entité",
	"You agree not to use the Services to":
		"Vous acceptez de ne pas utiliser les Services pour",
	"Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Services":
		"Supprimer ou modifier tout avis de droit d’auteur, de marque de commerce ou d’autres droits de propriété à partir de copies de documents des Services",
	"USE RESTRICTIONS": "RESTRICTIONS D’UTILISATION",
	"Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text":
		"Utilisez des illustrations, des photographies, des séquences vidéo ou audio, ou des graphiques séparément du texte d’accompagnement",
	"Modify copies of any materials from the Services":
		"Modifier les copies de tout matériel des Services",
	"Remove, circumvent, disable, damage, or otherwise interfere with security-related features of the Services, features that prevent or restrict use or copying of any content accessible through the Services or features that enforce limitations on use of the Service":
		"Supprimer, contourner, désactiver, endommager ou autrement interférer avec les fonctionnalités liées à la sécurité des Services, les fonctionnalités qui empêchent ou restreignent l’utilisation ou la copie de tout contenu accessible via les Services ou les fonctionnalités qui imposent des limitations à l’utilisation du Service",
	"Rent, lease, loan, resell, sublicense, distribute, or otherwise transfer your access to the Services to any third party":
		"Louer, prêter, revendre, sous-licencier, distribuer ou autrement transférer votre accès aux Services à un tiers",
	"Modify, disassemble, decompile, or reverse engineer the Services except to the extent that such restriction is expressly prohibited by law":
		"Modifier, désassembler, décompiler ou désosser les Services, sauf dans la mesure où une telle restriction est expressément interdite par la loi",
	"You may not": "Vous ne pouvez pas",
	"Subject to the use restrictions set forth below, these Terms grant you a limited, non-exclusive right to access and make personal use of the Services, subject to these Terms. The Services, and any part thereof may not be reproduced, copied, framed or otherwise exploited for any commercial purpose without the express prior written consent of Generation Fertility and/or its licensors":
		"Sous réserve des restrictions d’utilisation énoncées ci-dessous, les présentes Conditions vous accordent un droit limité et non exclusif d’accès et d’utilisation personnelle des Services, sous réserve des présentes Conditions. Les Services, et toute partie de ceux-ci, ne peuvent être reproduits, copiés, encadrés ou autrement exploités à des fins commerciales sans le consentement écrit préalable exprès de Generation Fertility et/ou de ses concédants de licence",
	"SERVICE ACCESS AND LIMITATION": "ACCÈS AU SERVICE ET LIMITATION",
	"You represent and warrant that you are a temporary or permanent resident of the Province of Ontario":
		"Vous déclarez et garantissez que vous êtes un résident temporaire ou permanent de la province de l’Ontario",
	"You represent that you are at least eighteen (18) years of age. You acknowledge that the Services and the Platform Website are only intended for users who are least eighteen (18) years old":
		"Vous déclarez que vous avez au moins dix-huit (18) ans. Vous reconnaissez que les Services et le Site Web de la Plateforme ne sont destinés qu’aux utilisateurs âgés d’au moins dix-huit (18) ans",
	"You agree that any and all information you provide to the Platform is truthful and accurate. Inaccurate information will affect your experience when using the Services and the Platform Website. You may also be asked to provide authorization or additional information when you use the Services and the Platform Website in order to verify the information or content you submit. You further agree to promptly notify Generation Fertility if you discover or reasonably suspect you have provided any false or incorrect information":
		"Vous acceptez que toutes les informations que vous fournissez à la Plate-forme sont véridiques et exactes. Des informations inexactes affecteront votre expérience lors de l’utilisation des Services et du Site Web de la Plateforme. Il peut également vous être demandé de fournir une autorisation ou des informations supplémentaires lorsque vous utilisez les Services et le Site Web de la Plateforme afin de vérifier les informations ou le contenu que vous soumettez. Vous acceptez en outre d’informer rapidement Generation Fertility si vous découvrez ou soupçonnez raisonnablement que vous avez fourni des informations fausses ou incorrectes",
	"You must at all times comply with these Terms in order for you to use the Services and the Platform Website. You will comply with all applicable federal, provincial, and local laws, regulations and rules when using the Services and the Platform Website":
		"Vous devez à tout moment vous conformer à ces Conditions afin que vous puissiez utiliser les Services et le Site Web de la Plateforme. Vous vous conformerez à toutes les lois, réglementations et règles fédérales, provinciales et locales applicables lorsque vous utiliserez les Services et le Site Web de la Plateforme",
	"Your access and continued use of the Platform Website is subject to you agreeing to and undertaking certain responsibilities. You hereby agree to the following":
		"Votre accès et votre utilisation continue du site Web de la plateforme sont subordonnés à votre acceptation et à l’exercice de certaines responsabilités. Vous acceptez par la présente ce qui suit",
	"USERS’ RESPONSIBILITIES": "RESPONSABILITÉS DES UTILISATEURS",
	"Our name, the Generation Fertility logo, and all related names, logos, product and service names, designs, and slogans are trademarks of Generation Fertility or its affiliates or licensors. You must not use such marks without the prior written permission of Generation Fertility. All other names, logos, product and service names, designs, and slogans on the Services are the trademarks of their respective owners":
		"Notre nom, le logo Generation Fertility et tous les noms, logos, noms de produits et de services, dessins et slogans associés sont des marques de commerce de Generation Fertility ou de ses sociétés affiliées ou concédants de licence. Vous ne devez pas utiliser de telles marques sans l’autorisation écrite préalable de Generation Fertility. Tous les autres noms, logos, noms de produits et de services, dessins et slogans sur les Services sont des marques de commerce de leurs propriétaires respectifs",
	"The Services and the information, files, documents, text, photographs, images, audio, and video contained therein, including any material made available for download by us, whether through Platform Website or otherwise (collectively, the “Content”) are the property of us or our licensors, as applicable. The Services and Contents are protected by Canadian and international copyright and trademark laws. The Content may not be copied, distributed, modified, reproduced, published, or used, in whole or in part, except for purposes authorized or approved in writing by us. All rights not expressly granted herein are reserved to us and our licensors":
		"Les Services et les informations, fichiers, documents, textes, photographies, images, audio et vidéo qu’ils contiennent, y compris tout matériel mis à disposition pour téléchargement par nous, que ce soit via le Site Web de la Plate-forme ou autrement (collectivement, le « Contenu ») sont la propriété de nous ou de nos concédants de licence, le cas échéant. Les Services et le Contenu sont protégés par les lois canadiennes et internationales sur le droit d’auteur et les marques de commerce. Le Contenu ne peut être copié, distribué, modifié, reproduit, publié ou utilisé, en tout ou en partie, sauf à des fins autorisées ou approuvées par écrit par nous. Tous les droits qui ne sont pas expressément accordés dans les présentes sont réservés à nous et à nos concédants de licence",
	"This process took an appropriate amount of time":
		"Ce processus a pris un temps approprié",
	"INTELLECTUAL PROPERTY": "PROPRIÉTÉ INTELLECTUELLE",
	Review: "Examen",
	"Let us know what you think!": "Dites-nous ce que vous en pensez !",
	"To understand how we process information collected about you, including what information we collect through our Services, how such information is used, and how such information may be shared, before you agree to these Terms":
		"Pour comprendre comment nous traitons les informations collectées à votre sujet, y compris les informations que nous recueillons via nos Services, comment ces informations sont utilisées et comment ces informations peuvent être partagées, avant que vous n’acceptiez ces Conditions",
	"your fertility history": "vos antécédents de fertilité",
	"Please review our Patient Privacy Policy":
		"Veuillez consulter notre Politique de confidentialité des patients",
	"This process was simple to complete":
		"Ce processus a été simple à mener à bien",
	"Thank you for completing": "Merci d’avoir complété",
	"As with any other health care service, there are risks associated with the use of the Services. In addition to any risks communicated to you by your Healthcare Professional, there are risks unique to accessing health care services virtually, which include that the Platform may not permit the transmission of information in a way that allows the Healthcare Professional to provide Services (for example, due to poor resolution of images). Other risks of the use of electronic means to transmit and store information are described in our Website Privacy Policy, posted at generationfertility.ca, and our patient privacy policy, provided to you in connection with the Services (the “Patient Privacy Policy”), both of which form part of these Terms":
		"Comme pour tout autre service de soins de santé, il existe des risques associés à l’utilisation des Services. En plus des risques qui vous sont communiqués par votre professionnel de la santé, il existe des risques uniques à l’accès direct aux services de soins de santé, notamment le fait que la plateforme ne permette pas la transmission d’informations d’une manière qui permet au professionnel de la santé de fournir des services (par exemple, en raison d’une mauvaise résolution des images). D’autres risques liés à l’utilisation de moyens électroniques pour transmettre et stocker des informations sont décrits dans notre politique de confidentialité du site Web, affichée à generationfertility.ca, et notre politique de confidentialité des patients, qui vous sont fournis dans le cadre des services (la « politique de confidentialité des patients »), qui font tous deux partie des présentes conditions",
	"Completing this process helped me feel I am progressing my fertility journey":
		"L’achèvement de ce processus m’a aidé à sentir que je progressais dans mon parcours de fertilité",
	"Your consent will be required before being provided with any Health Service and undergoing certain assessments. In this regard, you may be required to complete certain patient consent and acknowledgements (either written or verbal)":
		"Votre consentement sera requis avant d’être fourni avec un service de santé et de subir certaines évaluations. À cet égard, vous devrez peut-être remplir certains consentements et accusés de réception du patient (écrits ou verbaux)",
	"(scale of 1 [it took too long] to 5 [it took a reasonable amount of time])":
		"(échelle de 1 [cela a pris trop de temps] à 5 [cela a pris un délai raisonnable])",
	"The Platform allows you to access fertility resources, tools and services provided through fertility care coordinators (“Care Coordinators”) and physicians, nurses, or other healthcare professionals (“Healthcare Professionals”). Our Care Coordinators help coordinate, plan and manage your fertility planning and treatments (“Care Services”) and our Healthcare Professionals provide regulated health care services relating to fertility treatment (“Health Services”)":
		"La plateforme vous permet d’accéder aux ressources, aux outils et aux services de fertilité fournis par l’intermédiaire de coordonnateurs des soins de fertilité (« coordonnateurs des soins ») et de médecins, d’infirmières ou d’autres professionnels de la santé (« professionnels de la santé »). Nos coordonnateurs de soins aident à coordonner, planifier et gérer votre planification et vos traitements de fertilité (« Services de soins ») et nos professionnels de la santé fournissent des services de soins de santé réglementés liés au traitement de la fertilité (« Services de santé »)",
	"This provision may not apply to you if applicable law prohibits it. Please be aware that these Terms contain provisions that govern how claims that you and Generation Fertility have against each other are resolved and limit the remedies that may be available to you in the event of a dispute. In particular, it contains an arbitration provision which will require you to submit claims that you have against Generation Fertility to binding and final arbitration. To the fullest extent permitted by law: (i) you will only be permitted to pursue claims and seek relief against Generation Fertility on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding; and (ii) you are waiving your right to seek relief in a court of law and to have a jury trial on your claims. The arbitration provisions set forth in these Terms could affect your right to participate in class action litigation. Please see the section titled “ARBITRATION” for more information regarding these arbitration provisions":
		"Cette disposition peut ne pas s’appliquer à vous si la loi applicable l’interdit. Veuillez noter que ces Conditions contiennent des dispositions qui régissent la façon dont les réclamations que vous et Generation Fertility avez l’un contre l’autre sont résolues et limitent les recours qui peuvent être à votre disposition en cas de litige. En particulier, il contient une disposition d’arbitrage qui vous obligera à soumettre les réclamations que vous avez contre Generation Fertility à un arbitrage exécutoire et final. Dans toute la mesure permise par la loi : (i) vous ne serez autorisé à poursuivre des réclamations et à demander réparation contre Generation Fertility que sur une base individuelle, et non en tant que demandeur ou membre du groupe dans une action ou une procédure de recours collectif ou représentatif ; et (ii) vous renoncez à votre droit de demander réparation devant un tribunal et d’avoir un procès devant jury sur vos réclamations.Les dispositions d’arbitrage énoncées dans les présentes Conditions pourraient affecter votre droit de participer à des recours collectifs. Veuillez consulter la section intitulée « ARBITRAGE » pour plus d’informations sur ces dispositions d’arbitrage",
	"GENERATION FERTILITY HEALTH SERVICES":
		"GÉNÉRATION FERTILITÉ SERVICES DE SANTÉ",
	"(scale of 1 [it did not help] to 5 [it has helped])":
		"(échelle de 1 [cela n’a pas aidé] à 5 [cela a aidé])",
	"THE SERVICES ARE NOT INTENDED TO BE USED FOR URGENT OR EMERGENT HEALTHCARE NEEDS. IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, PLEASE DIAL 911":
		"LES SERVICES NE SONT PAS DESTINÉS À ÊTRE UTILISÉS POUR DES BESOINS URGENTS OU ÉMERGENTS EN MATIÈRE DE SOINS DE SANTÉ. SI VOUS RENCONTREZ UNE URGENCE MÉDICALE, VEUILLEZ COMPOSER LE 911",
	"Send feedback": "Envoyer des commentaires",
	"ARBITRATION NOTICE": "AVIS D’ARBITRAGE",
	"MEDICAL EMERGENCY": "URGENCE MÉDICALE",
	"EFFECTIVE DATE OF THESE TERMS IS SET FORTH AT THE TOP OF THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, GENERATION FERTILITY RESERVES THE RIGHT TO MODIFY,UPDATE AND CHANGE, AT ANY TIME, IN ITS SOLE AND ABSOLUTE  DISCRETION: (I) THESE TERMS; (II) ADDITIONAL DOCUMENTS RELATED TO THE SERVICES AND THE PLATFORM WEBSITE; (III)  SERVICES; AND/OR (IV) THE PLATFORM WEBSITE. CHANGES TO THE TERMS WILL BE POSTED TO THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY POSTED CHANGES WILL BE BINDING ON YOU THIRTY (30) DAYS FOLLOWING THEIR POSTING.WE ENCOURAGE YOU TO RETURN TO THIS WEBPAGE FREQUENTLY SO THAT YOU ARE AWARE OF OUR CURRENT TERMS. YOUR CONTINUED USAGE OF THE PLATFORM WEBSITE AND/OR SERVICES AFTER THE EFFECTIVE DATE WILL MEAN YOU ACCEPT AND CONSENT TO ANY CHANGES, UPDATES AND MODIFICATIONS TO THE TERMS. THE AMENDED TERMS SUPERSEDE ALL PREVIOUS VERSIONS":
		"LA DATE D’ENTRÉE EN VIGUEUR DE CES CONDITIONS EST INDIQUÉE EN HAUT DE CETTE PAGE WEB. DANS TOUTE LA MESURE PERMISE PAR LA LOI, GENERATION FERTILITY SE RÉSERVE LE DROIT DE MODIFIER, DE METTRE À JOUR ET DE CHANGER, À TOUT MOMENT, À SA SEULE ET ABSOLUE DISCRÉTION : (I) CES CONDITIONS ; (II) DES DOCUMENTS SUPPLÉMENTAIRES LIÉS AUX SERVICES ET AU SITE WEB DE LA PLATEFORME ; III) SERVICES ; ET/OU (IV) LE SITE WEB DE LA PLATEFORME. LES MODIFICATIONS APPORTÉES AUX CONDITIONS SERONT AFFICHÉES SUR CETTE PAGE WEB. DANS TOUTE LA MESURE PERMISE PAR LA LOI, TOUTE MODIFICATION PUBLIÉE VOUS LIERA TRENTE (30) JOURS APRÈS LEUR PUBLICATION. NOUS VOUS ENCOURAGEONS À REVENIR FRÉQUEMMENT SUR CETTE PAGE WEB AFIN QUE VOUS SOYEZ AU COURANT DE NOS CONDITIONS ACTUELLES. VOTRE UTILISATION CONTINUE DU SITE WEB ET / OU DES SERVICES DE LA PLATE-FORME APRÈS LA DATE D’ENTRÉE EN VIGUEUR SIGNIFIERA QUE VOUS ACCEPTEZ ET CONSENTEZ À TOUT CHANGEMENT, MISE À JOUR ET MODIFICATION DES CONDITIONS. LES CONDITIONS MODIFIÉES REMPLACENT TOUTES LES VERSIONS PRÉCÉDENTES",
	"CHANGES TO SERVICES AND TERMS":
		"MODIFICATIONS DES SERVICES ET DES CONDITIONS",
	"These Terms are entered into by and between you and Generation Fertility, and these Terms govern your access to and use of our Care Navigation Platform (the “Platform”), including any content, functionality, services, or products offered on, through or in connection with www.generation.fertility.care (the “Platform Website”) (collectively, the “Services”). By accessing the Platform Website and Services or using other acceptance methods provided by our Services, you agree to be bound by these Terms. Additionally, when generally accessing the Services, your use and continued	 access of the Services signifies your acceptance to these Terms. If you do not agree to any of the terms or conditions in these Terms you should immediately leave the Platform Website, not access or use the Services, or create an account and not use the Platform Website":
		"« Les présentes Conditions sont conclues par et entre vous et Generation Fertility, et les présentes Conditions régissent votre accès et votre utilisation de notre Plateforme de navigation des soins (la « Plateforme »), y compris tout contenu, fonctionnalité, services ou produits offerts sur, via ou en relation avec www.generation.fertility.care (le « Site Web de la Plateforme ») (collectivement, les « Services »). En accédant au site Web et aux services de la plate-forme ou en utilisant d’autres méthodes d’acceptation fournies par nos services, vous acceptez d’être lié par ces conditions. De plus, lorsque vous accédez généralement aux Services, votre utilisation et votre accès continu aux Services signifient votre acceptation des présentes Conditions. Si vous n’acceptez pas l’un des termes ou conditions des présentes Conditions générales, vous devez immédiatement quitter le site Web de la plate-forme, ne pas accéder ou utiliser les services, ou créer un compte et ne pas utiliser le site Web de la plate-forme ",
	"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of Generation Fertility and its respective	affiliates, and obligations to comply with applicable laws and regulations":
		"« Avant d’utiliser l’un des services disponibles via notre plate-forme de navigation de soins, vous devez lire attentivement ces conditions d’utilisation (« Conditions ») car elles contiennent des informations importantes affectant vos droits, obligations et recours légaux. Cela inclut, entre autres dispositions importantes, diverses limitations de vos droits, les conditions concernant l’utilisation des informations personnelles, la limitation de la responsabilité de Generation Fertility et de ses sociétés affiliées respectives, et les obligations de se conformer aux lois et réglementations applicables .",
	"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as Generation Fertility (“Generation Fertility”, “we,” “us,” or “our”), a Canadian company with offices at 955 Major MacKenzie Drive West, Unit 400, Maple, Ontario, and 1111 Davis Drive East, Unit 39, Newmarket, Ontario":
		"Bienvenue sur notre plateforme de navigation de soins ! Ce service est offert par Procrea Canada (Vaughan) Inc., exerçant sous le nom de Generation Fertility (« Generation Fertility », « nous », « notre » ou « nos »), une entreprise canadienne dont les bureaux sont situés au 955 Major MacKenzie Drive West, Unit 400, Maple, Ontario, et au 1111 Davis Drive East, Unit 39, Newmarket, Ontario",
	"EFFECTIVE DATE: January 30, 2023":
		"DATE D’ENTRÉE EN VIGUEUR : 30 janvier 2023",
	"PLATFORM TERMS OF USE": "CONDITIONS D’UTILISATION DE LA PLATEFORME",
	"Please give a reason": "Veuillez donner une raison",
	"Please select one of the reasons":
		"Veuillez sélectionner l’une des raisons",
	Unsubscribed: "Désabonné",
	"You have been unsubscribed! You will no longer receive emails from us":
		"Vous avez été désabonné ! Vous ne recevrez plus d’e-mails de notre part",
	Unsubscribe: "Se désabonner",
	"You will no longer receive emails from us":
		"Vous ne recevrez plus d’e-mails de notre part",
	"If you have a moment, please let us know why you don’t need an email anymore":
		"Si vous avez un moment, s’il vous plaît laissez-nous savoir pourquoi vous n’avez plus besoin d’un e-mail",
	"Address postal code is required": "Le code postal de l’adresse est requis",
	"Complete Your Profile": "Complétez votre profil",
	"Date of birth is required": "La date de naissance est requise",
	"Date Of Birth is required": "La date de naissance est requise",
	"Health card expiry date is required":
		"La date d’expiration de la carte Santé est requise",
	"Health Card Expiry Date": "Date d’expiration de la carte Santé",
	"Address is required": "L’adresse est requise",
	"Check your spam folder": "Vérifiez votre dossier de spam",
	"Invalid Token": "Jeton non valide",
	"Your confidential health forms include a history of your general health and fertility to help us tailor your first consultation to your needs. This information needs to be completed before your first virtual consultation":
		"Vos formulaires de santé confidentiels comprennent un historique de votre état de santé général et de votre fertilité pour nous aider à adapter votre première consultation à vos besoins. Ces informations doivent être complétées avant votre première consultation virtuelle",
	"Save and quit": "Enregistrer et cesser de fumer",
	Speciality: "Spécialité",
	Specialty: "Spécialités",
	Finish: "Terminer",
	"Continue where I left off": "Continuez là où je me suis arrêté",
	"Your information is always protected":
		"Vos informations sont toujours protégées",
	"Please select an answer for the first question":
		"Veuillez sélectionner une réponse à la première question",
	"Please select an answer for the second question":
		"Veuillez sélectionner une réponse à la deuxième question",
	"Please select an answer for the third question":
		"Veuillez sélectionner une réponse à la troisième question",
	"(scale of 1 [it was not simple] to 5 [it was very simple])":
		"(échelle de 1 [ce n’était pas simple] à 5 [c’était très simple])",
	"The token is invalid": "Le jeton n’est pas valide",
	"By creating an account, I agree to": "En créant un compte, j’accepte de",
	"Verify your email": "Vérifiez votre e-mail",
	"Verify your phone number": "Vérifiez votre numéro de téléphone",
	"Your browser does not support the audio element":
		"Votre navigateur ne prend pas en charge l’élément audio",
	"Install this app on your iPhone: tap":
		"Installez cette application sur votre iPhone : appuyez sur",
	"and then Add to homescreen": "puis Ajouter à l’écran d’accueil",
	"Canada (English)": "Canada (anglais)",
	Francais: "Français",
	"Having some issues": "Avoir quelques problèmes",
	"Close Account": "Fermer le compte",
	"Please read this carefully": "S’il vous plaît lire attentivement ceci",
	"Your Generation Fertility account will be permanently closed and your data will be deleted. This means that your account will no longer be available and cannot be restored":
		"Votre compte Generation Fertility sera définitivement fermé et vos données seront supprimées. Cela signifie que votre compte ne sera plus disponible et ne pourra plus être restauré",
	"Please select the main reason for closing your account":
		"Veuillez sélectionner la principale raison de la fermeture de votre compte",
	"Choose reason (optional)": "Choisissez la raison (facultatif)",
	"Cancel your appointment": "Annuler votre rendez-vous",
	"Are you sure you want to cancel your appointment on":
		"Êtes-vous sûr de vouloir annuler votre rendez-vous le",
	"Thank you for getting in touch!": "Merci d’avoir pris contact !",
	"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as Generation Fertility (“Generation Fertility”, “we,” “us” or “our”), a Canadian company with offices at 955 Major MacKenzie Drive West, Unit 400, Maple, Ontario, and 1111 Davis Drive East, Unit 39, Newmarket, Ontario":
		"Bienvenue sur notre plateforme de navigation de soins ! Ce service est offert par Procrea Canada (Vaughan) Inc., exerçant ses fonctions sous le nom de Generation Fertility (« Generation Fertility », « nous », « notre » ou « nos »), une entreprise canadienne ayant des bureaux au 955 Major MacKenzie Drive West, Unit 400, Maple, Ontario, et 1111 Davis Drive East, Unit 39, Newmarket, Ontario",
	"Message/specify (Please be as detailed as possible)":
		"Message/préciser (Veuillez être aussi détaillé que possible)",
	"For further information, please visit":
		"Pour de plus amples renseignements, veuillez consulter le site Web",
	"There are": "Il y a",
	"tasks you'll need to complete before your first appointment":
		"tâches que vous devrez effectuer avant votre premier rendez-vous",
	"Last name should contain valid characters":
		"Le nom de famille doit contenir des caractères valides",
	"First name should contain valid characters":
		"Le prénom doit contenir des caractères valides",
	"Partner has already created the account":
		"Le partenaire a déjà créé le compte",
	"Invite your partner to set up their own profile. They will need to complete their own set of consent and health forms":
		"Invitez votre partenaire à créer son propre profil. Ils devront remplir leur propre ensemble de formulaires de consentement et de santé",
	"Phone Number Verified and Saved":
		"Numéro de téléphone vérifié et enregistré",
	"Invite Your Partner": "Invitez votre partenaire",
	Verification: "Vérification",
	"Please confirm the first day of your period":
		"Veuillez confirmer le premier jour de vos règles",
	"Monday, August 1, 2022": "Lundi, Août 1, 2022",
	"Are you still there": "Êtes-vous toujours là",
	"To protect your information, we will automatically log you out after a period of no activity":
		"Pour protéger vos informations, nous vous déconnecterons automatiquement après une période d’absence d’activité",
	"Remaining time": "Temps restant",
	"Yes, I’m here": "Oui, je suis là",
	Welcome: "Bienvenue",
	"Yes, I want to permanently close my Generation Fertility account and delete my data":
		"Oui, je souhaite fermer définitivement mon compte Generation Fertility et supprimer mes données",
	"Close my account": "Fermer mon compte",
	"Choose reason": "Choisissez la raison",
	"There are three tasks you'll need to complete before your first appointment":
		"Il y a trois tâches que vous devrez effectuer avant votre premier rendez-vous",
	"First day of your period": "Premier jour de vos règles",
	"Welcome ": "Bienvenue ",
	Accessibility: "Accessibilité",
	Help: "Aide",
	Hormones: "Les hormones",
	"All caught up": "Tous rattrapés",
	"Personal Information": "Renseignements personnels",
	"Communication Preferences": "Préférences de communication",
	"Having some issue": "Avoir un problème",
	Hello: "Allô",
	"What is your ethnic background": "Quelle est votre origine ethnique",
	"What is your occupation": "Quelle est votre profession",
	Musician: "Musicien",
	"Please enter your height": "S’il vous plaît entrer votre taille",
	"Please enter your weight": "S’il vous plaît entrer votre poids",
	"Have you experienced any weight changes":
		"Avez-vous connu des changements de poids",
	Lost: "Perdu",
	"Do you exercise regularly": "Faites-vous de l’exercice régulièrement ?",
	"Do you smoke cigarettes": "Fumez-vous des cigarettes ?",
	"How many cigarettes do you smoke a day":
		"Combien de cigarettes fumez-vous par jour",
	"Do you drink alcohol": "Buvez-vous de l’alcool",
	"Do you consume caffeine (e.g. coffee, tea, pop)":
		"Consommez-vous de la caféine (p. ex. café, thé, pop)",
	"Please select which ones": "Veuillez sélectionner ceux qui",
	"Coffee, Tea": "Café, Thé",
	"How many cups a day": "Combien de tasses par jour",
	"Do you use recreational drugs (e.g. cannabis, cocaine, etc)":
		"Utilisez-vous des drogues récréatives (p. ex. cannabis, cocaïne, etc.)",
	"Have you ever been exposed to harmful chemicals or toxins":
		"Avez-vous déjà été exposé à des produits chimiques nocifs ou à des toxines",
	"Now we are going to ask some questions related to hormones":
		"Maintenant, nous allons poser quelques questions liées aux hormones",
	"This should take about": "Cela devrait prendre environ",
	"2 minutes": "2 minutes",
	Start: "Début",
	"Social activities": "Activités sociales",
	"Previous pregnancies": "Grossesses antérieures",
	Black: "Noir",
	"Biological history": "Histoire biologique",
	"On a scale from 1-5, how would you rate":
		"Sur une échelle de 1 à 5, comment évalueriez-vous",
	"your experience": "votre expérience",
	"about your experience that made you choose 5":
		"à propos de votre expérience qui vous a fait choisir 5",
	"We are so happy to hear you choose 5! What was it":
		"Nous sommes si heureux de vous entendre choisir 5 ! Qu’est-ce que c’était",
	"Back to patient portal": "Retour au portail patient",
	"To-do": "À faire",
	"Once you have completed all your intake forms, the clinic will call to confirm your first appointment":
		"Une fois que vous aurez rempli tous vos formulaires d’admission, la clinique vous appellera pour confirmer votre premier rendez-vous",
	"Contact Us": "Contactez-nous",
	"Contact us": "Contactez-nous",
	"Sex at birth": "Sexe à la naissance",
	"Sex at Birth": "Sexe à la naissance",
	"Hetero - sexual": "Hétéro - sexuel",
	"Same - sex": "Même - sexe",
	"She/her": "Elle/elle",
	"They/them": "Ils/eux",
	"incorrect username or password":
		"nom d’utilisateur ou mot de passe incorrect",
	"Incorrect password": "Mot de passe incorrect",
	"Incorrect username or password.":
		"Nom d’utilisateur ou mot de passe incorrect.",
	"incorrect username or password.":
		"nom d’utilisateur ou mot de passe incorrect.",
	"Do you have a family doctor": "Avez-vous un médecin de famille ?",
	"Full Name": "Nom complet",
	"Do you allow us to contact your family doctor to get a referral on your behalf":
		"Nous permettez-vous de communiquer avec votre médecin de famille pour obtenir une recommandation en votre nom ?",
	"Invalid password": "Mot de passe non valide",
	"Email address is required": "L’adresse e-mail est requise",
	"Invalid session for the user": "Session non valide pour l’utilisateur",
	To: "Pour",
	"Edit Username": "Modifier le nom d’utilisateur",
	"Please complete your profile first":
		"Veuillez d’abord remplir votre profil",
	"Select an Option": "Sélectionnez une option",
	"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of Generation Fertility and its respective affiliates, and obligations to comply with applicable laws and regulations.":
		"Avant d’utiliser l’un des services disponibles via notre plate-forme de navigation de soins, vous devez lire attentivement ces conditions d’utilisation (« Conditions ») car elles contiennent des informations importantes affectant vos droits, obligations et recours légaux. Cela inclut, entre autres dispositions importantes, diverses limitations sur vos droits, les conditions concernant l’utilisation des informations personnelles, la limitation de la responsabilité de Generation Fertility et de ses sociétés affiliées respectives, et les obligations de se conformer aux lois et réglementations applicables.",
	"register for more than one Account or register or operate an Account on behalf of another person":
		"vous inscrire à plus d’un compte ou créer ou exploiter un compte au nom d’une autre personne",
	"We retain personal information for as long as necessary for the fulfilment of the purposes for which it was collected, in accordance with prudent business practices on general document retention, or as otherwise require d by law":
		"Nous conservons les renseignements personnels aussi longtemps que nécessaire pour la réalisation des fins pour lesquelles ils ont été recueillis, conformément à des pratiques commerciales prudentes sur la conservation générale des documents, ou comme l’exige la loi",
	"Select priority": "Sélectionner la priorité",
	"Partner Profile": "Profil du partenaire",
	Inactive: "Inactif",
	"Assigned Role": "Rôle attribué",
	"Edit Member": "Modifier le membre",
	"PreAuthentication failed with error This user is not an administrator":
		"PréAuthentication a échoué avec erreur Cet utilisateur n’est pas un administrateur",
	Text: "Texte",
	Never: "Jamais",
	CLINIC_NAME: "CLINIC_NAME",
	CLINIC_WEBSITE: "CLINIC_WEBSITE",
	"Physician campaign Details": "Détails de la campagne des médecins",
	Unassign: "Annuler l’affectation",
	Month: "Mois",
	Year: "Année",
	Administrator: "Administrateur",
	CareCoordinator: "CareCoordinator",
	MOA: "Mode d’action",
	WebReferral: "WebReferral",
	"Yes, disable this user": "Oui, désactivez cet utilisateur",
	"Campaign Status": "État de la campagne",
	Channel: "Canal",
	"Campaigns Name": "Nom de la campagne",
	"Campaign info": "Informations sur la campagne",
	"Log In": "S’identifier",
	"One time password delivery method":
		"Méthode de remise de mot de passe unique",
	"Invalid session for the user.": "Session non valide pour l’utilisateur.",
	"Your biological history forms the basis of your fertility journey.":
		"Votre histoire biologique constitue la base de votre voyage de fertilité.",
	"Your biological history forms the basis of your fertility journey":
		"Votre histoire biologique constitue la base de votre parcours de fertilité",
	"We look forward to seeing you and helping you take the next steps on your fertility journey":
		"Nous sommes impatients de vous voir et de vous aider à franchir les prochaines étapes de votre parcours de fertilité",
	"Notes added successfully": "Notes ajoutées avec succès",
	"Notes Updated successfully": "Remarques : Mise à jour réussie",
	"Notes deleted successfully": "Notes supprimées avec succès",
	"No Documents": "Aucun document",
	"Please answer this question": "Veuillez répondre à cette question",
	"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as CLINIC_NAME (“CLINIC_NAME”, “we,” “us” or “our”), a Canadian company with offices at CLINIC_ADDRESS":
		"Bienvenue sur notre plateforme de navigation de soins ! Ce service est offert par Procrea Canada (Vaughan) Inc., exerçant ses fonctions de CLINIC_NAME (« CLINIC_NAME », « nous », « notre » ou « nos »), une entreprise canadienne ayant des bureaux à CLINIC_ADDRESS",
	"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of CLINIC_NAME and its respective	affiliates, and obligations to comply with applicable laws and regulations":
		"« Avant d’utiliser l’un des services disponibles via notre plate-forme de navigation de soins, vous devez lire attentivement ces conditions d’utilisation (« Conditions ») car elles contiennent des informations importantes affectant vos droits, obligations et recours légaux. Cela comprend, entre autres dispositions importantes, diverses limitations de vos droits, les conditions concernant l’utilisation des informations personnelles, la limitation de la responsabilité de CLINIC_NAME et de ses sociétés affiliées respectives, et les obligations de se conformer aux lois et règlements applicables.",
	"These Terms are entered into by and between you and CLINIC_NAME, and these Terms govern your access to and use of our Care Navigation Platform (the “Platform”), including any content, functionality, services, or products offered on, through or in connection with www.CLINIC_DOMAIN.fertility.care (the “Platform Website”) (collectively, the “Services”). By accessing the Platform Website and Services or using other acceptance methods provided by our Services, you agree to be bound by these Terms. Additionally, when generally accessing the Services, your use and continued	 access of the Services signifies your acceptance to these Terms. If you do not agree to any of the terms or conditions in these Terms you should immediately leave the Platform Website, not access or use the Services, or create an account and not use the Platform Website":
		"« Les présentes Conditions sont conclues par et entre vous et CLINIC_NAME, et les présentes Conditions régissent votre accès et votre utilisation de notre Plateforme de navigation des soins (la « Plateforme »), y compris tout contenu, fonctionnalité, services ou produits offerts sur, via ou en relation avec www. CLINIC_DOMAIN.fertility.care (le « Site Web de la Plateforme ») (collectivement, les « Services »). En accédant au site Web et aux services de la plate-forme ou en utilisant d’autres méthodes d’acceptation fournies par nos services, vous acceptez d’être lié par ces conditions. De plus, lorsque vous accédez généralement aux Services, votre utilisation et votre accès continu aux Services signifient votre acceptation des présentes Conditions. Si vous n’acceptez pas l’un des termes ou conditions des présentes Conditions générales, vous devez immédiatement quitter le site Web de la plate-forme, ne pas accéder ou utiliser les services, ou créer un compte et ne pas utiliser le site Web de la plate-forme ",
	"EFFECTIVE DATE OF THESE TERMS IS SET FORTH AT THE TOP OF THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, CLINIC_NAME RESERVES THE RIGHT TO MODIFY,UPDATE AND CHANGE, AT ANY TIME, IN ITS SOLE AND ABSOLUTE  DISCRETION: (I) THESE TERMS; (II) ADDITIONAL DOCUMENTS RELATED TO THE SERVICES AND THE PLATFORM WEBSITE; (III)  SERVICES; AND/OR (IV) THE PLATFORM WEBSITE. CHANGES TO THE TERMS WILL BE POSTED TO THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY POSTED CHANGES WILL BE BINDING ON YOU THIRTY (30) DAYS FOLLOWING THEIR POSTING.WE ENCOURAGE YOU TO RETURN TO THIS WEBPAGE FREQUENTLY SO THAT YOU ARE AWARE OF OUR CURRENT TERMS. YOUR CONTINUED USAGE OF THE PLATFORM WEBSITE AND/OR SERVICES AFTER THE EFFECTIVE DATE WILL MEAN YOU ACCEPT AND CONSENT TO ANY CHANGES, UPDATES AND MODIFICATIONS TO THE TERMS. THE AMENDED TERMS SUPERSEDE ALL PREVIOUS VERSIONS":
		"LA DATE D’ENTRÉE EN VIGUEUR DE CES CONDITIONS EST INDIQUÉE EN HAUT DE CETTE PAGE WEB. DANS TOUTE LA MESURE PERMISE PAR LA LOI, CLINIC_NAME SE RÉSERVE LE DROIT DE MODIFIER, DE METTRE À JOUR ET DE CHANGER, À TOUT MOMENT, À SA SEULE ET ABSOLUE DISCRÉTION : (I) LES PRÉSENTES CONDITIONS ; (II) DES DOCUMENTS SUPPLÉMENTAIRES LIÉS AUX SERVICES ET AU SITE WEB DE LA PLATEFORME ; III) SERVICES ; ET/OU (IV) LE SITE WEB DE LA PLATEFORME. LES MODIFICATIONS APPORTÉES AUX CONDITIONS SERONT AFFICHÉES SUR CETTE PAGE WEB. DANS TOUTE LA MESURE PERMISE PAR LA LOI, TOUTE MODIFICATION PUBLIÉE VOUS LIERA TRENTE (30) JOURS APRÈS LEUR PUBLICATION. NOUS VOUS ENCOURAGEONS À REVENIR FRÉQUEMMENT SUR CETTE PAGE WEB AFIN QUE VOUS SOYEZ AU COURANT DE NOS CONDITIONS ACTUELLES. VOTRE UTILISATION CONTINUE DU SITE WEB ET / OU DES SERVICES DE LA PLATE-FORME APRÈS LA DATE D’ENTRÉE EN VIGUEUR SIGNIFIERA QUE VOUS ACCEPTEZ ET CONSENTEZ À TOUT CHANGEMENT, MISE À JOUR ET MODIFICATION DES CONDITIONS. LES CONDITIONS MODIFIÉES REMPLACENT TOUTES LES VERSIONS PRÉCÉDENTES",
	"View Forms": "Voir les formulaires",
	"At least 1 number": "Au moins 1 nombre",
	"At least 1 capital letter": "Au moins 1 lettre majuscule",
	"8 or more characters": "8 caractères ou plus",
	"At least 1 special character": "Au moins 1 caractère spécial",
	Contacted: "Contacté",
	Virtual: "Virtuel",
	"On-Site": "Sur place",
	"You're sending": "Vous envoyez",
	"Business transactions": "Transactions commerciales",
	"Text Message": "Message texte",
	"Last invitation sent on": "Dernière invitation envoyée le",
	"Need Attention": "Besoin d’attention",
	Send: "Envoyer",
	send: "envoyer",
	"Email sent": "Courriel envoyé",
	"Cancelled Appointment": "Rendez-vous annulé",
	Booked: "Réservé",
	"Active High Volume": "Volume élevé actif",
	"Active high volume": "Volume élevé actif",
	active_high_volume: "active_high_volume",
	active_core: "active_core",
	"Active Core": "Noyau actif",
	"3 Months Lapsed": "3 mois écoulés",
	"4 Months Lapsed": "4 mois écoulés",
	"5 Months Lapsed": "5 mois écoulés",
	"Yes, How can we help you": "Oui, Comment pouvons-nous vous aider",
	"Profile Completion": "Achèvement du profil",
	"Fertility Specialist": "Spécialiste de la fertilité",
	"Please check your email": "S’il vous plaît vérifier votre e-mail",
	"Password successfully updated": "Mot de passe mis à jour avec succès",
	"Error deleting items from DynamoDB":
		"Erreur de suppression d’éléments de DynamoDB",
	"Token found": "Jeton trouvé",
	"Internal server error": "Erreur de serveur interne",
	"Key does not exist in request body":
		"La clé n’existe pas dans le corps de la demande",
	"User is disabled.": "L’utilisateur est désactivé.",
	"Please enter the OTP":
		"S’il vous plaît entrer dans le BUREAU DU PROCUREUR",
	"User does not exist": "L’utilisateur n’existe pas",
	"delete this note": "supprimer cette note",
	"You will not be able to revert this":
		"Vous ne pourrez pas revenir en arrière",
	"Yes, delete this note": "Oui, supprimez cette note",
	False: "Faux",
	"Assigned role is required": "Le rôle attribué est requis",
	"Invitation sent to phone and email successfully":
		"Invitation envoyée au téléphone et à l’e-mail avec succès",
	"Invitation sent to phone successfully":
		"Invitation envoyée au téléphone avec succès",
	"Invitation sent to email successfully":
		"Invitation envoyée à l’e-mail avec succès",
	"Please provide the sex at birth to assign the partner as pre-diagnostic.":
		"Veuillez indiquer le sexe à la naissance pour désigner le partenaire comme pré-diagnostic.",
	"Please provide email, sex at birth and REI to assign the lead as pre-diagnostic.":
		"Veuillez fournir un e-mail, le sexe à la naissance et REI pour attribuer le plomb comme pré-diagnostic.",
	"Please provide email and sex at birth to assign the lead as pre-diagnostic.":
		"Veuillez fournir un courriel et le sexe à la naissance pour attribuer le plomb comme pré-diagnostic.",
	"Please provide email and REI to assign the lead as pre-diagnostic.":
		"Veuillez fournir un e-mail et rei pour attribuer le plomb comme pré-diagnostic.",
	"Please provide sex at birth and REI to assign the lead as pre-diagnostic.":
		"Veuillez fournir le sexe à la naissance et REI pour attribuer le plomb comme pré-diagnostic.",
	"Please provide email to assign the lead as pre-diagnostic.":
		"Veuillez fournir un e-mail pour attribuer le prospect comme pré-diagnostic.",
	"Please provide sex at birth to assign the lead as pre-diagnostic.":
		"Veuillez indiquer le sexe à la naissance pour attribuer le plomb comme pré-diagnostic.",
	"Please provide REI to assign the lead as pre-diagnostic.":
		"Veuillez fournir REI pour attribuer le plomb comme pré-diagnostic.",
	"Please select a partner": "Veuillez sélectionner un partenaire",
	"Day, Month, Year": "Jour, mois, année",
	"Archived Reason": "Raison archivée",
	"Name and contact information (including mailing address, email address, and telephone number)":
		"Nom et coordonnées (y compris l’adresse postale, l’adresse e-mail et le numéro de téléphone)",
	"Benefit provider": "Fournisseur d’avantages sociaux",
	"Submitted by": "Soumis par",
	"Not Submitted": "Non soumis",
	New: "Nouveau",
	"Patient records will be moved to the previous stage":
		"Les dossiers des patients seront transférés à l’étape précédente",
	January: "Janvier",
	February: "Février",
	March: "Mars",
	April: "Avril",
	May: "Mai",
	June: "Juin",
	July: "Juillet",
	August: "Août",
	September: "Septembre",
	October: "Octobre",
	November: "Novembre",
	December: "Décembre",
	Jan: "Janv",
	Feb: "Fév",
	Mar: "Mars",
	Apr: "Avril",
	Jun: "Juin",
	Jul: "Juil",
	Aug: "Août",
	Sep: "Sept",
	Oct: "Oct",
	Nov: "Nov",
	Dec: "Déc",
	Pharmacy: "Pharmacie",
	"Pharmacy (Located outside OFC)": "Pharmacie (située à l’extérieur du OFC)",
	"Pharmacy name is required": "Le nom de la pharmacie est requis",
	"Pharmacy Name": "Nom de la pharmacie",
	Sun: "Soleil",
	Mon: "Lun",
	Tue: "Mar",
	Wed: "Se sont produits",
	Thu: "Jeu",
	Fri: "Vendredi",
	Sat: "Sam",
	Assigned: "Affecté",
	"Password attempts exceeded": "Dépassement des tentatives de mot de passe",
	Days: "Jours",
	Day: "Jour",
	"Health Form": "Formulaire de santé",
	"Consent-Form-Template": "Modèle-formulaire-consentement",
	"consent-form template-copy": "modèle-copie du formulaire de consentement",
	"Video Chat Link": "Lien de chat vidéo",
	"Notes saved!": "Notes enregistrées !",
	"PreAuthentication failed with error this user does not exist":
		"PréAuthentication a échoué avec erreur cet utilisateur n’existe pas",
	"Fertility Benefits or Health Benefits Provider":
		"Fournisseur d’avantages de fertilité ou d’avantages pour la santé",
	"Email does not exist": "Le courrier électronique n’existe pas",
	"has changed the status of Consents":
		"a changé le statut des consentements",
	"Move to Patient": "Passer au patient",
	"Secondary Phone number is required":
		"Un numéro de téléphone secondaire est requis",
	"Secondary Phone": "Téléphone secondaire",
	"Cell Phone": "Téléphone cellulaire",
	"Cell Phone is required": "Téléphone cellulaire est requis",
	"Cell Phone is not a valid US/Canadian number":
		"Le téléphone cellulaire n’est pas un numéro américain/canadien valide",
	"Cell Phone is already taken": "Téléphone cellulaire est déjà pris",
	"Family Physician or Nurse Practitioner":
		"Médecin de famille ou infirmière praticienne",
	Feet: "Pieds",
	Inches: "Pouces",
	Centimeter: "Centimètre",
	"Feet/Inches": "Pieds/pouces",
	Cm: "Cm",
	"Feet cannot be 0": "Les pieds ne peuvent pas être 0",
	"Height should be between 1ft and 15ft":
		"La hauteur doit être comprise entre 1 pied et 15 pieds",
	"Height should be between 30cm and 457cm":
		"La hauteur doit être comprise entre 30cm et 457cm",
	"Please enter a value less than 10": "Entrez une valeur inférieure à 10",
	"I don't have a family physician.": "Je n’ai pas de médecin de famille.",
	Consents: "Consentements",
	"Whitespaces are not allowed.": "Les espaces ne sont pas autorisés.",
	"Method of contact is required.": "La méthode de contact est requise.",
	"New Patient is required.": "Un nouveau patient est requis.",
	"First name is required.": "Le prénom est requis.",
	"Referral Date is required.": "La date de référence est requise.",
	"Care Coordinator is required.": "Un coordonnateur des soins est requis.",
	"Preferred Location is required.": "L’emplacement préféré est requis.",
	"Cell Phone is required.": "Le téléphone cellulaire est requis.",
	"Suite, Apt, Unit, Building": "Suite, Apt, Unité, Bâtiment",
	"Lead Address": "Adresse principale",
	"Patient Address": "Adresse du patient",
	"Purpose of visit": "Motif de la visite",
	"Version code": "Code de version",
	"health card": "carte d’assurance-maladie",
	"Select Priority": "Sélectionnez Priorité",
	"Exclude Opt-Out": "Exclure la désinscription",
	"Select Campaign": "Sélectionner une campagne",
	"Last Segment Status": "État du dernier segment",
	"Doctor Repository": "Répertoire des médecins",
	"Add a New Campaigns": "Ajouter une nouvelle campagne",
	"Form Submitted": "Formulaire soumis",
	"Virtual Care Provider": "Fournisseur de soins virtuels",
	"Received On": "Date de réception",
	"Add a Note": "Ajouter une note",
	"Oops! Something went wrong": "Oups ! Quelque chose s’est mal passé",
	"Please try refreshing the page. If the issue persists, contact support for assistance":
		"Veuillez essayer d’actualiser la page. Si le problème persiste, contactez le support technique pour obtenir de l’aide",
	"Back to home": "Retour à la maison",
	"Input should be 15 or less": "L’entrée doit être de 15 ou moins",
	"New password must be different from the current password":
		"Le nouveau mot de passe doit être différent du mot de passe actuel",
	"The patient has signed up; this field cannot be empty.":
		"Le patient s’est inscrit ; ce champ ne peut pas être vide.",
	"The lead has signed up; this field cannot be empty.":
		"Le responsable s’est inscrit ; ce champ ne peut pas être vide.",
	"Input must be at least 2 characters long":
		"L’entrée doit être d’au moins 2 caractères",
	"Input must be no more than 25 characters":
		"L’entrée ne doit pas avoir plus de 25 caractères",
	Teams: "Équipes",
	"Add Professional Referral": "Ajouter une référence professionnelle",
	"Professional Referral": "Référence professionnelle",
	"Yes, delete this campaign": "Oui, supprimez cette campagne",
	"Invalid Postal/Zip Code": "Code postal invalide",
	None: "Aucune",
	"Invitation sent to": "Invitation envoyée au",
	successfully: "succès",
	"Input cannot be empty or contain only spaces.":
		"L'entrée ne peut pas être vide ou contenir seulement des espaces.",
	"Use the form below to change the password for your":
		"Utilisez le formulaire ci-dessous pour changer le mot de passe de votre",
	account: "compte",
	CNP_PatientsNavigator: "CNP_PatientsNavigator",
	"Transgender or non binary is required":
		"Transgenre ou non binaire est requis",
	"Transgender or non-binary": "Transgenre ou non-binaire",
	"The patient will be notified about the updates via email.":
		"Le patient sera informé des mises à jour par e-mail.",
	"Do you want to update the patient’s consent?":
		"Voulez-vous mettre à jour le consentement du patient ?",
	"Month, Year": "Mois, Année",
};

export default frTranslations;
