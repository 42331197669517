const enTranslations = {
	Next: "Next",
	"Add document": "Add document",
	"Add Document": "Add Document",
	"All right reserved": "All right reserved",
	"All Right Reserved": "All Right Reserved",
	"Privacy Policy": "Privacy Policy",
	"PRIVACY POLICY": "PRIVACY POLICY",
	Inbox: "Inbox",
	Archived: "Archived",
	Dashboard: "Dashboard",
	Referrals: "Referrals",
	"In progress": "In progress",
	"In Progress": "In Progress",
	"Same-sex relationship": "Same-sex relationship",
	Patients: "Patients",
	"Public Funded Waitlist": "Public Funded Waitlist",
	"Referring Physician": "Referring Physician",
	Team: "Team",
	"We are here to help you": "We are here to help you",
	"New Referral": "New Referral",
	"My Account": "My Account",
	Previous: "Previous",
	"Male/Female relationship": "Male/Female relationship",
	"Virtual care provider": "Virtual care provider",
	"Rate your experience?": "Rate your experience?",
	"Rate your experience": "Rate your experience",
	"Please rate your overall experience":
		"Please rate your overall experience",
	"Need Help": "Need Help",
	"Need help": "Need help",
	Hi: "Hi",
	Leads: "Leads",
	Lead: "Lead",
	Name: "Name",
	name: "name",
	"Referral Date": "Referral Date",
	"Entry Date": "Entry Date",
	Source: "Source",
	"Type of Referral": "Type of Referral",
	"Referral name": "Referral name",
	Phone: "Phone",
	phone: "phone",
	Email: "Email",
	email: "email",
	None: "None",
	All: "All",
	"Send invitation": "Send invitation",
	"Form submitted": "Form submitted",
	Appointments: "Appointments",
	Priority: "Priority",
	Doctor: "Doctor",
	Waitlist: "Waitlist",
	Active: "Active",
	"Doctor repository": "Doctor repository",
	Campaign: "Campaign",
	Campaigns: "Campaigns",
	Filter: "Filter",
	"Assign Campaign": "Assign Campaign",
	"Assign campaign": "Assign campaign",
	"Mark as lead": "Mark as lead",
	"Add Physician": "Add Physician",
	"Contact Info": "Contact Info",
	Billing: "Billing",
	"New Member": "New Member",
	"Team Members": "Team Members",
	"Team members": "Team members",
	"Physician Name": "Physician Name",
	City: "City",
	"Last Segment status": "Last Segment status",
	"Assigned campaign": "Assigned campaign",
	"Assigned Campaign": "Assigned Campaign",
	Role: "Role",
	Status: "Status",
	"First Name": "First Name",
	"First name": "First name",
	"Last Name": "Last Name",
	"Last name": "Last name",
	Preferred: "Preferred",
	"Preferred Method of Contact": "Preferred Method of Contact",
	"Date Of Birth": "Date Of Birth",
	"Date of Birth": "Date of Birth",
	"Fertility Benefit Provider": "Fertility Benefit Provider",
	"I would like to help with": "I would like to help with",
	"Reason For Referral": "Reason For Referral",
	"Reason for Referral": "Reason for Referral",
	"New Patient": "New Patient",
	"Preferred Language": "Preferred Language",
	"Preferred language": "Preferred language",
	"Preferred Location": "Preferred Location",
	"Referred To": "Referred To",
	"Physician Referral": "Physician Referral",
	"Add file": "Add file",
	"or drop a file here": "or drop a file here",
	Notes: "Notes",
	"Lead Notes": "Lead Notes",
	"Consent History": "Consent History",
	"Add a note": "Add a note",
	Save: "Save",
	Cancel: "Cancel",
	Close: "Close",
	"Very High": "Very High",
	High: "High",
	Medium: "Medium",
	Low: "Low",
	"Very Low": "Very Low",
	Edit: "Edit",
	"Your Profile": "Your Profile",
	Logout: "Logout",
	"The address used to identify this account cannot be changed":
		"The address used to identify this account cannot be changed",
	"Change password": "Change password",
	"Change Password": "Change Password",
	Back: "Back",
	Password: "Password",
	"Confirm Password": "Confirm Password",
	"Show Password": "Show Password",
	"Receive insightful tips to support your fertility health.":
		"Receive insightful tips to support your fertility health.",
	"By creating an account": "By creating an account",
	"I agree to": "I agree to",
	"Terms & Conditions": "Terms & Conditions",
	"Privacy Policy.": "Privacy Policy.",
	"Use 8 or more characters with a mix of letters, numbers & symbols.":
		"Use 8 or more characters with a mix of letters, numbers & symbols.",
	"Use 8 or more characters with a mix of letters, numbers & symbols":
		"Use 8 or more characters with a mix of letters, numbers & symbols",
	"Forgot your password?": "Forgot your password?",
	"Sex assigned at birth": "Sex assigned at birth",
	"We're here to help you every step of the way":
		"We're here to help you every step of the way",
	Female: "Female",
	Male: "Male",
	"Relationship status": "Relationship status",
	"Relationship Status": "Relationship Status",
	"Relationship status?": "Relationship status?",
	Single: "Single",
	"Male / female relationship": "Male / female relationship",
	"Would you like to include your partner in your fertility journey at this time":
		"Would you like to include your partner in your fertility journey at this time",
	"Maybe later": "Maybe later",
	"What are your pronouns": "What are your pronouns",
	"He/Him": "He/Him",
	"She/Her": "She/Her",
	"They/Them": "They/Them",
	"Health card Number (optional)": "Health card Number (optional)",
	"Health Card Number (Optional)": "Health Card Number (Optional)",
	"Health Card Number": "Health Card Number",
	"Health Card Number*": "Health Card Number *",
	"Health Card Number is required": "Health Card Number is required",
	"Health Card Number must be 9 digits":
		"Health Card Number must be 9 digits",
	"Please enter health card number first":
		"Please enter health card number first",
	"Health Card Expiry is required": "Health Card Expiry is required",
	"Date-Month-Year": "Date-Month-Year",
	"Month, Day, Year": "Month, Day, Year",
	Address: "Address",
	"Address Line 2": "Address Line 2",

	"Address line 2": "Address line 2",
	"Address line 1": "Address line 1",
	"Appointment Seen": "Appointment Seen",
	Province: "Province",
	Country: "Country",
	"Postal/Zip Code": "Postal/Zip Code",
	"There are 2 tasks you'll need to complete before your first appointment.":
		"There are 2 tasks you'll need to complete before your first appointment.",
	"We look forward to seeing you and helping you take the next steps in your fertility journey.":
		"We look forward to seeing you and helping you take the next steps in your fertility journey.",
	"Task 1: Personal Information": "Task 1: Personal Information",
	"Task 2: Health History": "Task 2: Health History",
	"Fertility Center Location": "Fertility Center Location",
	"When you join a fertility center, you will be assigned to a specific location.":
		"When you join a fertility center, you will be assigned to a specific location.",
	"My Location": "My Location",
	"Change Location": "Change Location",
	"Select Location": "Select Location",
	"Send Request": "Send Request",
	"Connect with a Fertility Nurse": "Connect with a Fertility Nurse",
	"Connect with a Nurse": "Connect with a Nurse",
	"If you have any questions or need assistance, please don't hesitate to reach out.":
		"If you have any questions or need assistance, please don't hesitate to reach out.",
	"Here to Help": "Here to Help",
	"Nurse Name": "Nurse Name",
	"Available Hours": "Available Hours",
	"Send Message": "Send Message",
	"View Profile": "View Profile",
	Calendar: "Calendar",
	"Schedule an Appointment": "Schedule an Appointment",
	"Choose a Date & Time": "Choose a Date & Time",
	"Select Date": "Select Date",
	"Select Time": "Select Time",
	AM: "AM",
	PM: "PM",
	"Appointment Type": "Appointment Type",
	"Reason for Appointment": "Reason for Appointment",
	Duration: "Duration",
	"30 Minutes": "30 Minutes",
	"60 Minutes": "60 Minutes",
	"Appointment Date & Time": "Appointment Date & Time",
	"Confirm Appointment": "Confirm Appointment",
	"Appointment Confirmation": "Appointment Confirmation",
	"Your appointment has been confirmed for":
		"Your appointment has been confirmed for",
	with: "with",
	on: "on",
	"You will receive a confirmation email shortly.":
		"You will receive a confirmation email shortly.",
	"Manage Appointments": "Manage Appointments",
	"View All": "View All",
	"Upcoming Appointments": "Upcoming Appointments",
	"Appointment History": "Appointment History",
	"You have no upcoming appointments.": "You have no upcoming appointments.",
	"Your appointment history will be displayed here.":
		"Your appointment history will be displayed here.",
	"Appointment Details": "Appointment Details",
	"Appointment ID": "Appointment ID",
	Date: "Date",
	Time: "Time",
	Location: "Location",
	"Care-Coordinator": "Care Coordinator",
	Reason: "Reason",
	"Set Priority": "Set Priority",
	Completed: "Completed",
	Cancelled: "Cancelled",
	Rescheduled: "Rescheduled",
	"View All Notes": "View All Notes",
	"Add a New Note": "Add a New Note",
	"Your notes will be displayed here.": "Your notes will be displayed here.",
	"Enter your note here...": "Enter your note here...",
	"Patient Information": "Patient Information",
	"Emergency Contact Information": "Emergency Contact Information",
	"Emergency Contact Name": "Emergency Contact Name",
	"Emergency Contact Relationship": "Emergency Contact Relationship",
	"Emergency Contact Phone": "Emergency Contact Phone",
	"Insurance Information": "Insurance Information",
	"Primary Insurance Provider": "Primary Insurance Provider",
	"Policy Holder Name": "Policy Holder Name",
	"Policy Number": "Policy Number",
	"Group Number": "Group Number",
	"Secondary Insurance Provider": "Secondary Insurance Provider",
	Prediagnostic: "Prediagnostic",
	"Employment Information": "Employment Information",
	"Employment Status": "Employment Status",
	"Employer Name": "Employer Name",
	Occupation: "Occupation",
	"Work Phone": "Work Phone",
	"School Information": "School Information",
	"School Name": "School Name",
	"Grade Level": "Grade Level",
	"School Phone": "School Phone",
	"Referred By": "Referred By",

	"How did you hear about us": "How did you hear about us",
	"Please select": "Please select",
	"Health Information": "Health Information",
	"Medical History": "Medical History",
	"Care Team": "Care Team",
	"Have you ever been diagnosed with a genetic disorder":
		"Have you ever been diagnosed with a genetic disorder",
	Yes: "Yes",
	No: "No",
	NO: "NO",
	"Please specify": "Please specify",
	"Have you ever had a genetic test": "Have you ever had a genetic test",
	"Last Contacted": "Last Contacted",
	"Do you have a family history of fertility issues":
		"Do you have a family history of fertility issues",
	"Are you currently taking any medications or supplements":
		"Are you currently taking any medications or supplements",
	"Report Day 1": "Report Day 1",
	"Are you currently experiencing any of the following":
		"Are you currently experiencing any of the following",
	"None of the above": "None of the above",
	"Referral Reason": "Referral Reason",
	"Have you ever been diagnosed with a sexually transmitted infection (STI)":
		"Have you ever been diagnosed with a sexually transmitted infection (STI)",
	" Documents": " Documents",
	Lifestyle: "Lifestyle",
	"Test requisitions": "Test requisitions",
	"Tobacco Use": "Tobacco Use",
	"Alcohol Consumption": "Alcohol Consumption",
	"Substance Use": "Substance Use",
	"Appointment Booked": "Appointment Booked",
	"Exercise Habits": "Exercise Habits",
	"New appointment": "New appointment",
	"Dietary Preferences": "Dietary Preferences",
	"Additional Information": "Additional Information",
	"Is there anything else you would like to share about your health or fertility journey":
		"Is there anything else you would like to share about your health or fertility journey",
	"Your answers have been saved.": "Your answers have been saved.",
	"Back to Dashboard": "Back to Dashboard",
	"Task 3: Fertility Assessment": "Task 3: Fertility Assessment",
	"Understand Your Fertility": "Understand Your Fertility",
	"Contact Attempts": "Contact Attempts",
	"Contact atempts": "Contact atempts",
	Unassigned: "Unassigned",
	Regular: "Regular",
	"No Partner": "No Partner",
	"No Partner Invited": "No Partner Invited",
	"User successfully deleted": "User successfully deleted",
	"Patient Name": "Patient Name",
	"Patient name": "Patient name",
	"Add A New Campaigns": "Add A New Campaigns",
	"Start Date Is Required": "Start Date Is Required",
	"Name Is Required": "Name Is Required",
	"Start Date is invalid": "Start Date is invalid",
	"Pending Invitation": "Pending Invitation",
	Disable: "Disable",
	"Pending Invite": "Pending Invite",
	"Edit Referrals": "Edit Referrals",

	"Referral To": "Referral To",
	"Care Coordinator is required": "Care Coordinator is required",
	Optional: "Optional",
	"Priority must be selected": "Priority must be selected",
	Loading: "Loading",
	"First name is required": "First name is required",
	"This field is required.": "This field is required.",
	"This field is required": "This field is required",
	"Last name is required.": "Last name is required.",
	"Last name is required": "Last name is required",
	"Email is invalid": "Email is invalid",
	"Email is already taken": "Email is already taken",
	"Phone number is required": "Phone number is required",
	"Invalid phone number": "Invalid phone number",
	"Phone number is already taken": "Phone number is already taken",
	"Date of birth is invalid": "Date of birth is invalid",
	"Date Of Birth cannot be less than 10 years":
		"Date Of Birth cannot be less than 10 years",
	"Preferred location is required": "Preferred location is required",
	"Preferred Location is required": "Preferred Location is required",
	"Please Select a physician": "Please Select a physician",
	"Please Enter Some Text": "Please Enter Some Text",
	"Primary Patient": "Primary Patient",
	"Add Inactive Partner": "Add Inactive Partner",
	Pronouns: "Pronouns",
	"Health Card Expiry is invalid": "Health Card Expiry is invalid",
	"Health Card Expiry": "Health Card Expiry",
	"Same as primary": "Same as primary",
	"Same as Primary": "Same as Primary",
	"Street Address": "Street Address",
	Region: "Region",
	"Move To Active Partner": "Move To Active Partner",
	"Olivia Chloe": "Olivia Chloe",
	Primary: "Primary",
	"Diagnosed with endometriosis": "Diagnosed with endometriosis",
	Contact: "Contact",
	"Phone Call": "Phone Call",
	"General Practitioners": "General Practitioners",
	REI: "REI",
	"Select the test type": "Select the test type",
	"Add Test requisitions": "Add Test requisitions",
	Unarchive: "Unarchive",
	"Archive patient": "Archive patient",
	Profile: "Profile",
	Doctors: "Doctors",
	"Referring Doctor": "Referring Doctor",
	"Referring doctor": "Referring doctor",
	"Added To IVF Funded": "Added To IVF Funded",
	"Remove From Public": "Remove From Public",
	"Funded Waitlist": "Funded Waitlist",
	"Add to public funded": "Add to public funded",
	"Health form": "Health form",
	Submitted: "Submitted",
	"Consent Form": "Consent Form",
	"No Appointment Scheduled": "No Appointment Scheduled",
	"No appointment scheduled": "No appointment scheduled",
	"You are about to unarchive the records of":
		"You are about to unarchive the records of",
	and: "and",
	"Patient records will be moved To th Previous stage":
		"Patient records will be moved To th Previous stage",
	Ok: "Ok",
	"Please select a priority": "Please select a priority",
	Appointment: "Appointment",
	"Virtual Care provider is required": "Virtual Care provider is required",
	"Received on is required": "Received on is required",
	"Received on is invalid": "Received on is invalid",
	"Please cancel your appointments first then unmerge the partner":
		"Please cancel your appointments first then unmerge the partner",
	"Resend Email": "Resend Email",
	"Pre-diagnostic form": "Pre-diagnostic form",
	"Todays appointment": "Todays appointment",
	"Join Meeting": "Join Meeting",
	"Please unmerge the partner before changing the relationship status to single":
		"Please unmerge the partner before changing the relationship status to single",
	"Edit partner information": "Edit partner information",
	"Edit lead information": "Edit lead information",
	"Email is required": "Email is required",
	"Referral date is required": "Referral date is required",
	"Referral Date is required": "Referral Date is required",
	"Referral date is invalid": "Referral date is invalid",
	"Referral date cannot be after today's date":
		"Referral date cannot be after today's date",
	"Sex At Birth": "Sex At Birth",
	Maybe: "Maybe",
	Unmerge: "Unmerge",
	"Reason for Referral is required": "Reason for Referral is required",
	"Reason For referral is required": "Reason For referral is required",
	"New patient is required": "New patient is required",
	"Save & Send Invitation": "Save & Send Invitation",
	"Sex At Birth is required": "Sex At Birth is required",
	"Two Step verification": "Two Step verification",
	"Initial Contact Channel": "Initial Contact Channel",
	"Your response has been submitted": "Your response has been submitted",
	Share: "Share",
	"Invitation has been sent to prospects":
		"Invitation has been sent to prospects",
	"No leads selected": "No leads selected",
	"Edit profile information": "Edit profile information",
	"Patient Todo Tasks": "Patient Todo Tasks",
	"Partner Todo Tasks": "Partner Todo Tasks",
	"Not Completed": "Not Completed",
	"Resend invitation": "Resend invitation",
	"Invite Partner": "Invite Partner",
	assign: "assign",
	Assign: "Assign",
	Physician: "Physician",
	"Received on": "Received on",
	"Source is required.": "Source is required.",
	"EMR Patient ID": "EMR Patient ID",
	Apt: "Apt",
	"Concent Form": "Concent Form",
	"Write a note": "Write a note",
	"Partner Invited": "Partner Invited",
	"Merge Existing": "Merge Existing",
	"Partner Not Invited": "Partner Not Invited",
	"Edit Patient information": "Edit Patient information",
	"Edit Partner": "Edit Partner",
	Unisex: "Unisex",
	"Invite lead/patient": "Invite lead/patient",
	"Date of Appointment": "Date of Appointment",
	From: "From",
	"Video chat link": "Video chat link",
	"If the email is in our system, you'll receive a password reset link":
		"If the email is in our system, you'll receive a password reset link",
	"Reset password": "Reset password",
	"Enter an email associated with your account and we will send an email with instructions to reset your password":
		"Enter an email associated with your account and we will send an email with instructions to reset your password",
	"Enter Email": "Enter Email",
	"We have sent you an email with instructions to reset your password":
		"We have sent you an email with instructions to reset your password",
	"Did not receive your email? Check your spam folder":
		"Did not receive your email? Check your spam folder",
	"Did not receive your email?": "Did not receive your email?",
	"Did not receive your email": "Did not receive your email",
	"If you didn't receive an email": "If you didn't receive an email",
	"Resend code": "Resend code",
	"8 characters minimum": "8 characters minimum",
	"Password must contain at least one number":
		"Password must contain at least one number",
	"Password must contain at least one special character":
		"Password must contain at least one special character",
	"Password is required": "Password is required",
	"Confirm Password is required": "Confirm Password is required",
	"Both passwords must match": "Both passwords must match",
	"Password requirements do not meet": "Password requirements do not meet",
	"Are you sure you want to mark the health form as completed":
		"Are you sure you want to mark the health form as completed",
	"This will render the patient unable to make any changes to their health form":
		"This will render the patient unable to make any changes to their health form",
	"Please select an answer": "Please select an answer",
	"Please provide details": "Please provide details",
	"Please enter a value greater than zero":
		"Please enter a value greater than zero",
	"Something went wrong": "Something went wrong",
	"Patient health history": "Patient health history",
	"Mark As Completed": "Mark As Completed",
	"Download PDF": "Download PDF",
	"New Password": "New Password",
	"Use the form below to reset the password for your account":
		"Use the form below to reset the password for your account",
	"Use the form below to change the password for your CLINIC_NAME account":
		"Use the form below to change the password for your CLINIC_NAME account",
	"Logged in with email": "Logged in with email",
	Add: "Add",
	"Add partner": "Add partner",
	"Add Partner": "Add Partner",
	"Password Expired": "Password Expired",
	"Your password has expired": "Your password has expired",
	"To reset your password, please click on the":
		"To reset your password, please click on the",
	"Reset Button": "Reset Button",
	"You will not be able to revert": "You will not be able to revert",
	"Are you sure you want to": "Are you sure you want to",
	"remove this user": "remove this user",
	"Yes, delete this user": "Yes, delete this user",
	"Enter Your Registered ID": "Enter Your Registered ID",
	"Billing Number": "Billing Number",
	"Enter Your Billing Number": "Enter Your Billing Number",
	"Reg ID": "Reg ID",
	"Area(s) of SPEC": "Area(s) of SPEC",
	"Total Referrals": "Total Referrals",
	"First Date of Referral": "First Date of Referral",
	"Last Date of Referral": "Last Date of Referral",
	"Opt out": "Opt out",
	"Opt Out": "Opt Out",
	"Edit Physician": "Edit Physician",
	"Registered ID": "Registered ID",
	"Invalid Number": "Invalid Number",
	"Previous campaigns": "Previous campaigns",
	"Exclude Opt Out": "Exclude Opt Out",
	"Physician Within Radius By Clinic Location":
		"Physician Within Radius By Clinic Location",
	Clinic: "Clinic",
	Patient: "Patient",
	"Please Provide A Clinic": "Please Provide A Clinic",
	Distance: "Distance",
	Km: "Km",
	"Referral received during a defined period of time":
		"Referral received during a defined period of time",
	"Please Provide Start Date": "Please Provide Start Date",
	"Start Date Cannot Be After End Date":
		"Start Date Cannot Be After End Date",
	"Start Date": "Start Date",
	"Please Provide End Date": "Please Provide End Date",
	"End Date Cannot Be Before Start Date":
		"End Date Cannot Be Before Start Date",
	"End Date": "End Date",
	"Please Provide Minimum Referrals": "Please Provide Minimum Referrals",
	"Minimum referrals": "Minimum referrals",
	"Filter by Segment status": "Filter by Segment status",
	"Please Provide Year": "Please Provide Year",
	"Please Provide Month": "Please Provide Month",
	"Please Provide Status": "Please Provide Status",
	"Filter by Contact info": "Filter by Contact info",
	Contains: "Contains",
	Apply: "Apply",
	"Clear All": "Clear All",
	"New Campaign": "New Campaign",
	"End date cannot be before or same to start date":
		"End date cannot be before or same to start date",
	"Target audience is required": "Target audience is required",
	"Target Audience": "Target Audience",
	"Campaign Channel": "Campaign Channel",
	"Campaign channel is required": "Campaign channel is required",
	"Campaign info is required": "Campaign info is required",
	"Edit Campaign": "Edit Campaign",
	"Start date is required": "Start date is required",
	"End date is required": "End date is required",
	"End Date is invalid": "End Date is invalid",
	"Select campaign": "Select campaign",
	"remove this campaign": "remove this campaign",
	"No Physicians can be marked as lead":
		"No Physicians can be marked as lead",
	"Are you sure you want to Mark these Items as Lead":
		"Are you sure you want to Mark these Items as Lead",
	"Selected physicians will be marked as lead":
		"Selected physicians will be marked as lead",
	"Selected physicians already have a referral":
		"Selected physicians already have a referral",
	"Yes, Mark As Lead": "Yes, Mark As Lead",
	"Please select a campaign": "Please select a campaign",
	"Edit Referring Physician": "Edit Referring Physician",
	Remove: "Remove",
	"Token not found": "Token not found",
	"Passwords do not match": "Passwords do not match",
	"Password field empty": "Password field empty",
	"Create your account": "Create your account",
	"Confirm new password": "Confirm new password",
	"Confirm New Password": "Confirm New Password",
	"Create account": "Create account",
	"The address used to identify this": "The address used to identify this",
	"account cannot be changed": "account cannot be changed",
	Disabled: "Disabled",
	"Team member": "Team member",
	"disable this user": "disable this user",
	"The following email address will receive a link to create an account":
		"The following email address will receive a link to create an account",
	"Current password is required": "Current password is required",
	"Password length should be at least 4 characters":
		"Password length should be at least 4 characters",
	"Password is successfully updated": "Password is successfully updated",
	"Current Password is incorrect": "Current Password is incorrect",
	"Password Change limit reached. Please try after some time":
		"Password Change limit reached. Please try after some time",
	"Profile picture updated successfully":
		"Profile picture updated successfully",
	"Please select a file to upload": "Please select a file to upload",
	"Profile updated successfully": "Profile updated successfully",
	"Page Not Found": "Page Not Found",
	"Return Back": "Return Back",
	Test: "Test",
	"Please select a contact method": "Please select a contact method",
	"Forgot your password": "Forgot your password",
	"Incorrect OTP. You have": "Incorrect OTP. You have",
	"tries remaining": "tries remaining",
	"Your session has expired. Please login again":
		"Your session has expired. Please login again",
	"A verification code has been sent to":
		"A verification code has been sent to",
	"Check your Email and input the": "Check your Email and input the",
	"OTP is required": "OTP is required",
	"OTP must be 6 digits": "OTP must be 6 digits",
	Verify: "Verify",
	"If you didn't receive a code": "If you didn't receive a code",
	"Yes, archive patient": "Yes, archive patient",
	"Reason Is Required": "Reason Is Required",
	"Method of contact": "Method of contact",
	"Selection required": "Selection required",
	"Contact Resolution": "Contact Resolution",
	"New Referring Physician": "New Referring Physician",
	"Phone number has to be 10 digits minimum":
		"Phone number has to be 10 digits minimum",
	"Phone number can be 10 digits maximum":
		"Phone number can be 10 digits maximum",
	"Phone number can only include digits":
		"Phone number can only include digits",
	"Phone Number": "Phone Number",
	Fax: "Fax",
	"Add New Physician": "Add New Physician",
	"Are you sure you want to ": "Are you sure you want to ",
	remove: "remove",
	"Yes, delete archived": "Yes, delete archived",
	"delete this comment": "delete this comment",
	"Yes, delete this comment": "Yes, delete this comment",
	"Are you sure": "Are you sure",
	Move: "Move",
	"from Lead appointments to Patients": "from Lead appointments to Patients",
	"from Leads to Patients": "from Leads to Patients",
	"Please enter the patient‘s EMR ID to confirm this action. This cannot be undone":
		"Please enter the patient‘s EMR ID to confirm this action. This cannot be undone",
	"Primary Patient EMR ID is required": "Primary Patient EMR ID is required",
	"Primary Patient EMR ID": "Primary Patient EMR ID",
	"Partner EMR ID is required": "Partner EMR ID is required",
	"Partner EMR ID": "Partner EMR ID",
	Confirm: "Confirm",
	Partner: "Partner",
	"Please confirm that do you really want to move this partner as an Active Partner":
		"Please confirm that do you really want to move this partner as an Active Partner",
	"Date is required": "Date is required",
	"Date is invalid": "Date is invalid",
	"Yes, Unarchive": "Yes, Unarchive",
	"Are you sure you want to Unassign": "Are you sure you want to Unassign",
	"Are you sure you want to Unmerge": "Are you sure you want to Unmerge",
	"If you want to merge again, you can merge them in the Lead list":
		"If you want to merge again, you can merge them in the Lead list",
	"Are you sure you want to cancel": "Are you sure you want to cancel",
	"the appointment": "the appointment",
	"Canceling Reason is required": "Canceling Reason is required",
	"Other reason is required": "Other reason is required",
	"Please invite either primary patient or partner":
		"Please invite either primary patient or partner",
	"Please Select A Clinic Location": "Please Select A Clinic Location",
	"Clinic Locations": "Clinic Locations",
	"Please select appointment type first":
		"Please select appointment type first",
	"Profile incomplete. Missing to-do tasks":
		"Profile incomplete. Missing to-do tasks",
	"Appointment type": "Appointment type",
	"Appointment type is required": "Appointment type is required",
	"Visit type": "Visit type",
	"Visit type is required": "Visit type is required",
	"Please Select A Doctor": "Please Select A Doctor",
	"Date of Appointment is required": "Date of Appointment is required",
	"Date of Appointment is invalid": "Date of Appointment is invalid",
	"Appointment date must be greater than or equal to":
		"Appointment date must be greater than or equal to",
	"From Time is required": "From Time is required",
	"To Time is required": "To Time is required",
	"To time must be greater than from time":
		"To time must be greater than from time",
	"Copy Link": "Copy Link",
	"Link Copied successfully": "Link Copied successfully",
	"Save & send email": "Save & send email",
	"Cancel Appointment": "Cancel Appointment",
	English: "English",
	French: "French",
	"From time must be greater than": "From time must be greater than",
	"Password must meet the": "Password must meet the",
	"following requirements": "following requirements",
	"Send Email": "Send Email",
	"Confirm Invitations": "Confirm Invitations",
	"You‘re sending": "You‘re sending",
	"invitations via both sms and email": "invitations via both sms and email",
	"just by sms, correct": "just by sms, correct",
	"Check your Message and input the": "Check your Message and input the",
	"verification code below to Continue":
		"verification code below to Continue",
	"A verification code has been sent to your email":
		"A verification code has been sent to your email",
	"Enter Code": "Enter Code",
	"Password did not conform with policy: Password must have uppercase characters":
		"Password did not conform with policy: Password must have uppercase characters",
	"Update your password for your Generation Fertility account below":
		"Update your password for your Generation Fertility account below",
	"Address Information": "Address Information",
	"City is required": "City is required",
	"Province is required": "Province is required",
	"Country is required": "Country is required",
	"Postal/Zip Code is required": "Postal/Zip Code is required",
	"Contact Information": "Contact Information",
	"Phone number is not a valid US/Canadian number":
		"Phone number is not a valid US/Canadian number",
	"This phone number is not verified": "This phone number is not verified",
	Language: "Language",
	Notifications: "Notifications",
	"CLINIC_NAME may still send you important notifications about your account and content outside of your preferred notification settings":
		"CLINIC_NAME may still send you important notifications about your account and content outside of your preferred notification settings",
	"Send me notifications for": "Send me notifications for",
	"Task assignments": "Task assignments",
	Reminders: "Reminders",
	"No tasks have been completed yet": "No tasks have been completed yet",
	"Insightful Tips": "Insightful Tips",
	"Fertility health tips": "Fertility health tips",
	"Where you will be notified": "Where you will be notified",
	SMS: "SMS",
	"Preferred Name": "Preferred Name",
	"Preferred name": "Preferred name",
	"Security & Data": "Security & Data",
	"Account closure": "Account closure",
	Preferences: "Preferences",
	"Edit Address Information": "Edit Address Information",
	"Edit Contact Information": "Edit Contact Information",
	"Edit Profile": "Edit Profile",
	"Please enter valid date": "Please enter valid date",
	"Personal Info": "Personal Info",
	"Postal Code": "Postal Code",
	"Use the form below to change the password for your Generation fertility account":
		"Use the form below to change the password for your Generation fertility account",
	"Current password": "Current password",
	"You're amazing": "You're amazing",
	"All tasks complete": "All tasks complete",
	"Report the first day of your period":
		"Report the first day of your period",
	"Your estimated date must be confirmed by":
		"Your estimated date must be confirmed by",
	"Today's Appointment": "Today's Appointment",
	"Your upcoming appointments": "Your upcoming appointments",
	"will be listed here": "will be listed here",
	Article: "Article",
	"Logging out": "Logging out",
	"April 1, 2023": "April 1, 2023",
	"Last Updated": "Last Updated",
	"is committed to protecting the privacy and confidentiality of personal information in our custody and control. This Privacy Policy describes how personal information is collected, used, and disclosed in connection with the TFP Care Navigation platform (the)":
		"is committed to protecting the privacy and confidentiality of personal information in our custody and control. This Privacy Policy describes how personal information is collected, used, and disclosed in connection with the TFP Care Navigation platform (the)",
	"is committed to protecting the privacy and confidentiality of personal information in our custody and control. This Privacy Policy describes how personal information is collected, used, and disclosed in connection with the TFP Care Navigation platform":
		"is committed to protecting the privacy and confidentiality of personal information in our custody and control. This Privacy Policy describes how personal information is collected, used, and disclosed in connection with the TFP Care Navigation platform",
	us: "us",
	or: "or",
	our: "our",
	Platform: "Platform",
	"and website located at": "and website located at",
	"Table of Contents": "Table of Contents",
	"Collection and Use of Personal Information":
		"Collection and Use of Personal Information",
	"Information Collected Automatically":
		"Information Collected Automatically",
	"Disclosure of Personal Information": "Disclosure of Personal Information",
	"Third Party Links": "Third Party Links",
	Safeguards: "Safeguards",
	"Retention of Personal Information": "Retention of Personal Information",
	"Your Choices": "Your Choices",
	"Your Rights": "Your Rights",
	"Changes to This Privacy Policy": "Changes to This Privacy Policy",
	"Our Platform provides tools that allow a fertility clinic’s care coordinators":
		"Our Platform provides tools that allow a fertility clinic’s care coordinators",
	"and physicians or other healthcare professionals":
		"and physicians or other healthcare professionals",
	"Healthcare Professionals": "Healthcare Professionals",
	"collectively our": "collectively our",
	"to share fertility resources, to access practice management tools, and to provide fertility services to their patients":
		"to share fertility resources, to access practice management tools, and to provide fertility services to their patients",
	"ncluding management of the patient journey virtually. Our Platform also allows Patients to view information related to their fertility treatment assigned to them by Care Coordinators or Health Professionals and to communicate with their Care Coordinators or Health Professionals. CLINIC_NAME is not a medical or healthcare professional and does not provide fertility or other medical services to Patients. CLINIC_NAME provides the Platform to its Clients as a tool for them to service their Patients":
		"ncluding management of the patient journey virtually. Our Platform also allows Patients to view information related to their fertility treatment assigned to them by Care Coordinators or Health Professionals and to communicate with their Care Coordinators or Health Professionals. CLINIC_NAME is not a medical or healthcare professional and does not provide fertility or other medical services to Patients. CLINIC_NAME provides the Platform to its Clients as a tool for them to service their Patients",
	"We process Patient Data on behalf of our Clients as their service provider. Our Clients are accountable for the Patient Data that we process on their behalf. If you are a Patient of one of our Clients and you have questions about how your Patient Data is handled, please contact them directly":
		"We process Patient Data on behalf of our Clients as their service provider. Our Clients are accountable for the Patient Data that we process on their behalf. If you are a Patient of one of our Clients and you have questions about how your Patient Data is handled, please contact them directly",
	"In providing the Platform, we collect personal information relating to our Clients and their employees and independent contractors":
		"In providing the Platform, we collect personal information relating to our Clients and their employees and independent contractors",
	"Client Data": "Client Data",
	"We also collect personal information relating to our":
		"We also collect personal information relating to our",
	"Client’s Patients": "Client’s Patients",
	"Patient Data": "Patient Data",
	"We collect Patient Data on behalf of our Clients and in accordance with their instructions. The Patient Data we collect will depend on how our Client uses the Platform, but may include the following information about Patients":
		"We collect Patient Data on behalf of our Clients and in accordance with their instructions. The Patient Data we collect will depend on how our Client uses the Platform, but may include the following information about Patients",
	"Name and contact information (including mailing address, email address, and telephone numbe":
		"Name and contact information (including mailing address, email address, and telephone numbe",
	"Date of birth, gender, sex at birth, relationship status, and other demographic information":
		"Date of birth, gender, sex at birth, relationship status, and other demographic information",
	"Account information such as username and password":
		"Account information such as username and password",
	"Billing information including provincial/territorial health insurance plan (health card) number, private medical insurance details, and credit card information":
		"Billing information including provincial/territorial health insurance plan (health card) number, private medical insurance details, and credit card information",
	"Health information including medical history, family history, test results, reports, symptoms, diagnosis, treatments performed, prescriptions, appointment information, and consents provided":
		"Health information including medical history, family history, test results, reports, symptoms, diagnosis, treatments performed, prescriptions, appointment information, and consents provided",
	"Information about the Patient’s use of the Platform and interactions with Care Coordinators and Healthcare Professionals":
		"Information about the Patient’s use of the Platform and interactions with Care Coordinators and Healthcare Professionals",
	"Opinions and feedback shared with Clients":
		"Opinions and feedback shared with Clients",
	"Additional information Patients may choose to provide such as queries and requests":
		"Additional information Patients may choose to provide such as queries and requests",
	"We also collect certain Patient Data automatically (see “Information We Collect Automatically” section below)":
		"We also collect certain Patient Data automatically (see “Information We Collect Automatically” section below)",
	"We use Patient Data to provide the Platform to our Clients and otherwise in accordance with their instructions. We may also use Patient Data to generate anonymized data, which is not capable of identifying a Patient, whether directly or indirectly, and may use anonymized data to improve our products and services, for market research purposes, and for other lawful purposes":
		"We use Patient Data to provide the Platform to our Clients and otherwise in accordance with their instructions. We may also use Patient Data to generate anonymized data, which is not capable of identifying a Patient, whether directly or indirectly, and may use anonymized data to improve our products and services, for market research purposes, and for other lawful purposes",
	"The Client Data we collect includes the following information about the employees and independent contractors of our Clients":
		"The Client Data we collect includes the following information about the employees and independent contractors of our Clients",
	"Name and contact information": "Name and contact information",
	"Username and password": "Username and password",
	"Information about the use of the Platform":
		"Information about the use of the Platform",
	"Opinions, feedback, and other information that an employee or independent contractor may choose to provide":
		"Opinions, feedback, and other information that an employee or independent contractor may choose to provide",
	"We also collect certain Client Data automatically (see “Information We Collect Automatically” section below)":
		"We also collect certain Client Data automatically (see “Information We Collect Automatically” section below)",
	"We use Client Data to": "We use Client Data to",
	"Provide the Platform, including to create and manage Client accounts, provide customer service and support, and process payments":
		"Provide the Platform, including to create and manage Client accounts, provide customer service and support, and process payments",
	"Communicate with Clients and their employees and representatives respect of the Platform or other products, services and events that may be of interest":
		"Communicate with Clients and their employees and representatives respect of the Platform or other products, services and events that may be of interest",
	"Better understand how our Clients use the Platform in order to improve the Platform and develop new products or services":
		"Better understand how our Clients use the Platform in order to improve the Platform and develop new products or services",
	"Manage our business including to maintain business records for reasonable periods, enforce our agreements, and collect amounts owing to us":
		"Manage our business including to maintain business records for reasonable periods, enforce our agreements, and collect amounts owing to us",
	"Meet legal and regulatory obligations":
		"Meet legal and regulatory obligations",
	Continue: "Continue",
	"Please read over your form before submitting":
		"Please read over your form before submitting",
	"We may use Client Data for other purposes with consent or as otherwise permitted or required by law":
		"We may use Client Data for other purposes with consent or as otherwise permitted or required by law",
	"Information We Collect Automatically":
		"Information We Collect Automatically",
	"We use data collection tools to automatically collect information when individuals visit our Website or use our Platform":
		"We use data collection tools to automatically collect information when individuals visit our Website or use our Platform",
	"We use technologies like cookies and pixel tags to provide, monitor, analyze, and improve the Website and the Platform. For example, a cookie is used to remember your username when you return to the Website or the Platform and to improve our understanding of how you interact with the Website or Platform. At this time, we do not offer the ability to block cookies as key features of the Website and the Platform will not function properly if the ability to accept cookies is disabled":
		"We use technologies like cookies and pixel tags to provide, monitor, analyze, and improve the Website and the Platform. For example, a cookie is used to remember your username when you return to the Website or the Platform and to improve our understanding of how you interact with the Website or Platform. At this time, we do not offer the ability to block cookies as key features of the Website and the Platform will not function properly if the ability to accept cookies is disabled",
	"Log Files": "Log Files",
	"When you use the Website or the Platform, our servers automatically record certain information in server logs. These server logs may include information such as your web request, Internet Protocol":
		"When you use the Website or the Platform, our servers automatically record certain information in server logs. These server logs may include information such as your web request, Internet Protocol",
	"address, browser type, referring / exit pages and URLs, number of clicks and how you interact with content and links on the Website or the Platform, domain names, landing pages, pages viewed, mobile carrier, and other such information. Log files help us to monitor, analyze, improve and maintain the Website and the Platform and to diagnose and fix any service-related issues":
		"address, browser type, referring / exit pages and URLs, number of clicks and how you interact with content and links on the Website or the Platform, domain names, landing pages, pages viewed, mobile carrier, and other such information. Log files help us to monitor, analyze, improve and maintain the Website and the Platform and to diagnose and fix any service-related issues",
	"Device Identifiers": "Device Identifiers",
	"When you access the Website or the Platform using a mobile device, we collect specific device information contained in your mobile device’s “device identifier.” This device identifier includes information such as the type of device you are using, its operating system, and mobile network information, which may include your mobile phone number. We may associate this device identifier with your account and will use data associated with your device identifier to customize our Website or Platform to your device and to analyze any device related issues":
		"When you access the Website or the Platform using a mobile device, we collect specific device information contained in your mobile device’s “device identifier.” This device identifier includes information such as the type of device you are using, its operating system, and mobile network information, which may include your mobile phone number. We may associate this device identifier with your account and will use data associated with your device identifier to customize our Website or Platform to your device and to analyze any device related issues",
	"Location Information": "Location Information",
	"We may collect and process information about the location of the device from which you are accessing the Website or the Platform to provide certain location-based services. You can disable location-based services in settings associated with the Website or the Platform; however please be aware that some features of the Website or the Platform may not function properly if location services are turned off":
		"We may collect and process information about the location of the device from which you are accessing the Website or the Platform to provide certain location-based services. You can disable location-based services in settings associated with the Website or the Platform; however please be aware that some features of the Website or the Platform may not function properly if location services are turned off",
	Analytics: "Analytics",
	"We may use third party service providers like Google Analytics to collect information about your use of the Website or Platform, such as the features used and time spent on the Website or the Platform, to help us better understand our users and improve our Website and Platform. The information we gather is used in an aggregate, non-identifiable form. For information about information is processed in connection with Google Analytics please read the Google Analytics Policy here":
		"We may use third party service providers like Google Analytics to collect information about your use of the Website or Platform, such as the features used and time spent on the Website or the Platform, to help us better understand our users and improve our Website and Platform. The information we gather is used in an aggregate, non-identifiable form. For information about information is processed in connection with Google Analytics please read the Google Analytics Policy here",
	Clients: "Clients",
	"Our Clients have access to Patient Data relating to their Patients. We are not responsible for our Client’s handling of Patient Data. A Client’s privacy policy may allow a Patient to consent to providing TFP Care Navigation with Patient Data. If you are a Patient of one of our Clients and have questions about how your Patient Data is handled, please contact the applicable Client directly":
		"Our Clients have access to Patient Data relating to their Patients. We are not responsible for our Client’s handling of Patient Data. A Client’s privacy policy may allow a Patient to consent to providing TFP Care Navigation with Patient Data. If you are a Patient of one of our Clients and have questions about how your Patient Data is handled, please contact the applicable Client directly",
	"Service providers": "Service providers",
	"We may transfer (or otherwise make available) personal information to service providers (including affiliated companies acting in this capacity) who perform services on our behalf. For example, we may use service providers to store and process Patient Data and Client Data, provide back-up systems, process payments, or provide data analytics and marketing services. Personal information may be maintained and processed by our service providers within and outside of Canada":
		"We may transfer (or otherwise make available) personal information to service providers (including affiliated companies acting in this capacity) who perform services on our behalf. For example, we may use service providers to store and process Patient Data and Client Data, provide back-up systems, process payments, or provide data analytics and marketing services. Personal information may be maintained and processed by our service providers within and outside of Canada",
	"If TFP Care Navigation is sold, restructured, or involved in a similar business transaction, we may transfer your personal information to the parties involved in that transaction. We will ensure that your personal information is transferred in accordance with legal and regulatory obligations":
		"If TFP Care Navigation is sold, restructured, or involved in a similar business transaction, we may transfer your personal information to the parties involved in that transaction. We will ensure that your personal information is transferred in accordance with legal and regulatory obligations",
	"Disclosures authorized or required by law":
		"Disclosures authorized or required by law",
	"We may disclose your personal information where we are legally required or authorized to do so":
		"We may disclose your personal information where we are legally required or authorized to do so",
	"We may disclose your personal information in other circumstances with your consent":
		"We may disclose your personal information in other circumstances with your consent",
	"Disclosures with consent": "Disclosures with consent",
	"Disclosure of Anonymous Data": "Disclosure of Anonymous Data",
	"Not Started": "Not Started",
	"As noted above, we may use Patient Data to generate anonymized data, which is not capable of identifying a Patient, whether directly or indirectly. We may disclose and sell anonymous data to third parties for research and commercial purposes":
		"As noted above, we may use Patient Data to generate anonymized data, which is not capable of identifying a Patient, whether directly or indirectly. We may disclose and sell anonymous data to third parties for research and commercial purposes",
	"Our Website or Platform may contain links to other websites or online or digital services that we do not own or operate. We provide these links as a convenience to the user. We do not have any control over these websites or services and they are not subject to this Privacy Policy. We encourage you to read the privacy policy of every website or online or digital service you visit":
		"Our Website or Platform may contain links to other websites or online or digital services that we do not own or operate. We provide these links as a convenience to the user. We do not have any control over these websites or services and they are not subject to this Privacy Policy. We encourage you to read the privacy policy of every website or online or digital service you visit",
	"We have implemented reasonable physical, technological, and administrative security measures designed to protect the personal information in our custody or control against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. We limit access to personal information to our employees and service providers who require access in connection with their role or function. Each of our employees is responsible for maintaining the confidentiality of all personal information to which they have access. We keep our employees informed about our policies and procedures for protecting personal information. Despite these measures, since no system or security measure is completely secure, it is possible that personal information may be lost, stolen, or accessed without authorization":
		"We have implemented reasonable physical, technological, and administrative security measures designed to protect the personal information in our custody or control against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. We limit access to personal information to our employees and service providers who require access in connection with their role or function. Each of our employees is responsible for maintaining the confidentiality of all personal information to which they have access. We keep our employees informed about our policies and procedures for protecting personal information. Despite these measures, since no system or security measure is completely secure, it is possible that personal information may be lost, stolen, or accessed without authorization",
	"We retain personal information for as long as necessary for the fulfilment of the purposes for which it was collected, in accordance with prudent business practices on general document retention, or as otherwise required by law":
		"We retain personal information for as long as necessary for the fulfilment of the purposes for which it was collected, in accordance with prudent business practices on general document retention, or as otherwise required by law",
	"You may unsubscribe from promotional email communications that we send at any time by using the unsubscribe feature included in those messages. Please note that if you unsubscribe from promotional email communications you may still receive transactional or business-related communications from us":
		"You may unsubscribe from promotional email communications that we send at any time by using the unsubscribe feature included in those messages. Please note that if you unsubscribe from promotional email communications you may still receive transactional or business-related communications from us",
	"Employees and independent contractors of our Clients may have the right to request access to or correction of their personal information in our custody or control, by writing to us as described below. These rights are subject to applicable legal restrictions and we may take appropriate steps to verify identity before responding to the request. To exercise any of these rights, please contact us in writing using the contact information in the 'Contact' section below":
		"Employees and independent contractors of our Clients may have the right to request access to or correction of their personal information in our custody or control, by writing to us as described below. These rights are subject to applicable legal restrictions and we may take appropriate steps to verify identity before responding to the request. To exercise any of these rights, please contact us in writing using the contact information in the 'Contact' section below",
	"If you are a Patient of one of our Clients and would like to exercise your rights under applicable privacy laws, please contact the applicable Client directly":
		"If you are a Patient of one of our Clients and would like to exercise your rights under applicable privacy laws, please contact the applicable Client directly",
	"Changes to this Privacy Policy": "Changes to this Privacy Policy",
	"If we change our Privacy Policy, we will post those changes on this page to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it. Changes to this Privacy Policy are effective when they are posted on this page":
		"If we change our Privacy Policy, we will post those changes on this page to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it. Changes to this Privacy Policy are effective when they are posted on this page",
	"If you have questions about this Privacy Policy or how your personal information is handled by TFP Care Navigation, please contact the TFP Care Navigation Privacy Officer at":
		"If you have questions about this Privacy Policy or how your personal information is handled by TFP Care Navigation, please contact the TFP Care Navigation Privacy Officer at",
	"privacy@thefertilitypartners.com": "privacy@thefertilitypartners.com",
	"Token is Invalid": "Token is Invalid",
	"Communication Paused": "Communication Paused",
	"You will not receive emails from us until next month":
		"You will not receive emails from us until next month",
	"Go to website": "Go to website",
	"If you have any questions, comments, or notices regarding these Terms, please contact us at":
		"If you have any questions, comments, or notices regarding these Terms, please contact us at",
	"HOW TO CONTACT US": "HOW TO CONTACT US",
	"The following Sections survive the termination of these Terms of Use: Intellectual Property; Arbitration; Disclaimer and Limitation of Liability; Indemnification; Miscellaneous":
		"The following Sections survive the termination of these Terms of Use: Intellectual Property; Arbitration; Disclaimer and Limitation of Liability; Indemnification; Miscellaneous",
	"You acknowledge and agree that you have been provided with a copy of these Terms and ancillary documents in French prior to, or simultaneously, with these Terms and have elected to execute and be bound by these Terms and ancillary documents in English. Vous reconnaissez et convenez que vous avez reçu une copie de ces modalités d'utilisation et des documents y afférant en français avant ou en même temps que ces modalités d'utilisation et avez choisi d'accepter et d'être lié par ces modalités d'utilisation et les documents y afférant en anglais":
		"You acknowledge and agree that you have been provided with a copy of these Terms and ancillary documents in French prior to, or simultaneously, with these Terms and have elected to execute and be bound by these Terms and ancillary documents in English. Vous reconnaissez et convenez que vous avez reçu une copie de ces modalités d'utilisation et des documents y afférant en français avant ou en même temps que ces modalités d'utilisation et avez choisi d'accepter et d'être lié par ces modalités d'utilisation et les documents y afférant en anglais",
	"These Terms are between you and Generation Fertility only and no other parties, including any third party service providers. You agree that there are no third-party beneficiaries under these Terms":
		"These Terms are between you and Generation Fertility only and no other parties, including any third party service providers. You agree that there are no third-party beneficiaries under these Terms",
	"No waiver by us of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition. Our failure to insist upon or enforce strict performance of any provision of these Terms shall not be construed as a waiver of any right":
		"No waiver by us of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition. Our failure to insist upon or enforce strict performance of any provision of these Terms shall not be construed as a waiver of any right",
	"If any provision of these Terms is held to be unenforceable, the remaining Terms shall remain in full force and effect, and the unenforceable provision shall be replaced by an enforceable provision that comes closest to the intention underlying the unenforceable provision":
		"If any provision of these Terms is held to be unenforceable, the remaining Terms shall remain in full force and effect, and the unenforceable provision shall be replaced by an enforceable provision that comes closest to the intention underlying the unenforceable provision",
	"We reserve the right to seek all remedies available at law and in equity for violations of these Terms, including the right to remove your account and any contents generated by you through the Services, block your access to any or all aspects of the Services, and block IP addresses":
		"We reserve the right to seek all remedies available at law and in equity for violations of these Terms, including the right to remove your account and any contents generated by you through the Services, block your access to any or all aspects of the Services, and block IP addresses",
	"You represent and warrant that you shall comply with all laws and regulations that apply to your access and use of the Services, including, but not limited to, any applicable national laws that prohibit the export or transmission of technical data or software to certain territories or jurisdictions":
		"You represent and warrant that you shall comply with all laws and regulations that apply to your access and use of the Services, including, but not limited to, any applicable national laws that prohibit the export or transmission of technical data or software to certain territories or jurisdictions",
	"To the fullest extent permitted by applicable law, these Terms shall be governed by the laws of the Province of Ontario, Canada":
		"To the fullest extent permitted by applicable law, these Terms shall be governed by the laws of the Province of Ontario, Canada",
	MISCELLANEOUS: "MISCELLANEOUS",
	"To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold harmless us, our licensor, and our affiliates, and our respective officers, directors, employees, agents, and representatives from and against all losses, expenses, damages, and costs, including reasonable legal fees and court costs, resulting from any violation by you of these Terms or from our termination of your access to or use of the Services. Such termination will not affect either party's rights or obligations, which accrued before the termination":
		"To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold harmless us, our licensor, and our affiliates, and our respective officers, directors, employees, agents, and representatives from and against all losses, expenses, damages, and costs, including reasonable legal fees and court costs, resulting from any violation by you of these Terms or from our termination of your access to or use of the Services. Such termination will not affect either party's rights or obligations, which accrued before the termination",
	INDEMNIFICATION: "INDEMNIFICATION",
	"TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE REMEDY FOR DISSATISFACTION WITH (1) THE SERVICES, (2) THE PLATFORM WEBSITE, (3) INFORMATION CONTAINED WITHIN THE SERVICES OR THE PLATFORM WEBSITE, (4) ANY LINKED SITE, OR (5) ANY PRODUCT, IS TO STOP USING THE SERVICES, THE PLATFORM WEBSITE, AND/OR THOSE PRODUCTS. TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, GENERATION FERTILITY, ITS LICENSORS, AND THE APPLICABLE THIRD PARTY'S MAXIMUM LIABILITY TO YOU WITH RESPECT TO YOUR USE OF THE SERVICES, THE PLATFORM WEBSITE, AND ANY PRODUCTS PURCHASED BY YOU THROUGH THE SERVICES IS THE EXTENT OF ACTUAL DAMAGES INCURRED BY YOU, NOT TO EXCEED $1000. THE FOREGOING LIMITATIONS APPLY EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE":
		"TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE REMEDY FOR DISSATISFACTION WITH (1) THE SERVICES, (2) THE PLATFORM WEBSITE, (3) INFORMATION CONTAINED WITHIN THE SERVICES OR THE PLATFORM WEBSITE, (4) ANY LINKED SITE, OR (5) ANY PRODUCT, IS TO STOP USING THE SERVICES, THE PLATFORM WEBSITE, AND/OR THOSE PRODUCTS. TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, GENERATION FERTILITY, ITS LICENSORS, AND THE APPLICABLE THIRD PARTY'S MAXIMUM LIABILITY TO YOU WITH RESPECT TO YOUR USE OF THE SERVICES, THE PLATFORM WEBSITE, AND ANY PRODUCTS PURCHASED BY YOU THROUGH THE SERVICES IS THE EXTENT OF ACTUAL DAMAGES INCURRED BY YOU, NOT TO EXCEED $1000. THE FOREGOING LIMITATIONS APPLY EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE",
	"YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT WILL GENERATION FERTILITY, ITS LICENSORS OR APPLICABLE THIRD PARTIES OR THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL LOSSES OR DAMAGES OF WHATSOEVER KIND ARISING OUT OF ACCESS TO OR USE OF THE WEBSITE, SERVICES, PRODUCTS, OR ANY INFORMATION, CONTENT OR MATERIALS INCLUDED ON WEBSITE":
		"YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT WILL GENERATION FERTILITY, ITS LICENSORS OR APPLICABLE THIRD PARTIES OR THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL LOSSES OR DAMAGES OF WHATSOEVER KIND ARISING OUT OF ACCESS TO OR USE OF THE WEBSITE, SERVICES, PRODUCTS, OR ANY INFORMATION, CONTENT OR MATERIALS INCLUDED ON WEBSITE",
	"TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DO NOT WARRANT THAT ACCESS TO OR USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SERVICES WILL BE CORRECTED. THE SERVICES ARE PROVIDED BY GENERATION FERTILITY AND APPLICABLE THIRD PARTIES ON AN AS IS AND AS AVAILABLE BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EACH OF GENERATION FERTILITY, ITS LICENSORS, AND APPLICABLE THIRD PARTIES MAKES NO REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, EXPRESS, LEGAL OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, COVENANTS, DURABILITY, NON-INFRINGEMENT, SATISFACTORY QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO THE SERVICE OR ANY INFORMATION, CONTENT, PRODUCT, OR OTHER MATERIAL PROVIDED ON OR THROUGH THE SERVICES, EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW":
		"TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DO NOT WARRANT THAT ACCESS TO OR USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SERVICES WILL BE CORRECTED. THE SERVICES ARE PROVIDED BY GENERATION FERTILITY AND APPLICABLE THIRD PARTIES ON AN AS IS AND AS AVAILABLE BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EACH OF GENERATION FERTILITY, ITS LICENSORS, AND APPLICABLE THIRD PARTIES MAKES NO REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, EXPRESS, LEGAL OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, COVENANTS, DURABILITY, NON-INFRINGEMENT, SATISFACTORY QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO THE SERVICE OR ANY INFORMATION, CONTENT, PRODUCT, OR OTHER MATERIAL PROVIDED ON OR THROUGH THE SERVICES, EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW",
	"SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. TO THE EXTENT THAT WE MAY NOT DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY OR LIMIT ITS LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY AND THE EXTENT OF OUR LIABILITY WILL BE THE MINIMUM PERMITTED UNDER APPLICABLE LAW":
		"SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. TO THE EXTENT THAT WE MAY NOT DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY OR LIMIT ITS LIABILITIES, THE SCOPE AND DURATION OF SUCH WARRANTY AND THE EXTENT OF OUR LIABILITY WILL BE THE MINIMUM PERMITTED UNDER APPLICABLE LAW",
	"DISCLAIMER AND LIMITATION OF LIABILITY":
		"DISCLAIMER AND LIMITATION OF LIABILITY",
	"Certain parts of the Services, including the Platform Website, may contain links to third-party websites or may give you access to applications, software, or products provided by third parties. Please understand that those third-party websites and products may have different terms of use and privacy policies, and that Generation Fertility does not control and is not responsible for the content of such websites or the privacy practices or products of such third parties. The information collected by such third-party websites is not covered by the Privacy Policy":
		"Certain parts of the Services, including the Platform Website, may contain links to third-party websites or may give you access to applications, software, or products provided by third parties. Please understand that those third-party websites and products may have different terms of use and privacy policies, and that Generation Fertility does not control and is not responsible for the content of such websites or the privacy practices or products of such third parties. The information collected by such third-party websites is not covered by the Privacy Policy",
	"LINKS TO THIRD PARTY SITES": "LINKS TO THIRD PARTY SITES",
	"To the fullest extent permissible by law, you and Generation Fertility agree that any dispute or claim arising from or relating to these Terms, the Services and the Platform Website shall be finally settled by final and binding arbitration, using the English language, administered by the Canadian Arbitration Association (the “CAA”) under its Arbitration Rules (the “CAA Rules”) then in effect (those rules are deemed to be incorporated by reference into this section, and as of the date of these Terms you can find the CAA here), unless otherwise required by law. Arbitration, including threshold questions of arbitrability of the dispute, will be handled by a sole arbitrator in accordance with those rules. Judgment on the arbitration award may be entered in any court that has jurisdiction. To the fullest extent permissible by law, any arbitration under these Terms will take place on an individual basis — class arbitrations and class actions are not permitted. You understand that by agreeing to these Terms, you and Generation Fertility are each waiving the right to trial by jury or to participate in a class action or class arbitration. Notwithstanding the foregoing, each party shall have the right to bring an action in a court of proper jurisdiction for injunctive or other equitable or conservatory relief, pending a final decision by the arbitrator":
		"To the fullest extent permissible by law, you and Generation Fertility agree that any dispute or claim arising from or relating to these Terms, the Services and the Platform Website shall be finally settled by final and binding arbitration, using the English language, administered by the Canadian Arbitration Association (the “CAA”) under its Arbitration Rules (the “CAA Rules”) then in effect (those rules are deemed to be incorporated by reference into this section, and as of the date of these Terms you can find the CAA here), unless otherwise required by law. Arbitration, including threshold questions of arbitrability of the dispute, will be handled by a sole arbitrator in accordance with those rules. Judgment on the arbitration award may be entered in any court that has jurisdiction. To the fullest extent permissible by law, any arbitration under these Terms will take place on an individual basis — class arbitrations and class actions are not permitted. You understand that by agreeing to these Terms, you and Generation Fertility are each waiving the right to trial by jury or to participate in a class action or class arbitration. Notwithstanding the foregoing, each party shall have the right to bring an action in a court of proper jurisdiction for injunctive or other equitable or conservatory relief, pending a final decision by the arbitrator",
	ARBITRATION: "ARBITRATION",
	"Electronic communication via text (SMS) messaging or email is critical to the functionality of the Platform and the performance of the Services. If you opt-out of both email and text (SMS) communications, you will not be able to continue to use the Platform and the Services will terminate":
		"Electronic communication via text (SMS) messaging or email is critical to the functionality of the Platform and the performance of the Services. If you opt-out of both email and text (SMS) communications, you will not be able to continue to use the Platform and the Services will terminate",
	"You may choose to opt-out of receiving email messages by updating your preferences in the Services or following the opt-out instructions in the message. You may opt-out of receiving email communication directly relating to provision of the Services or other types of messages, including marketing announcements. If you opt-out, Generation Fertility will not send another message to you unless/until you expressly opt in to receiving additional messages. You acknowledge that opting out of receiving email messages may impact your experience with the Service(s) that rely on communications via e-mail":
		"You may choose to opt-out of receiving email messages by updating your preferences in the Services or following the opt-out instructions in the message. You may opt-out of receiving email communication directly relating to provision of the Services or other types of messages, including marketing announcements. If you opt-out, Generation Fertility will not send another message to you unless/until you expressly opt in to receiving additional messages. You acknowledge that opting out of receiving email messages may impact your experience with the Service(s) that rely on communications via e-mail",
	"Message and data rates may apply. Your wireless carrier’s standard messaging rates apply to SMS correspondence. Downloadable content may incur additional charges from your wireless carrier. Please contact your wireless carrier for information about charges in your plan. Carriers are not responsible for delayed or undelivered messages":
		"Message and data rates may apply. Your wireless carrier’s standard messaging rates apply to SMS correspondence. Downloadable content may incur additional charges from your wireless carrier. Please contact your wireless carrier for information about charges in your plan. Carriers are not responsible for delayed or undelivered messages",
	"You may choose to opt-out of receiving text messages by updating your preferences in the Services or following the opt-out instructions in the message. You may opt-out of receiving text (SMS) messages directly relating to provision of the Services or other types of messages, including marketing announcements. If you opt-out, Generation Fertility will not send another message to you unless/until you expressly opt in to receiving additional messages. You acknowledge that opting out of receiving text (SMS) messages may impact your experience with the Service(s) that rely on communications via text (SMS) messaging":
		"You may choose to opt-out of receiving text messages by updating your preferences in the Services or following the opt-out instructions in the message. You may opt-out of receiving text (SMS) messages directly relating to provision of the Services or other types of messages, including marketing announcements. If you opt-out, Generation Fertility will not send another message to you unless/until you expressly opt in to receiving additional messages. You acknowledge that opting out of receiving text (SMS) messages may impact your experience with the Service(s) that rely on communications via text (SMS) messaging",
	"By providing your cell phone number and email address to Generation Fertility, you are agreeing to be contacted by or on behalf of Generation Fertility at the email address and the telephone number provided, including emails to your email address and text (SMS) messages to your cell phone and other wireless devices, and the use of an automatic telephone dialing system, artificial voice and pre-recorded messages, for the purpose of providing the Services and sending you marketing communications. You may also be contacted with marketing announcements about the products and services offered by or made available through our website":
		"By providing your cell phone number and email address to Generation Fertility, you are agreeing to be contacted by or on behalf of Generation Fertility at the email address and the telephone number provided, including emails to your email address and text (SMS) messages to your cell phone and other wireless devices, and the use of an automatic telephone dialing system, artificial voice and pre-recorded messages, for the purpose of providing the Services and sending you marketing communications. You may also be contacted with marketing announcements about the products and services offered by or made available through our website",
	"EMAIL AND SMS MESSAGING": "EMAIL AND SMS MESSAGING",
	"By using the Services, you hereby assign to Generation Fertility all right, title, and interest in and to any third-party benefits due from any and all insurance policies and/or responsible third-party payers for Services rendered to you. You agree to have any such amounts paid directly to Generation Fertility":
		"By using the Services, you hereby assign to Generation Fertility all right, title, and interest in and to any third-party benefits due from any and all insurance policies and/or responsible third-party payers for Services rendered to you. You agree to have any such amounts paid directly to Generation Fertility",
	"Assignment of Insurance/Third Party Payer Benefits":
		"Assignment of Insurance/Third Party Payer Benefits",
	"All prices posted on or through the Services and/or the Platform Website are subject to change without notice. Posted prices do not include taxes unless otherwise specified. All such taxes and charges will be added to your total. We strive to display accurate price information, however we may, on occasion, make inadvertent typographical errors, inaccuracies or omissions related to pricing and availability. To the fullest extent permitted by applicable law, we reserve the right to correct any errors, inaccuracies, or omissions at any time and to cancel any Services arising from such occurrences":
		"All prices posted on or through the Services and/or the Platform Website are subject to change without notice. Posted prices do not include taxes unless otherwise specified. All such taxes and charges will be added to your total. We strive to display accurate price information, however we may, on occasion, make inadvertent typographical errors, inaccuracies or omissions related to pricing and availability. To the fullest extent permitted by applicable law, we reserve the right to correct any errors, inaccuracies, or omissions at any time and to cancel any Services arising from such occurrences",
	"TERMS OF SALE": "TERMS OF SALE",
	"For greater certainty, certain Services provided to you may be elective, not be medically necessary and/or not insured benefits or services under any federal or provincial healthcare programs in the jurisdiction in which they are offered. This means that these particular Services might be optional or chosen by the patient, rather than being essential for medical reasons. Additionally, they may not be covered under standard government health insurance programs. As such, if you opt for these Services, you may be fully responsible for their associated costs, as they might not be covered or reimbursed by public healthcare or other insurance plans":
		"For greater certainty, certain Services provided to you may be elective, not be medically necessary and/or not insured benefits or services under any federal or provincial healthcare programs in the jurisdiction in which they are offered. This means that these particular Services might be optional or chosen by the patient, rather than being essential for medical reasons. Additionally, they may not be covered under standard government health insurance programs. As such, if you opt for these Services, you may be fully responsible for their associated costs, as they might not be covered or reimbursed by public healthcare or other insurance plans",
	"Some of the Services provided on the Platform Website are not covered by public health insurance. For these services, you agree to be financially responsible and obligated to pay any balance owing that is not otherwise paid by public health insurance, any third-party benefits provider, any and all insurance policies and/or responsible third-party payers. You acknowledge and agree that such payment will be made to Generation Fertility, its affiliates, and/or service providers, as per the information received prior to being provided with any Service. You further acknowledge and agree that in the event that you fail to make such payments in accordance with the payment terms in respect thereof, Generation Fertility may terminate your relationship with it, in accordance with applicable laws. Furthermore, in the event of a default of your financial obligation, should your account be turned over to an external collection agency for non-payment, you agree to pay any associated collection costs":
		"Some of the Services provided on the Platform Website are not covered by public health insurance. For these services, you agree to be financially responsible and obligated to pay any balance owing that is not otherwise paid by public health insurance, any third-party benefits provider, any and all insurance policies and/or responsible third-party payers. You acknowledge and agree that such payment will be made to Generation Fertility, its affiliates, and/or service providers, as per the information received prior to being provided with any Service. You further acknowledge and agree that in the event that you fail to make such payments in accordance with the payment terms in respect thereof, Generation Fertility may terminate your relationship with it, in accordance with applicable laws. Furthermore, in the event of a default of your financial obligation, should your account be turned over to an external collection agency for non-payment, you agree to pay any associated collection costs",
	"FINANCIAL RESPONSIBILITY FOR SERVICES":
		"FINANCIAL RESPONSIBILITY FOR SERVICES",
	"You are also responsible for: (i) all transactions or activities carried out using your Account and Password; and (ii) ensuring that when you access the Account that the system you use to access the Account is secure, is not left unattended unless you have fully exited the Account, and that your Password is not retrievable from the system by others":
		"You are also responsible for: (i) all transactions or activities carried out using your Account and Password; and (ii) ensuring that when you access the Account that the system you use to access the Account is secure, is not left unattended unless you have fully exited the Account, and that your Password is not retrievable from the system by others",
	"To the fullest extent permitted by applicable law, we reserve the right to take any action that we deem necessary to ensure the security of the Services and your Account, including without limitation deactivating your Password, terminating your Account, or requesting additional information to authenticate your Account. You are solely responsible for keeping your Password and any security questions and responses associated with your Account confidential. Anyone with knowledge of or access to your Password or the security questions and responses associated with your Account can use that information to gain access to your Account. You are solely liable for any claims, damages, losses, costs, or other liabilities resulting from or caused by any failure to keep your Password and the security questions and responses confidential, whether such failure occurs with or without your knowledge or consent. You agree that you will immediately notify us of any suspected or actual unauthorized access to or use of your Password or any other breach of your Account security":
		"To the fullest extent permitted by applicable law, we reserve the right to take any action that we deem necessary to ensure the security of the Services and your Account, including without limitation deactivating your Password, terminating your Account, or requesting additional information to authenticate your Account. You are solely responsible for keeping your Password and any security questions and responses associated with your Account confidential. Anyone with knowledge of or access to your Password or the security questions and responses associated with your Account can use that information to gain access to your Account. You are solely liable for any claims, damages, losses, costs, or other liabilities resulting from or caused by any failure to keep your Password and the security questions and responses confidential, whether such failure occurs with or without your knowledge or consent. You agree that you will immediately notify us of any suspected or actual unauthorized access to or use of your Password or any other breach of your Account security",
	"It is a condition of your use of the Services that all the required information you provide is correct, current, and complete. You agree to promptly inform us of any changes to your Account registration information":
		"It is a condition of your use of the Services that all the required information you provide is correct, current, and complete. You agree to promptly inform us of any changes to your Account registration information",
	"To set up an Account, you must provide us with certain personal information and establish a username and password that you will use to access the Services":
		"To set up an Account, you must provide us with certain personal information and establish a username and password that you will use to access the Services",
	"In order to use certain aspects of the Services, you must set up an account with us":
		"In order to use certain aspects of the Services, you must set up an account with us",
	"ACCESSING THE SERVICES AND ACCOUNT SECURITY":
		"ACCESSING THE SERVICES AND ACCOUNT SECURITY",
	"If you provide us with any feedback or suggestions regarding the Services or the Platform Website (any such feedback referred to herein as “Feedback”), you hereby assign to Generation Fertility all rights in the Feedback and agree that Generation Fertility shall have the right to use such Feedback and related information in any manner it deems appropriate. Generation Fertility will treat any Feedback you provide to Platform as non-confidential and non-proprietary. You agree that you will not submit to Generation Fertility any Feedback that contains your confidential health information or confidential health information of any third party, nor will you submit any information that you do not have the rights or permission to submit":
		"If you provide us with any feedback or suggestions regarding the Services or the Platform Website (any such feedback referred to herein as “Feedback”), you hereby assign to Generation Fertility all rights in the Feedback and agree that Generation Fertility shall have the right to use such Feedback and related information in any manner it deems appropriate. Generation Fertility will treat any Feedback you provide to Platform as non-confidential and non-proprietary. You agree that you will not submit to Generation Fertility any Feedback that contains your confidential health information or confidential health information of any third party, nor will you submit any information that you do not have the rights or permission to submit",
	FEEDBACK: "FEEDBACK",
	"WE MAY TERMINATE OR DISABLE YOUR ACCESS TO ANY OR ALL OF THE SERVICES FOR ANY REASON, WITH OR WITHOUT CAUSE, INCLUDING IF WE BELIEVE THAT YOU HAVE VIOLATED OR ACTED INCONSISTENTLY WITH THESE TERMS":
		"WE MAY TERMINATE OR DISABLE YOUR ACCESS TO ANY OR ALL OF THE SERVICES FOR ANY REASON, WITH OR WITHOUT CAUSE, INCLUDING IF WE BELIEVE THAT YOU HAVE VIOLATED OR ACTED INCONSISTENTLY WITH THESE TERMS",
	"otherwise attempt to interfere with the proper working of the Services":
		"otherwise attempt to interfere with the proper working of the Services",
	"attack the Platform Website or any other Generation Fertility website via a denial-of-service attack or a distributed denial-of-service attack; or":
		"attack the Platform Website or any other Generation Fertility website via a denial-of-service attack or a distributed denial-of-service attack; or",
	"attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services are stored, or any server, computer, or database connected to the Services":
		"attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services are stored, or any server, computer, or database connected to the Services",
	"use any automated means to access the Services, or collect any information from Services (including, without limitation, robots, spiders, scripts, or other automatic devices or programs);":
		"use any automated means to access the Services, or collect any information from Services (including, without limitation, robots, spiders, scripts, or other automatic devices or programs);",
	"harass, abuse, or otherwise mistreat any of our personnel":
		"harass, abuse, or otherwise mistreat any of our personnel",
	"transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation":
		"transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation",
	"upload, post, transmit, share, or otherwise make available any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of the Services":
		"upload, post, transmit, share, or otherwise make available any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of the Services",
	"upload, post, transmit, share, store, or otherwise make publicly available through the Services any material that contains private information of any third party without their consent, including, without limitation, addresses, phone numbers, email addresses, social insurance numbers, and credit card numbers":
		"upload, post, transmit, share, store, or otherwise make publicly available through the Services any material that contains private information of any third party without their consent, including, without limitation, addresses, phone numbers, email addresses, social insurance numbers, and credit card numbers",
	"impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age, or your affiliation with or authority to act on behalf of any person or entity":
		"impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age, or your affiliation with or authority to act on behalf of any person or entity",
	"You agree not to use the Services to":
		"You agree not to use the Services to",
	"Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Services":
		"Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Services",
	"USE RESTRICTIONS": "USE RESTRICTIONS",
	"Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text":
		"Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text",
	"Modify copies of any materials from the Services":
		"Modify copies of any materials from the Services",
	"Remove, circumvent, disable, damage, or otherwise interfere with security-related features of the Services, features that prevent or restrict use or copying of any content accessible through the Services or features that enforce limitations on use of the Service":
		"Remove, circumvent, disable, damage, or otherwise interfere with security-related features of the Services, features that prevent or restrict use or copying of any content accessible through the Services or features that enforce limitations on use of the Service",
	"Rent, lease, loan, resell, sublicense, distribute, or otherwise transfer your access to the Services to any third party":
		"Rent, lease, loan, resell, sublicense, distribute, or otherwise transfer your access to the Services to any third party",
	"Modify, disassemble, decompile, or reverse engineer the Services except to the extent that such restriction is expressly prohibited by law":
		"Modify, disassemble, decompile, or reverse engineer the Services except to the extent that such restriction is expressly prohibited by law",
	"You may not": "You may not",
	"Subject to the use restrictions set forth below, these Terms grant you a limited, non-exclusive right to access and make personal use of the Services, subject to these Terms. The Services, and any part thereof may not be reproduced, copied, framed or otherwise exploited for any commercial purpose without the express prior written consent of Generation Fertility and/or its licensors":
		"Subject to the use restrictions set forth below, these Terms grant you a limited, non-exclusive right to access and make personal use of the Services, subject to these Terms. The Services, and any part thereof may not be reproduced, copied, framed or otherwise exploited for any commercial purpose without the express prior written consent of Generation Fertility and/or its licensors",
	"SERVICE ACCESS AND LIMITATION": "SERVICE ACCESS AND LIMITATION",
	"You represent and warrant that you are a temporary or permanent resident of the Province of Ontario":
		"You represent and warrant that you are a temporary or permanent resident of the Province of Ontario",
	"You represent that you are at least eighteen (18) years of age. You acknowledge that the Services and the Platform Website are only intended for users who are least eighteen (18) years old":
		"You represent that you are at least eighteen (18) years of age. You acknowledge that the Services and the Platform Website are only intended for users who are least eighteen (18) years old",
	"You agree that any and all information you provide to the Platform is truthful and accurate. Inaccurate information will affect your experience when using the Services and the Platform Website. You may also be asked to provide authorization or additional information when you use the Services and the Platform Website in order to verify the information or content you submit. You further agree to promptly notify Generation Fertility if you discover or reasonably suspect you have provided any false or incorrect information":
		"You agree that any and all information you provide to the Platform is truthful and accurate. Inaccurate information will affect your experience when using the Services and the Platform Website. You may also be asked to provide authorization or additional information when you use the Services and the Platform Website in order to verify the information or content you submit. You further agree to promptly notify Generation Fertility if you discover or reasonably suspect you have provided any false or incorrect information",
	"You must at all times comply with these Terms in order for you to use the Services and the Platform Website. You will comply with all applicable federal, provincial, and local laws, regulations and rules when using the Services and the Platform Website":
		"You must at all times comply with these Terms in order for you to use the Services and the Platform Website. You will comply with all applicable federal, provincial, and local laws, regulations and rules when using the Services and the Platform Website",
	"Your access and continued use of the Platform Website is subject to you agreeing to and undertaking certain responsibilities. You hereby agree to the following":
		"Your access and continued use of the Platform Website is subject to you agreeing to and undertaking certain responsibilities. You hereby agree to the following",
	"USERS’ RESPONSIBILITIES": "USERS’ RESPONSIBILITIES",
	"Our name, the Generation Fertility logo, and all related names, logos, product and service names, designs, and slogans are trademarks of Generation Fertility or its affiliates or licensors. You must not use such marks without the prior written permission of Generation Fertility. All other names, logos, product and service names, designs, and slogans on the Services are the trademarks of their respective owners":
		"Our name, the Generation Fertility logo, and all related names, logos, product and service names, designs, and slogans are trademarks of Generation Fertility or its affiliates or licensors. You must not use such marks without the prior written permission of Generation Fertility. All other names, logos, product and service names, designs, and slogans on the Services are the trademarks of their respective owners",
	"The Services and the information, files, documents, text, photographs, images, audio, and video contained therein, including any material made available for download by us, whether through Platform Website or otherwise (collectively, the “Content”) are the property of us or our licensors, as applicable. The Services and Contents are protected by Canadian and international copyright and trademark laws. The Content may not be copied, distributed, modified, reproduced, published, or used, in whole or in part, except for purposes authorized or approved in writing by us. All rights not expressly granted herein are reserved to us and our licensors":
		"The Services and the information, files, documents, text, photographs, images, audio, and video contained therein, including any material made available for download by us, whether through Platform Website or otherwise (collectively, the “Content”) are the property of us or our licensors, as applicable. The Services and Contents are protected by Canadian and international copyright and trademark laws. The Content may not be copied, distributed, modified, reproduced, published, or used, in whole or in part, except for purposes authorized or approved in writing by us. All rights not expressly granted herein are reserved to us and our licensors",
	"This process took an appropriate amount of time":
		"This process took an appropriate amount of time",
	"INTELLECTUAL PROPERTY": "INTELLECTUAL PROPERTY",
	Review: "Review",
	"Let us know what you think!": "Let us know what you think!",
	"To understand how we process information collected about you, including what information we collect through our Services, how such information is used, and how such information may be shared, before you agree to these Terms":
		"To understand how we process information collected about you, including what information we collect through our Services, how such information is used, and how such information may be shared, before you agree to these Terms",
	"your fertility history": "your fertility history",
	"Please review our Patient Privacy Policy":
		"Please review our Patient Privacy Policy",
	"This process was simple to complete":
		"This process was simple to complete",
	"Thank you for completing": "Thank you for completing",
	"As with any other health care service, there are risks associated with the use of the Services. In addition to any risks communicated to you by your Healthcare Professional, there are risks unique to accessing health care services virtually, which include that the Platform may not permit the transmission of information in a way that allows the Healthcare Professional to provide Services (for example, due to poor resolution of images). Other risks of the use of electronic means to transmit and store information are described in our Website Privacy Policy, posted at generationfertility.ca, and our patient privacy policy, provided to you in connection with the Services (the “Patient Privacy Policy”), both of which form part of these Terms":
		"As with any other health care service, there are risks associated with the use of the Services. In addition to any risks communicated to you by your Healthcare Professional, there are risks unique to accessing health care services virtually, which include that the Platform may not permit the transmission of information in a way that allows the Healthcare Professional to provide Services (for example, due to poor resolution of images). Other risks of the use of electronic means to transmit and store information are described in our Website Privacy Policy, posted at generationfertility.ca, and our patient privacy policy, provided to you in connection with the Services (the “Patient Privacy Policy”), both of which form part of these Terms",
	"Completing this process helped me feel I am progressing my fertility journey":
		"Completing this process helped me feel I am progressing my fertility journey",
	"Your consent will be required before being provided with any Health Service and undergoing certain assessments. In this regard, you may be required to complete certain patient consent and acknowledgements (either written or verbal)":
		"Your consent will be required before being provided with any Health Service and undergoing certain assessments. In this regard, you may be required to complete certain patient consent and acknowledgements (either written or verbal)",
	"(scale of 1 [it took too long] to 5 [it took a reasonable amount of time])":
		"(scale of 1 [it took too long] to 5 [it took a reasonable amount of time])",
	"The Platform allows you to access fertility resources, tools and services provided through fertility care coordinators (“Care Coordinators”) and physicians, nurses, or other healthcare professionals (“Healthcare Professionals”). Our Care Coordinators help coordinate, plan and manage your fertility planning and treatments (“Care Services”) and our Healthcare Professionals provide regulated health care services relating to fertility treatment (“Health Services”)":
		"The Platform allows you to access fertility resources, tools and services provided through fertility care coordinators (“Care Coordinators”) and physicians, nurses, or other healthcare professionals (“Healthcare Professionals”). Our Care Coordinators help coordinate, plan and manage your fertility planning and treatments (“Care Services”) and our Healthcare Professionals provide regulated health care services relating to fertility treatment (“Health Services”)",
	"This provision may not apply to you if applicable law prohibits it. Please be aware that these Terms contain provisions that govern how claims that you and Generation Fertility have against each other are resolved and limit the remedies that may be available to you in the event of a dispute. In particular, it contains an arbitration provision which will require you to submit claims that you have against Generation Fertility to binding and final arbitration. To the fullest extent permitted by law: (i) you will only be permitted to pursue claims and seek relief against Generation Fertility on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding; and (ii) you are waiving your right to seek relief in a court of law and to have a jury trial on your claims. The arbitration provisions set forth in these Terms could affect your right to participate in class action litigation. Please see the section titled “ARBITRATION” for more information regarding these arbitration provisions":
		"This provision may not apply to you if applicable law prohibits it. Please be aware that these Terms contain provisions that govern how claims that you and Generation Fertility have against each other are resolved and limit the remedies that may be available to you in the event of a dispute. In particular, it contains an arbitration provision which will require you to submit claims that you have against Generation Fertility to binding and final arbitration. To the fullest extent permitted by law: (i) you will only be permitted to pursue claims and seek relief against Generation Fertility on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding; and (ii) you are waiving your right to seek relief in a court of law and to have a jury trial on your claims. The arbitration provisions set forth in these Terms could affect your right to participate in class action litigation. Please see the section titled “ARBITRATION” for more information regarding these arbitration provisions",
	"GENERATION FERTILITY HEALTH SERVICES":
		"GENERATION FERTILITY HEALTH SERVICES",
	"(scale of 1 [it did not help] to 5 [it has helped])":
		"(scale of 1 [it did not help] to 5 [it has helped])",
	"THE SERVICES ARE NOT INTENDED TO BE USED FOR URGENT OR EMERGENT HEALTHCARE NEEDS. IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, PLEASE DIAL 911":
		"THE SERVICES ARE NOT INTENDED TO BE USED FOR URGENT OR EMERGENT HEALTHCARE NEEDS. IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, PLEASE DIAL 911",
	"Send feedback": "Send feedback",
	"ARBITRATION NOTICE": "ARBITRATION NOTICE",
	"MEDICAL EMERGENCY": "MEDICAL EMERGENCY",
	"EFFECTIVE DATE OF THESE TERMS IS SET FORTH AT THE TOP OF THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, GENERATION FERTILITY RESERVES THE RIGHT TO MODIFY,UPDATE AND CHANGE, AT ANY TIME, IN ITS SOLE AND ABSOLUTE  DISCRETION: (I) THESE TERMS; (II) ADDITIONAL DOCUMENTS RELATED TO THE SERVICES AND THE PLATFORM WEBSITE; (III)  SERVICES; AND/OR (IV) THE PLATFORM WEBSITE. CHANGES TO THE TERMS WILL BE POSTED TO THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY POSTED CHANGES WILL BE BINDING ON YOU THIRTY (30) DAYS FOLLOWING THEIR POSTING.WE ENCOURAGE YOU TO RETURN TO THIS WEBPAGE FREQUENTLY SO THAT YOU ARE AWARE OF OUR CURRENT TERMS. YOUR CONTINUED USAGE OF THE PLATFORM WEBSITE AND/OR SERVICES AFTER THE EFFECTIVE DATE WILL MEAN YOU ACCEPT AND CONSENT TO ANY CHANGES, UPDATES AND MODIFICATIONS TO THE TERMS. THE AMENDED TERMS SUPERSEDE ALL PREVIOUS VERSIONS":
		"EFFECTIVE DATE OF THESE TERMS IS SET FORTH AT THE TOP OF THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, GENERATION FERTILITY RESERVES THE RIGHT TO MODIFY,UPDATE AND CHANGE, AT ANY TIME, IN ITS SOLE AND ABSOLUTE  DISCRETION: (I) THESE TERMS; (II) ADDITIONAL DOCUMENTS RELATED TO THE SERVICES AND THE PLATFORM WEBSITE; (III)  SERVICES; AND/OR (IV) THE PLATFORM WEBSITE. CHANGES TO THE TERMS WILL BE POSTED TO THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY POSTED CHANGES WILL BE BINDING ON YOU THIRTY (30) DAYS FOLLOWING THEIR POSTING.WE ENCOURAGE YOU TO RETURN TO THIS WEBPAGE FREQUENTLY SO THAT YOU ARE AWARE OF OUR CURRENT TERMS. YOUR CONTINUED USAGE OF THE PLATFORM WEBSITE AND/OR SERVICES AFTER THE EFFECTIVE DATE WILL MEAN YOU ACCEPT AND CONSENT TO ANY CHANGES, UPDATES AND MODIFICATIONS TO THE TERMS. THE AMENDED TERMS SUPERSEDE ALL PREVIOUS VERSIONS",
	"CHANGES TO SERVICES AND TERMS": "CHANGES TO SERVICES AND TERMS",
	"These Terms are entered into by and between you and Generation Fertility, and these Terms govern your access to and use of our Care Navigation Platform (the “Platform”), including any content, functionality, services, or products offered on, through or in connection with www.generation.fertility.care (the “Platform Website”) (collectively, the “Services”). By accessing the Platform Website and Services or using other acceptance methods provided by our Services, you agree to be bound by these Terms. Additionally, when generally accessing the Services, your use and continued	 access of the Services signifies your acceptance to these Terms. If you do not agree to any of the terms or conditions in these Terms you should immediately leave the Platform Website, not access or use the Services, or create an account and not use the Platform Website":
		"These Terms are entered into by and between you and Generation Fertility, and these Terms govern your access to and use of our Care Navigation Platform (the “Platform”), including any content, functionality, services, or products offered on, through or in connection with www.generation.fertility.care (the “Platform Website”) (collectively, the “Services”). By accessing the Platform Website and Services or using other acceptance methods provided by our Services, you agree to be bound by these Terms. Additionally, when generally accessing the Services, your use and continued	 access of the Services signifies your acceptance to these Terms. If you do not agree to any of the terms or conditions in these Terms you should immediately leave the Platform Website, not access or use the Services, or create an account and not use the Platform Website",
	"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of Generation Fertility and its respective	affiliates, and obligations to comply with applicable laws and regulations":
		"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of Generation Fertility and its respective	affiliates, and obligations to comply with applicable laws and regulations",
	"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as Generation Fertility (“Generation Fertility”, “we,” “us,” or “our”), a Canadian company with offices at 955 Major MacKenzie Drive West, Unit 400, Maple, Ontario, and 1111 Davis Drive East, Unit 39, Newmarket, Ontario":
		"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as Generation Fertility (“Generation Fertility”, “we,” “us,” or “our”), a Canadian company with offices at 955 Major MacKenzie Drive West, Unit 400, Maple, Ontario, and 1111 Davis Drive East, Unit 39, Newmarket, Ontario",
	"EFFECTIVE DATE: January 30, 2023": "EFFECTIVE DATE: January 30, 2023",
	"PLATFORM TERMS OF USE": "PLATFORM TERMS OF USE",
	"Please give a reason": "Please give a reason",
	"Please select one of the reasons": "Please select one of the reasons",
	Unsubscribed: "Unsubscribed",
	"You have been unsubscribed! You will no longer receive emails from us":
		"You have been unsubscribed! You will no longer receive emails from us",
	Unsubscribe: "Unsubscribe",
	"You will no longer receive emails from us":
		"You will no longer receive emails from us",
	"If you have a moment, please let us know why you don’t need an email anymore":
		"If you have a moment, please let us know why you don’t need an email anymore",
	"Address postal code is required": "Address postal code is required",
	"Complete Your Profile": "Complete Your Profile",
	"Date of birth is required": "Date of birth is required",
	"Health card expiry date is required":
		"Health card expiry date is required",
	"Health Card Expiry Date": "Health Card Expiry Date",
	"Address is required": "Address is required",
	"Check your spam folder": "Check your spam folder",
	"Invalid Token": "Invalid Token",
	"Your confidential health forms include a history of your general health and fertility to help us tailor your first consultation to your needs. This information needs to be completed before your first virtual consultation":
		"Your confidential health forms include a history of your general health and fertility to help us tailor your first consultation to your needs. This information needs to be completed before your first virtual consultation",
	"Save and quit": "Save and quit",
	Speciality: "Speciality",
	Specialty: "Specialty",
	Finish: "Finish",
	"Continue where I left off": "Continue where I left off",
	"Your information is always protected":
		"Your information is always protected",
	"Please select an answer for the first question":
		"Please select an answer for the first question",
	"Please select an answer for the second question":
		"Please select an answer for the second question",
	"Please select an answer for the third question":
		"Please select an answer for the third question",
	"(scale of 1 [it was not simple] to 5 [it was very simple])":
		"(scale of 1 [it was not simple] to 5 [it was very simple])",
	"The token is invalid": "The token is invalid",
	"By creating an account, I agree to": "By creating an account, I agree to",
	"Verify your email": "Verify your email",
	"Verify your phone number": "Verify your phone number",
	"Your browser does not support the audio element":
		"Your browser does not support the audio element",
	"Install this app on your iPhone: tap":
		"Install this app on your iPhone: tap",
	"and then Add to homescreen": "and then Add to homescreen",
	"Canada (English)": "Canada (English)",
	Francais: "Francais",
	"Having some issues": "Having some issues",
	"Close Account": "Close Account",
	"Please read this carefully": "Please read this carefully",
	"Your Generation Fertility account will be permanently closed and your data will be deleted. This means that your account will no longer be available and cannot be restored":
		"Your Generation Fertility account will be permanently closed and your data will be deleted. This means that your account will no longer be available and cannot be restored",
	"Please select the main reason for closing your account":
		"Please select the main reason for closing your account",
	"Choose reason (optional)": "Choose reason (optional)",
	"Cancel your appointment": "Cancel your appointment",
	"Are you sure you want to cancel your appointment on":
		"Are you sure you want to cancel your appointment on",
	"Thank you for getting in touch!": "Thank you for getting in touch!",
	"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as Generation Fertility (“Generation Fertility”, “we,” “us” or “our”), a Canadian company with offices at 955 Major MacKenzie Drive West, Unit 400, Maple, Ontario, and 1111 Davis Drive East, Unit 39, Newmarket, Ontario":
		"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as Generation Fertility (“Generation Fertility”, “we,” “us” or “our”), a Canadian company with offices at 955 Major MacKenzie Drive West, Unit 400, Maple, Ontario, and 1111 Davis Drive East, Unit 39, Newmarket, Ontario",
	"Message/specify (Please be as detailed as possible)":
		"Message/specify (Please be as detailed as possible)",
	"For further information, please visit":
		"For further information, please visit",
	"There are": "There are",
	"tasks you'll need to complete before your first appointment":
		"tasks you'll need to complete before your first appointment",
	"Last name should contain valid characters":
		"Last name should contain valid characters",
	"First name should contain valid characters":
		"First name should contain valid characters",
	"Partner has already created the account":
		"Partner has already created the account",
	"Invite your partner to set up their own profile. They will need to complete their own set of consent and health forms":
		"Invite your partner to set up their own profile. They will need to complete their own set of consent and health forms",
	"Phone Number Verified and Saved": "Phone Number Verified and Saved",
	"Invite Your Partner": "Invite Your Partner",
	Verification: "Verification",
	"Please confirm the first day of your period":
		"Please confirm the first day of your period",
	"Monday, August 1, 2022": "Monday, August 1, 2022",
	"Are you still there": "Are you still there",
	"To protect your information, we will automatically log you out after a period of no activity":
		"To protect your information, we will automatically log you out after a period of no activity",
	"Remaining time": "Remaining time",
	"Yes, I’m here": "Yes, I’m here",
	Welcome: "Welcome",
	"Yes, I want to permanently close my Generation Fertility account and delete my data":
		"Yes, I want to permanently close my Generation Fertility account and delete my data",
	"Close my account": "Close my account",
	"Choose reason": "Choose reason",
	"There are three tasks you'll need to complete before your first appointment":
		"There are three tasks you'll need to complete before your first appointment",
	"First day of your period": "First day of your period",
	"Welcome ": "Welcome ",
	Accessibility: "Accessibility",
	Help: "Help",
	Hormones: "Hormones",
	"All caught up": "All caught up",
	"Personal Information": "Personal Information",
	"Communication Preferences": "Communication Preferences",
	"Having some issue": "Having some issue",
	Hello: "Hello",
	"What is your ethnic background": "What is your ethnic background",
	"What is your occupation": "What is your occupation",
	Musician: "Musician",
	"Please enter your height": "Please enter your height",
	"Please enter your weight": "Please enter your weight",
	"Have you experienced any weight changes":
		"Have you experienced any weight changes",
	Lost: "Lost",
	"Do you exercise regularly": "Do you exercise regularly",
	"Do you smoke cigarettes": "Do you smoke cigarettes",
	"How many cigarettes do you smoke a day":
		"How many cigarettes do you smoke a day",
	"Do you drink alcohol": "Do you drink alcohol",
	"Do you consume caffeine (e.g. coffee, tea, pop)":
		"Do you consume caffeine (e.g. coffee, tea, pop)",
	"Please select which ones": "Please select which ones",
	"Coffee, Tea": "Coffee, Tea",
	"How many cups a day": "How many cups a day",
	"Do you use recreational drugs (e.g. cannabis, cocaine, etc)":
		"Do you use recreational drugs (e.g. cannabis, cocaine, etc)",
	"Have you ever been exposed to harmful chemicals or toxins":
		"Have you ever been exposed to harmful chemicals or toxins",
	"Now we are going to ask some questions related to hormones":
		"Now we are going to ask some questions related to hormones",
	"This should take about": "This should take about",
	"2 minutes": "2 minutes",
	Start: "Start",
	"Social activities": "Social activities",
	"Previous pregnancies": "Previous pregnancies",
	Black: "Black",
	"Biological history": "Biological history",
	"On a scale from 1-5, how would you rate":
		"On a scale from 1-5, how would you rate",
	"your experience": "your experience",
	"about your experience that made you choose 5":
		"about your experience that made you choose 5",
	"We are so happy to hear you choose 5! What was it":
		"We are so happy to hear you choose 5! What was it",
	"Back to patient portal": "Back to patient portal",
	"To-do": "To-do",
	"Once you have completed all your intake forms, the clinic will call to confirm your first appointment":
		"Once you have completed all your intake forms, the clinic will call to confirm your first appointment",
	"Contact Us": "Contact Us",
	"Contact us": "Contact us",
	"Sex at birth": "Sex at birth",
	"Sex at Birth": "Sex at Birth",
	"Hetero - sexual": "Hetero - sexual",
	"Same - sex": "Same - sex",
	"She/her": "She/her",
	"They/them": "They/them",
	"incorrect username or password": "incorrect username or password",
	"Incorrect password": "Incorrect password",
	"Incorrect username or password.": "Incorrect username or password.",
	"incorrect username or password.": "incorrect username or password.",
	"Do you have a family doctor": "Do you have a family doctor",
	"Full Name": "Full Name",
	"Do you allow us to contact your family doctor to get a referral on your behalf":
		"Do you allow us to contact your family doctor to get a referral on your behalf",
	"Invalid password": "Invalid password",
	"Email address is required": "Email address is required",
	"Invalid session for the user": "Invalid session for the user",
	To: "To",

	//eng
	"Please complete your profile first": "Please complete your profile first",
	"Select an Option": "Select an Option",
	Invited: "Invited",
	"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of Generation Fertility and its respective affiliates, and obligations to comply with applicable laws and regulations":
		"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of Generation Fertility and its respective affiliates, and obligations to comply with applicable laws and regulations.",
	"register for more than one Account or register or operate an Account on behalf of another person":
		"register for more than one Account or register or operate an Account on behalf of another person",
	"Edit Username": "Edit Username",
	GP: "GP",
	"New User": "New Use",
	"About Us": "About Us",
	"We retain personal information for as long as necessary for the fulfilment of the purposes for which it was collected, in accordance with prudent business practices on general document retention, or as otherwise require d by law":
		"We retain personal information for as long as necessary for the fulfilment of the purposes for which it was collected, in accordance with prudent business practices on general document retention, or as otherwise require d by law",
	"Partner Profile": "Partner Profile",
	Inactive: "Inactive",
	"Address Line 1": "Address Line 1",
	Never: "Never",
	Other: "Other",
	CLINIC_NAME: "CLINIC_NAME",
	CLINIC_WEBSITE: "CLINIC_WEBSITE",
	"Physician campaign Details": "Physician campaign Details",
	Unassign: "Unassign",
	Month: "Month",
	Year: "Year",
	Administrator: "Administrator",
	CareCoordinator: "Care Coordinator",
	MOA: "MOA",
	WebReferral: "WebReferral",
	"Yes, disable this user": "Yes, disable this user",
	"Campaign Status": "Campaign Status",
	Channel: "Channel",
	"Campaigns Name": "Campaigns Name",
	"Assigned role": "Assigned role",
	"Edit Member": "Edit Member",
	"PreAuthentication failed with error This user is not an administrator":
		"PreAuthentication failed with error This user is not an administrator",
	Text: "Text",

	"Campaign info": "Campaign info",
	"Log In": "Log In",
	"One time password delivery method": "One time password delivery method",
	"Invalid session for the user.": "Invalid session for the user.",
	"Your biological history forms the basis of your fertility journey.":
		"Your biological history forms the basis of your fertility journey.",
	"Your biological history forms the basis of your fertility journey":
		"Your biological history forms the basis of your fertility journey",
	"We look forward to seeing you and helping you take the next steps on your fertility journey":
		"We look forward to seeing you and helping you take the next steps on your fertility journey",
	"Notes added successfully": "Notes added successfully",
	"Notes Updated successfully": "Notes Updated successfully",
	"Notes deleted successfully": "Notes deleted successfully",
	"No Documents": "No Documents",
	"Please answer this question": "Please answer this question",
	"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as CLINIC_NAME (“CLINIC_NAME”, “we,” “us” or “our”), a Canadian company with offices at CLINIC_ADDRESS":
		"Welcome to our Care Navigation Platform! This service is offered by Procrea Canada (Vaughan) Inc., carrying on as CLINIC_NAME (“CLINIC_NAME”, “we,” “us” or “our”), a Canadian company with offices at CLINIC_ADDRESS",
	"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of CLINIC_NAME and its respective	affiliates, and obligations to comply with applicable laws and regulations":
		"Before using any of the services available through our Care Navigation Platform, you need to read these Terms of Use (“Terms”) carefully as they contain important information affecting your legal rights, obligations, and remedies. This includes, among other important provisions, various limitations on your rights, terms regarding the use of personal information, limitation on the liability of CLINIC_NAME and its respective	affiliates, and obligations to comply with applicable laws and regulations",
	"These Terms are entered into by and between you and CLINIC_NAME, and these Terms govern your access to and use of our Care Navigation Platform (the “Platform”), including any content, functionality, services, or products offered on, through or in connection with www.CLINIC_DOMAIN.fertility.care (the “Platform Website”) (collectively, the “Services”). By accessing the Platform Website and Services or using other acceptance methods provided by our Services, you agree to be bound by these Terms. Additionally, when generally accessing the Services, your use and continued	 access of the Services signifies your acceptance to these Terms. If you do not agree to any of the terms or conditions in these Terms you should immediately leave the Platform Website, not access or use the Services, or create an account and not use the Platform Website":
		"These Terms are entered into by and between you and CLINIC_NAME, and these Terms govern your access to and use of our Care Navigation Platform (the “Platform”), including any content, functionality, services, or products offered on, through or in connection with www.CLINIC_DOMAIN.fertility.care (the “Platform Website”) (collectively, the “Services”). By accessing the Platform Website and Services or using other acceptance methods provided by our Services, you agree to be bound by these Terms. Additionally, when generally accessing the Services, your use and continued	 access of the Services signifies your acceptance to these Terms. If you do not agree to any of the terms or conditions in these Terms you should immediately leave the Platform Website, not access or use the Services, or create an account and not use the Platform Website",
	"EFFECTIVE DATE OF THESE TERMS IS SET FORTH AT THE TOP OF THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, CLINIC_NAME RESERVES THE RIGHT TO MODIFY,UPDATE AND CHANGE, AT ANY TIME, IN ITS SOLE AND ABSOLUTE  DISCRETION: (I) THESE TERMS; (II) ADDITIONAL DOCUMENTS RELATED TO THE SERVICES AND THE PLATFORM WEBSITE; (III)  SERVICES; AND/OR (IV) THE PLATFORM WEBSITE. CHANGES TO THE TERMS WILL BE POSTED TO THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY POSTED CHANGES WILL BE BINDING ON YOU THIRTY (30) DAYS FOLLOWING THEIR POSTING.WE ENCOURAGE YOU TO RETURN TO THIS WEBPAGE FREQUENTLY SO THAT YOU ARE AWARE OF OUR CURRENT TERMS. YOUR CONTINUED USAGE OF THE PLATFORM WEBSITE AND/OR SERVICES AFTER THE EFFECTIVE DATE WILL MEAN YOU ACCEPT AND CONSENT TO ANY CHANGES, UPDATES AND MODIFICATIONS TO THE TERMS. THE AMENDED TERMS SUPERSEDE ALL PREVIOUS VERSIONS":
		"EFFECTIVE DATE OF THESE TERMS IS SET FORTH AT THE TOP OF THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, CLINIC_NAME RESERVES THE RIGHT TO MODIFY,UPDATE AND CHANGE, AT ANY TIME, IN ITS SOLE AND ABSOLUTE  DISCRETION: (I) THESE TERMS; (II) ADDITIONAL DOCUMENTS RELATED TO THE SERVICES AND THE PLATFORM WEBSITE; (III)  SERVICES; AND/OR (IV) THE PLATFORM WEBSITE. CHANGES TO THE TERMS WILL BE POSTED TO THIS WEBPAGE. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY POSTED CHANGES WILL BE BINDING ON YOU THIRTY (30) DAYS FOLLOWING THEIR POSTING.WE ENCOURAGE YOU TO RETURN TO THIS WEBPAGE FREQUENTLY SO THAT YOU ARE AWARE OF OUR CURRENT TERMS. YOUR CONTINUED USAGE OF THE PLATFORM WEBSITE AND/OR SERVICES AFTER THE EFFECTIVE DATE WILL MEAN YOU ACCEPT AND CONSENT TO ANY CHANGES, UPDATES AND MODIFICATIONS TO THE TERMS. THE AMENDED TERMS SUPERSEDE ALL PREVIOUS VERSIONS",
	"View Forms": "View Forms",
	"At least 1 number": "At least 1 number",
	"At least 1 capital letter": "At least 1 capital letter",
	"8 or more characters": "8 or more characters",
	"At least 1 special character": "At least 1 special character",
	Contacted: "Contacted",
	Virtual: "Virtual",
	"On-Site": "On-Site",
	"Yes, How can we help you": "Yes, How can we help you",
	"You're sending": "You're sending",
	"Text Message": "Text Message",
	"Last invitation sent on": "Last invitation sent on",
	"Need Attention": "Need Attention",
	Send: "Send",
	send: "send",
	"Are you sure!": "Are you sure!",
	"Email sent": "Email sent",
	"Do you really want to delete?": "Do you really want to delete?",
	"Cancelled Appointment": "Cancelled Appointment",
	Booked: "Booked",
	"Active High Volume": "Active High Volume",
	Active_high_volume: "Active-High-Volume",
	"Active Core": "Active Core",
	"3 Months Lapsed": "3 Months Lapsed",
	"4 Months Lapsed": "4 Months Lapsed",
	"5 Months Lapsed": "5 Months Lapsed",
	"Profile Completion": "Profile Completion",
	"Fertility Specialist": "Fertility Specialist",
	"Please check your email": "Please check your email",
	"Password successfully updated": "Password successfully updated",
	"Error deleting items from DynamoDB": "Error deleting items from DynamoDB",
	"Token found": "Token found",
	"Internal server error": "Internal server error",
	"Key does not exist in request body": "Key does not exist in request body",
	"User is disabled.": "User is disabled.",
	"Please enter the OTP": "Please enter the OTP",
	"User does not exist": "User does not exist",
	"delete this note": "delete this note",
	"You will not be able to revert this":
		"You will not be able to revert this",
	"Yes, delete this note": "Yes, delete this note",
	"Please enter the patient's EMR ID to confirm":
		"Please enter the patient's EMR ID to confirm",
	"this action. This cannot be undone": "this action. This cannot be undone",
	False: "False",
	"Pre-diagnostic": "Pre-diagnostic",
	"Assigned role is required": "Assigned role is required",
	"Invitation sent to phone and email successfully":
		"Invitation sent to phone and email successfully",
	"Invitation sent to phone successfully":
		"Invitation sent to phone successfully",
	"Invitation sent to email successfully":
		"Invitation sent to email successfully",
	"Please provide the sex at birth to assign the partner as pre-diagnostic.":
		"Please provide the sex at birth to assign the partner as pre-diagnostic.",
	"Please provide email, sex at birth and REI to assign the lead as pre-diagnostic.":
		"Please provide email, sex at birth and REI to assign the lead as pre-diagnostic.",
	"Please provide email and sex at birth to assign the lead as pre-diagnostic.":
		"Please provide email and sex at birth to assign the lead as pre-diagnostic.",
	"Please provide email and REI to assign the lead as pre-diagnostic.":
		"Please provide email and REI to assign the lead as pre-diagnostic.",
	"Please provide sex at birth and REI to assign the lead as pre-diagnostic.":
		"Please provide sex at birth and REI to assign the lead as pre-diagnostic.",
	"Please provide email to assign the lead as pre-diagnostic.":
		"Please provide email to assign the lead as pre-diagnostic.",
	"Please provide sex at birth to assign the lead as pre-diagnostic.":
		"Please provide sex at birth to assign the lead as pre-diagnostic.",
	"Please provide REI to assign the lead as pre-diagnostic.":
		"Please provide REI to assign the lead as pre-diagnostic.",
	"Please select a partner": "Please select a partner",
	"Day, Month, Year": "Day, Month, Year",
	"Archived Reason": "Archived Reason",
	"Name and contact information (including mailing address, email address, and telephone number)":
		"Name and contact information (including mailing address, email address, and telephone number)",
	"Benefit provider": "Benefit provider",
	"Submitted by": "Submitted by",
	"Not Submitted": "Not Submitted ",
	New: "New",
	"Patient records will be moved to the previous stage":
		"Patient records will be moved to the previous stage",
	// Pharmacy Translatios
	Pharmacy: "Pharmacy",
	"Pharmacy (Located outside OFC)": "Pharmacy (Located outside OFC)",
	"Pharmacy name is required": "Pharmacy name is required",
	"Pharmacy Name": "Pharmacy Name",
	"Password attempts exceeded": "Password attempts exceeded",
	"PreAuthentication failed with error this user does not exist":
		"User does not exist",
	"Fertility Benefits or Health Benefits Provider":
		"Fertility Benefits or Health Benefits Provider",
	"Email does not exist": "Email does not exist",
	"has changed the status of Consents": "has changed the status of Consents",
	"Move to Patient": "Move to Patient",
	"Secondary Phone number is required": "Secondary Phone number is required",
	"Secondary Phone": "Secondary Phone",
	"Cell Phone": "Cell Phone",
	"Cell Phone is required": "Cell Phone is required",
	"Cell Phone is not a valid US/Canadian number":
		"Cell Phone is not a valid US/Canadian number",
	"Cell Phone is already taken": "Cell Phone is already taken",
	"Family Physician or Nurse Practitioner":
		"Family Physician or Nurse Practitioner",
	Feet: "Feet",
	Inches: "Inches",
	Centimeter: "Centimeter",
	"Feet/Inches": "Feet/Inches",
	Cm: "Cm",
	"Feet cannot be 0": "Feet cannot be 0",
	"Height should be between 1ft and 15ft":
		"Height should be between 1ft and 15ft",
	"Height should be between 30cm and 457cm":
		"Height should be between 30cm and 457cm",
	"Please enter a value less than 10": "Please enter a value less than 10",
	"I don't have a family physician.": "I don't have a family physician.",
	Consents: "Consents",
	"Whitespaces are not allowed.": "Whitespaces are not allowed.",
	"Method of contact is required.": "Method of contact is required.",
	"New Patient is required": "New Patient is required",
	"First name is required.": "First name is required.",
	"Referral Date is required.": "Referral Date is required.",
	"Care Coordinator is required.": "Care Coordinator is required.",
	"Preferred Location is required.": "Preferred Location is required.",
	"New Patient is required.": "New Patient is required.",
	"Cell Phone is required.": "Cell Phone is required.",
	"Suite, Apt, Unit, Building": "Suite, Apt, Unit, Building",
	"Lead Address": "Lead Address",
	"Patient Address": "Patient Address",
	"Purpose of visit": "Purpose of visit",
	"health card": "health card",
	"Select Priority": "Select Priority",
	"Exclude Opt-Out": "Exclude Opt-Out",
	"Select Campaign": "Select Campaign",
	"Last Segment Status": "Last Segment Status",
	"Doctor Repository": "Doctor Repository",
	"Add a New Campaigns": "Add a New Campaigns",
	"Form Submitted": "Form Submitted",
	"Send Invitation": "Send Invitation",
	"Virtual Care Provider": "Virtual Care Provider",
	"Received On": "Received On",
	"Add a Note": "Add a Note",
	"Oops! Something went wrong": "Oops! Something went wrong",
	"Please try refreshing the page. If the issue persists, contact support for assistance":
		"Please try refreshing the page. If the issue persists, contact support for assistance",
	"Back to home": "Back to home",
	"Input should be 15 or less": "Input should be 15 or less",
	"New password must be different from the current password":
		"New password must be different from the current password",
	"The patient has signed up; this field cannot be empty.":
		"The patient has signed up; this field cannot be empty.",
	"The lead has signed up; this field cannot be empty.":
		"The lead has signed up; this field cannot be empty.",
	"Input must be at least 2 characters long":
		"Input must be at least 2 characters long",
	"Input must be no more than 25 characters":
		"Input must be no more than 25 characters",
	Teams: "Teams",
	"Add Professional Referral": "Add Professional Referral",
	"Professional Referral": "Professional Referral",
	"Yes, delete this campaign": "Yes, delete this campaign",
	"The patient will be notified about the updates via email.":
		"The patient will be notified about the updates via email.",
	"Do you want to update the patient’s consent?":
		"Do you want to update the patient’s consent?",
	"Invalid Postal/Zip Code": "Invalid Postal/Zip Code",
	"Input cannot be empty or contain only spaces.":
		"Input cannot be empty or contain only spaces.",
	"Use the form below to change the password for your":
		"Use the form below to change the password for your",
	account: "account",
	CNP_PatientsNavigator: "CNP_PatientsNavigator",
	"Transgender or non binary is required":
		"Transgender or non binary is required",
	"Transgender or non-binary": "Transgender or non-binary",
	"Month, Year": "Month, Year",
};

export default enTranslations;
