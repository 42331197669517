import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	refreshReferralInbox: false,
	refreshLeadInbox: false,
	reloadConfig: false,
};

const webSocketSlice = createSlice({
	name: "webSocket",
	initialState,
	reducers: {
		setRefreshReferralInbox: (state, action) => {
			state.refreshReferralInbox = action.payload;
		},
		setRefreshLeadInbox: (state, action) => {
			state.refreshLeadInbox = action.payload;
		},
		setReloadConfig: (state, action) => {
			state.reloadConfig = action.payload;
		},
		resetWebSocket: () => {
			return initialState;
		},
	},
});

export const {
	setRefreshReferralInbox,
	setRefreshLeadInbox,
	setReloadConfig,
	resetWebSocket,
} = webSocketSlice.actions;
export default webSocketSlice.reducer;
