import React from "react";
import IcomoonReact from "icomoon-react";
import iconSet from "./selection.json";
import { MuiTheme as theme } from "../../styles/theme";

interface IconProps {
	id?: string;
	className?: string;
	color?: string;
	icon: string;
	size?: string | number;
}

const Icon = ({
	id = "",
	className = "",
	color = theme.palette.primary.main,
	icon,
	size = 24,
}: IconProps) => {
	return (
		<IcomoonReact
			id={icon}
			className={className}
			iconSet={iconSet}
			color={color}
			size={size}
			icon={icon}
		/>
	);
};

export default Icon;
