import { alpha, createTheme } from "@mui/material/styles";
import { between, rem } from "polished";
import Icon from "../components/icons/icon";
import { Box } from "@mui/material";

declare module "@mui/material/styles" {
	interface PaletteColor {
		name: number | string;
	}

	interface Palette {
		tertiary1: Palette["primary"];
		tertiary2: Palette["primary"];
		tertiary3: Palette["primary"];
		link: Palette["primary"];
		white: Palette["primary"];
		black: Palette["primary"];
		disabled: Palette["primary"];
	}

	interface PaletteOptions {
		tertiary1?: PaletteOptions["primary"];
		tertiary2?: PaletteOptions["primary"];
		tertiary3?: PaletteOptions["primary"];
		link?: PaletteOptions["primary"];
		white: Palette["white"];
		black: Palette["primary"];
		disabled: Palette["primary"];
	}
}

declare module "@mui/material/Button" {
	interface ButtonPropsVariantOverrides {
		"icon-contained": true;
		"icon-text": true;
		primary: true;
		secondary: true;
	}
}

const font = ["Graphik Web"].join(",");

let theme = createTheme();

theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1440,
			xl: 1920,
		},
	},
});

const getFluidSize = (min: string, max: string) => `
  clamp(${min},
  ${between(
		min,
		max,
		rem(theme.breakpoints.values.lg),
		rem(theme.breakpoints.values.xl),
  )},
  ${max})
`;

const iconButtonCss = {
	borderRadius: "50%",
	fontSize: "1.6rem",
	minWidth: "1.5em",
	overflow: "hidden",
	padding: "0",
};

const SelectCustomIcon = () => {
	return (
		<Box
			sx={{
				pointerEvents: "none",
				cursor: "pointer",
				position: "absolute",
				right: 8,
			}}
		>
			<Icon icon="cheveron-down" />
		</Box>
	);
};

const OpenPickerIcon = () => {
	return <Icon icon="calendar" />;
};

theme = createTheme(theme, {
	palette: {
		primary: {
			name: "Ocean",
			main: "#263F6A",
			contrastText: "#FFFFFF",
		},
		secondary: {
			name: "Salmon",
			main: "#FF887C",
			contrastText: "#263F6A",
		},
		tertiary1: {
			name: "Sky",
			main: "#9CCFF0",
			contrastText: "#263F6A",
		},
		tertiary2: {
			name: "Light Sky",
			main: "#EAF4FB",
			contrastText: "#000000",
		},
		tertiary3: {
			name: "Light Salmon",
			main: "#FDE5E3",
			contrastText: "#263F6A",
		},
		link: {
			name: "Link Blue",
			main: "#2E5DA8",
			contrastText: "#FFFFFF",
		},
		info: {
			main: "#649DFF",
			light: "rgba(100, 157, 255, 0.08);",
			contrastText: "#FFFFFF",
		},
		success: {
			main: "#57C863",
			light: "#F2FFF0",
			contrastText: "#FFFFFF",
		},
		warning: {
			main: "#F3AF00",
			light: "#FF5C00",
			contrastText: "#ffebe0",
		},
		error: {
			main: "#F40A0A",
			light: "rgba(244, 10, 10, 0.08)",
			contrastText: "#FFFFFF",
		},
		text: {
			primary: "#263F6A",
			secondary: alpha("#263F6A", 0.6),
		},
		black: {
			name: "Black",
			main: "#000000",
			contrastText: "#ffffff",
		},
		white: {
			name: "White",
			main: "#FFFFFF",
			contrastText: "#000000",
		},
		disabled: {
			main: "#E4E4E4",
			contrastText: "#FBF8F6",
		},
	},
});

theme = createTheme(theme, {
	typography: {
		htmlFontSize: 10,
		fontFamily: font,
		fontSize: 16,
		h1: {
			fontSize: getFluidSize("3rem", "1rem"), // 48px
			fontWeight: 600,
			letterSpacing: "-0.02em",
			lineHeight: 1.1,
			color: theme.palette.primary.main,
		},
		h2: {
			fontSize: getFluidSize("2.5rem", "1rem"), // 40px
			fontWeight: 600,
			letterSpacing: "-0.02em",
			lineHeight: 1.2,
			color: theme.palette.primary.main,
		},
		h3: {
			fontSize: getFluidSize("2rem", "0.8rem"), // 32px
			fontWeight: 600,
			letterSpacing: "-0.02em",
			lineHeight: 1.2,
			color: theme.palette.primary.main,
		},
		h4: {
			fontSize: getFluidSize("1.5rem", "0.8rem"), // 24px
			fontWeight: 600,
			letterSpacing: "normal",
			lineHeight: 1.2,
			color: theme.palette.primary.main,
		},
		h5: {
			fontSize: getFluidSize("1.375rem", "0.8rem"), // 22px
			fontWeight: 600,
			letterSpacing: "normal",
			lineHeight: 1.3,
			color: theme.palette.primary.main,
		},
		h6: {
			fontSize: getFluidSize("1.25rem", "0.8rem"), // 20px
			fontWeight: 600,
			letterSpacing: "normal",
			lineHeight: 1.3,
			color: theme.palette.primary.main,
		},
		body1: {
			fontSize: getFluidSize("1.125rem", "0.8rem"), // 18px
			fontWeight: 500,
			letterSpacing: "normal",
			lineHeight: 1.3,
			color: theme.palette.primary.main,
		},
		body2: {
			fontSize: getFluidSize("1rem", "0.7rem"), // 16px
			fontWeight: 400,
			letterSpacing: "normal",
			lineHeight: 1.3,
			color: theme.palette.primary.main,
		},
		subtitle1: {
			fontSize: getFluidSize("0.875rem", "0.675rem"), // 14px
			fontWeight: 400,
			letterSpacing: "normal",
			lineHeight: 1.3,
			color: theme.palette.primary.main,
		},
		subtitle2: {
			fontSize: getFluidSize("0.75rem", "0.675rem"), // 12px
			fontWeight: 400,
			letterSpacing: "normal",
			lineHeight: 1.3,
			color: theme.palette.primary.main,
		},
		button: {
			fontSize: getFluidSize("1rem", "0.7rem"), // 16px
			lineHeight: 1.3,
			textTransform: "none",
		},
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					h1: "h1",
					h2: "h2",
					h3: "h3",
					h4: "h4",
					h5: "h5",
					h6: "h6",
					body1: "p",
					body2: "p",
					subtitle1: "p",
					subtitle2: "p",
				},
			},
		},
	},
});

theme = createTheme(theme, {
	shape: {
		borderRadius: 8,
	},
	shadows: [
		...createTheme({}).shadows.map((shadow, i) =>
			i === 24 ? "12px 12px 72px 0 rgba(0, 0, 0, 0.08)" : shadow,
		),
	],
	components: {
		MuiTypography: {
			defaultProps: {
				fontFamily: theme.typography.fontFamily,
			},
		},
	},
});

theme = createTheme(theme, {
	components: {
		MuiAvatar: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
					fontSize: "2rem",
					height: theme.spacing(7),
					width: theme.spacing(7),
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					fontFamily: `${font} !important`,
					"&.MuiTab-root": {
						fontSize: theme.typography.body2.fontSize,
					},
					"&.MuiCheckbox-root": {
						"&:hover": {
							backgroundColor: alpha(
								theme.palette.tertiary2.main,
								0.5,
							),
						},
					},
					"&.MuiMenuItem-root": {
						fontSize: theme.typography.subtitle1.fontSize,
					},
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					borderRadius: 50,
					overflow: "hidden",
					padding: "10px 30px",
					fontSize: theme.typography.body2.fontSize,
					fontWeight: "600",
					".MuiButton-startIcon": {
						marginRight: theme.spacing(3),
						"& .MuiSvgIcon-root": {
							fontSize: theme.typography.h3.fontSize,
						},
					},
					".MuiButton-endIcon": {
						marginLeft: theme.spacing(3),
						"& .MuiSvgIcon-root": {
							fontSize: theme.typography.h3.fontSize,
						},
					},
					"& .MuiSvgIcon-root": {
						fontSize: theme.typography.h3.fontSize,
					},
					"&:hover, &:focus": {
						"& svg": {
							display: "block",
							"& path": {
								fill: `${theme.palette.white.main} !important`,
							},
						},
					},
				},
				contained: {
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.secondary.contrastText,
					"&:hover, &:focus": {
						backgroundColor: theme.palette.secondary.main,
						color: theme.palette.secondary.contrastText,
						"&::before": {
							opacity: "1",
						},
					},
				},
				outlined: {
					border: `0.1rem solid ${theme.palette.primary.main}`,
					color: theme.palette.primary.main,
					"&:hover, &:focus": {
						border: `0.1rem solid ${theme.palette.divider}`,
					},
					"&:hover": {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.white.main,
					},
					"&:focus": {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.white.main,
					},
				},
				text: {
					color: theme.palette.link.main,
					padding: 0,
					minWidth: "auto",
					overflow: "visible",
					"&:hover": {
						backgroundColor: "transparent",
						textDecoration: "underline",
					},
					"&.Mui-disabled": {
						color: alpha(theme.palette.black.main, 0.38),
						"svg path": {
							opacity: "0.38",
						},
					},
				},
			},
			variants: [
				{
					props: { variant: "icon-contained" },
					style: {
						...iconButtonCss,
						color: theme.palette.tertiary3.contrastText,
						backgroundColor: theme.palette.tertiary3.main,
						"&:hover, &:focus": {
							color: theme.palette.tertiary3.contrastText,
							backgroundColor: theme.palette.tertiary3.main,
							"&::before": {
								opacity: "1",
							},
						},
						"&.Mui-disabled": {
							backgroundColor: theme.palette.disabled.main,
							color: alpha(theme.palette.black.main, 0.38),
						},
					},
				},
				{
					props: { variant: "icon-text" },
					style: {
						...iconButtonCss,
						color: theme.palette.primary.main,

						"&:hover, &:focus": {
							backgroundColor: theme.palette.tertiary2.main,
							color: theme.palette.tertiary2.contrastText,
						},
						"&.Mui-disabled": {
							backgroundColor: theme.palette.disabled.main,
							color: alpha(theme.palette.black.main, 0.38),
						},
					},
				},
				{
					props: { variant: "primary" },
					style: {
						color: theme.palette.primary.contrastText,
						backgroundColor: theme.palette.primary.main,

						"&:hover, &:focus": {
							color: theme.palette.secondary.contrastText,
							backgroundColor: theme.palette.secondary.main,
						},
						"&.Mui-disabled": {
							backgroundColor: theme.palette.disabled.main,
							color: alpha(theme.palette.black.main, 0.38),
							"&:hover, &:focus": {
								color: theme.palette.primary.contrastText,
								backgroundColor: theme.palette.primary.main,
							},
						},
					},
				},
				{
					props: { variant: "secondary" },
					style: {
						color: theme.palette.secondary.contrastText,
						backgroundColor: theme.palette.secondary.main,
						"&:hover, &:focus": {
							color: theme.palette.primary.contrastText,
							backgroundColor: theme.palette.primary.main,
						},
						"&.Mui-disabled": {
							backgroundColor: theme.palette.disabled.main,
							color: alpha(theme.palette.black.main, 0.38),
							"&:hover, &:focus": {
								color: theme.palette.primary.contrastText,
								backgroundColor: theme.palette.primary.main,
							},
						},
					},
				},
				{
					props: { variant: "outlined" },
					style: {
						"&.Mui-disabled": {
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.primary.contrastText,
							"&:hover, &:focus": {
								color: theme.palette.primary.contrastText,
								backgroundColor: theme.palette.primary.main,
							},
						},
					},
				},
			],
		},
		MuiCard: {
			defaultProps: {
				elevation: 24,
			},
			styleOverrides: {
				root: {
					padding: 16,
					borderRadius: 16,
					".MuiIconButton-root": {
						backgroundColor: theme.palette.tertiary3.main,
						color: theme.palette.tertiary3.contrastText,
						flexShrink: 0,
					},
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: 0,
					"&:first-of-type": {
						paddingBottom: 0,
					},
					"&:last-child": {
						paddingBottom: 0,
					},
				},
			},
		},
		MuiCardMedia: {
			styleOverrides: {
				root: {
					borderRadius: "12px",
					marginBottom: "12px",
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: theme.palette.primary.main,
					fontSize: "4rem",
					padding: "1px 9px 0",
					".MuiSvgIcon-root": {
						fontSize: theme.typography.h6.fontSize,
					},
				},
			},
		},
		MuiChip: {
			defaultProps: {
				clickable: true,
				variant: "outlined",
			},
			styleOverrides: {
				root: {
					borderColor: alpha(theme.palette.common.black, 0.5),
					color: "currentColor",
					fontSize: theme.typography.subtitle1.fontSize,
					borderRadius: "4px",
					fontWeight: "500",
					"&.MuiChip-filled": {
						border: `1px solid ${theme.palette.primary.main}`,
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
					},
					".MuiSvgIcon-root": {
						fontSize: "1.25em",
					},
					".MuiChip-deleteIcon": {
						color: "currentColor",
					},
				},
				avatar: {
					backgroundColor: theme.palette.common.black,
					color: theme.palette.common.white,
					fontSize: "0.825em",
				},
			},
		},
		MuiDialog: {
			defaultProps: {
				maxWidth: "sm",
			},
			styleOverrides: {
				root: {
					".MuiDialogTitle-root": {
						alignItems: "center",
						display: "flex",
						justifyContent: "space-between",
					},
				},
				container: {
					alignItems: "center",
					justifyContent: "center",
				},
				paper: {
					margin: theme.spacing(2),
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: "30px",
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: "20px",
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					color: theme.palette.primary.main,
					fontFamily: theme.typography.fontFamily,
					"& .MuiFormControlLabel-label": {
						fontSize: theme.typography.body2.fontSize,
						fontWeight: "500",
					},
				},
			},
		},
		MuiFormControl: {
			defaultProps: {
				fullWidth: true,
				variant: "outlined",
			},
			styleOverrides: {
				root: {
					color: theme.palette.primary.main,
					fontSize: theme.typography.body2.fontSize,
					fontFamily: theme.typography.fontFamily,
					fontWeight: "400",
					".MuiFormLabel-root": {
						color: "currentColor",
						opacity: 0.8,
						"&.Mui-error": {
							color: `${theme.palette.error.main} !important`,
						},
					},
					".MuiOutlinedInput-notchedOutline": {
						borderColor: alpha(theme.palette.primary.main, 0.5),
					},
					".MuiInputBase-root": {
						color: "currentColor",
					},
					".MuiFormHelperText-root": {
						color: "currentColor",
						fontSize: theme.typography.subtitle2.fontSize,
						opacity: 0.8,
						"&.Mui-error": {
							color: theme.palette.error.main,
						},
					},
					".MuiInputAdornment-root": {
						color: "currentColor",
					},
					".MuiButtonBase-root": {
						color: "currentColor",
					},
					"& input": {
						fontSize: theme.typography.body2.fontSize,
						fontFamily: theme.typography.fontFamily,
						fontWeight: "400",
						color: theme.palette.primary.main,
					},
					"& label": {
						fontSize: theme.typography.body2.fontSize,
						fontFamily: theme.typography.fontFamily,
						color: theme.palette.primary.main,
					},
					"& fieldset": {
						fontSize: theme.typography.body2.fontSize,
						fontFamily: theme.typography.fontFamily,
						color: theme.palette.primary.main,
					},
					"& .MuiOutlinedInput-input.MuiSelect-select": {
						padding: "15px 15px 15.2px;",
						fontFamily: theme.typography.fontFamily,
						fontWeight: "400",
					},
					"&.MuiTextField-root": {
						width: "100%",
					},
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				contained: {
					// backgroundColor: 'secondary.main',
					// color: 'secondary.contrastText',
				},
				outlined: {
					borderColor: theme.palette.primary.main,
					color: "primary.main",
				},
			},
		},
		MuiLink: {
			defaultProps: {
				color: "link.main",
				underline: "hover",
			},
		},
		MuiPagination: {
			styleOverrides: {
				root: {
					padding: "20px 0",
					"& .MuiPagination-ul": {
						justifyContent: "center",
						gap: "10px",
						padding: "0 20px",
						"& li:first-of-type .MuiButtonBase-root, & li:last-of-type .MuiButtonBase-root":
							{
								borderRadius: "30px",
								width: "auto",
								height: "auto",
								padding: "8px 30px",
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.white.main,
								minWidth: 157,
								"& svg path": {
									fill: `${theme.palette.white.main}!important`,
								},
								"& li:first-of-type": {
									marginRight: "auto",
								},
								"& li:last-of-type": {
									marginLeft: "auto",
								},

								// "@media (max-width: 1440px)": {
								// 	padding: "8px 10px",
								// },
							},
						"& .MuiButtonBase-root": {
							backgroundColor: "transparent",
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "center",
							borderRadius: "50%",
							width: "auto",
							height: "auto",
							gap: 10,
							padding: "6px",

							"&.Mui-selected,&:hover": {
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.white.main,
								"&:hover": {
									backgroundColor: theme.palette.primary.main,
								},
							},
						},
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					"&.MuiAlert-outlined": {
						fontFamily: font,
						border: "0",
						borderLeft: "5px solid",
						color: alpha(theme.palette.primary.main, 0.6),
						padding: "15px",
						display: "flex",
						alignItems: "center",
						".MuiAlert-icon": {
							alignSelf: "center",
							fontSize: theme.typography.subtitle1.fontSize,
							marginRight: 12,
							padding: 0,
						},
						".MuiAlert-message": {
							fontSize: theme.typography.subtitle1.fontSize,
							color: theme.palette.primary.main,
							padding: 0,
							lineHeight: 1.2,
							fontWeight: "500",
						},
						".MuiAlertTitle-root": {
							color: theme.palette.primary.main,
							fontWeight: "600",
							margin: "0",
							textTransform: "capitalize",
						},
						"&.MuiAlert-outlinedSuccess": {
							backgroundColor: theme.palette.success.light,
							borderLeftColor: theme.palette.success.main,
							"& .MuiAlert-icon": {
								color: theme.palette.success.main,
							},
						},
						"&.MuiAlert-outlinedInfo": {
							backgroundColor: theme.palette.info.light,
							borderLeftColor: theme.palette.info.main,
							"& .MuiAlert-icon": {
								color: theme.palette.info.main,
							},
						},
						"&.MuiAlert-outlinedWarning": {
							backgroundColor: theme.palette.warning.contrastText,
							borderLeftColor: theme.palette.warning.light,
							"& .MuiAlert-icon": {
								color: theme.palette.warning.light,
							},
						},
						"&.MuiAlert-outlinedError": {
							backgroundColor: theme.palette.error.light,
							borderLeftColor: theme.palette.error.main,
							"& .MuiAlert-icon": {
								color: theme.palette.error.main,
							},
						},
					},
					"&.MuiAlert-filled": {
						fontFamily: font,
						border: "0",
						color: theme.palette.white.main,
						padding: "15px",
						display: "flex",
						alignItems: "center",
						".MuiAlert-icon": {
							alignSelf: "center",
							fontSize: theme.typography.h6.fontSize,
							marginRight: 12,
							padding: 0,
						},
						".MuiAlert-message": {
							fontSize: theme.typography.subtitle1.fontSize,
							color: theme.palette.white.main,
							padding: 0,
							lineHeight: 1.2,
							fontWeight: "500",
						},
						".MuiAlertTitle-root": {
							color: theme.palette.primary.main,
							fontWeight: "600",
							margin: "0",
							textTransform: "capitalize",
						},
						"&.MuiAlert-filledSuccess": {
							backgroundColor: theme.palette.success.main,
							"& .MuiAlert-icon": {
								color: theme.palette.white.main,
							},
						},
						"&.MuiAlert-filledInfo": {
							backgroundColor: theme.palette.info.main,
							"& .MuiAlert-icon": {
								color: theme.palette.white.main,
							},
						},
						"&.MuiAlert-filledWarning": {
							backgroundColor: theme.palette.warning.main,
							"& .MuiAlert-icon": {
								color: theme.palette.white.main,
							},
						},
						"&.MuiAlert-filledError": {
							backgroundColor: theme.palette.error.main,
							"& .MuiAlert-icon": {
								color: theme.palette.white.main,
							},
						},
					},
				},
			},
		},
		MuiPickersPopper: {
			styleOverrides: {
				root: {
					".MuiPickersPopper-paper": {},
					".MuiPickersCalendarHeader-switchViewButton": {
						fontSize: theme.typography.subtitle1.fontSize,
					},
					".MuiDayPicker-weekDayLabel": {
						fontSize: theme.typography.subtitle1.fontSize,
					},
					".MuiPickersDay-today:not(.Mui-selected)": {
						border: "0 !important",
						fontWeight: "600",
					},
					".MuiPickersDay-root": {
						fontSize: theme.typography.body2.fontSize,
						"&:hover": {
							backgroundColor: alpha(
								theme.palette.primary.main,
								0.08,
							),
						},
						"&.Mui-selected": {
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.primary.contrastText,
							"&:hover,&:focus": {
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.primary.contrastText,
							},
						},
					},
					".MuiDateRangePickerDay-rangeIntervalDayHighlight": {
						backgroundColor: theme.palette.tertiary2.main,
					},
					".MuiPickersArrowSwitcher-root": {
						".MuiTypography-root": {
							fontSize: theme.typography.body2.fontSize,
						},
					},
				},
			},
		},
		MuiPopper: {
			styleOverrides: {
				root: {
					// boxShadow: " 0 1px 10px 0 rgba(0,0,0,0.2)",
					// backgroundColor: "#fff",
					// padding: "5px 25px",
				},
			},
		},
		MuiPopover: {
			defaultProps: {
				elevation: 24,
			},
			styleOverrides: {
				root: {
					".Mui-selected": {
						".MuiTypography-root": {
							fontWeight: "500",
						},
					},
					".MuiMenuItem-root": {
						fontSize: theme.typography.subtitle1.fontSize,
						"&.Mui-selected": {
							backgroundColor: "transparent !important",
						},
						"&:hover": {
							backgroundColor: `${alpha(
								theme.palette.primary.main,
								0.12,
							)} !important`,
						},
					},
					// ".MuiPopover-paper": {
					// 	maxHeight: "calc(100% - 500px)",
					// },
				},
			},
		},
		MuiSwitch: {
			defaultProps: {
				checkedIcon: <Icon icon="check-circle" />,
				icon: <Icon icon="error-alert" />,
			},
			styleOverrides: {
				root: {
					height: theme.spacing(4),
					padding: 0,
					width: theme.spacing(7),
					".MuiSwitch-switchBase": {
						margin: theme.spacing(0.5),
						padding: 0,
						"&.Mui-checked": {
							transform: `translateX(${theme.spacing(3)})`,
						},
					},
					".MuiSvgIcon-root": {
						color: alpha(theme.palette.primary.main, 0.8),
						fontSize: theme.spacing(3),
					},
					".MuiSwitch-track": {
						border: `0.1rem solid ${alpha(
							theme.palette.primary.main,
							0.8,
						)} `,
						backgroundColor: alpha(
							theme.palette.tertiary2.main,
							0.95,
						),
						borderRadius: theme.spacing(4),
						opacity: "1 !important",
					},
					".Mui-checked": {
						".MuiSvgIcon-root": {
							color: theme.palette.tertiary3.main,
						},
					},
					".Mui-disabled": {
						".MuiSvgIcon-root": {
							color: alpha(theme.palette.primary.main, 0.38),
						},
						"+ .MuiSwitch-track": {
							backgroundColor: "transparent !important",
							borderColor: alpha(
								theme.palette.action.disabledBackground,
								0.12,
							),
						},
						"&.Mui-checked": {
							".MuiSvgIcon-root": {
								color: theme.palette.common.white,
							},
							"+ .MuiSwitch-track": {
								backgroundColor: `${alpha(
									theme.palette.action.disabledBackground,
									0.12,
								)} !important`,
								borderColor: "transparent",
							},
						},
					},
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					".MuiTableContainer": {
						boxShadow: "none",
						".MuiTableRow-root ": {
							width: "100%",
						},
					},
					".MuiTableHead-root": {
						backgroundColor: alpha(
							theme.palette.tertiary2.main,
							0.6,
						),
						display: "table",
						width: "100%",
						tableLayout: "fixed",
						".MuiTableCell-root": {
							padding: theme.spacing(1.5, 2),
							display: "table-cell",
							verticalAlign: "middle",
						},
					},
					".MuiTableBody-root": {
						display: "table",
						width: "100%",
						tableLayout: "fixed",
						".MuiTableCell-root": {
							padding: theme.spacing(2),
							display: "table-cell",
							verticalAlign: "middle",
						},
					},
					".MuiTableRow-root": {
						display: "table",
						tableLayout: "fixed",
						width: "100%",
						cursor: "pointer",
						"&-hover, &:hover": {
							transition: theme.transitions.create([
								"background-color",
								"box-shadow",
							]),
							"&:hover": {
								backgroundColor: alpha(
									theme.palette.common.white,
									0.2,
								),
								boxShadow: theme.shadows[24],
							},
						},
					},
					".MuiTableRow-hover": {},
					".MuiRadio-root": {
						padding: 0,
					},
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				fullWidth: true,
				variant: "outlined",
			},
			styleOverrides: {
				root: {
					color: theme.palette.primary.main,
					fontSize: theme.typography.body2.fontSize,
					fontFamily: theme.typography.fontFamily,
					fontWeight: "400",

					".MuiFormLabel-root": {
						color: "currentColor",
						opacity: 0.8,
						"&.Mui-error": {
							color: `${theme.palette.error.main} !important`,
						},
					},
					".MuiOutlinedInput-notchedOutline": {
						borderColor: alpha(theme.palette.primary.main, 0.5),
					},
					".MuiInputBase-root": {
						color: "currentColor",
					},
					".MuiFormHelperText-root": {
						color: "currentColor",
						fontSize: theme.typography.subtitle2.fontSize,
						opacity: 0.8,
					},
					".MuiInputAdornment-root": {
						color: "currentColor",
					},
					".MuiButtonBase-root": {
						color: "currentColor",
					},
					"& input": {
						fontSize: theme.typography.body2.fontSize,
						fontFamily: theme.typography.fontFamily,
						fontWeight: "400",
						color: theme.palette.primary.main,
					},
					"& label": {
						fontSize: theme.typography.body2.fontSize,
						fontFamily: theme.typography.fontFamily,
						color: theme.palette.primary.main,
						"&.Mui-error": {
							color: `${theme.palette.error.main} !important`,
						},
					},
					"& fieldset": {
						fontSize: theme.typography.body2.fontSize,
						fontFamily: theme.typography.fontFamily,
						color: theme.palette.primary.main,
						"&.Mui-error": {
							color: `${theme.palette.error.main} !important`,
						},
					},
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				checkedIcon: <Icon icon="check-circle" />,
			},
		},
		MuiDatePicker: {
			defaultProps: {
				components: {
					OpenPickerIcon: OpenPickerIcon,
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				IconComponent: SelectCustomIcon,
			},
		},
		MuiAutocomplete: {
			defaultProps: {
				popupIcon: (
					<Box
						sx={{
							pointerEvents: "none",
							cursor: "pointer",
							position: "absolute",
							right: 0,
						}}
					>
						<Icon icon="cheveron-down" />
					</Box>
				),
			},
			styleOverrides: {
				popupIndicator: {
					transform: "rotate(0deg)",
				},
				root: {
					".MuiAutocomplete-clearIndicator": {
						marginRight: 20,
					},
				},
			},
		},
	},
});

export const MuiTheme = theme;
